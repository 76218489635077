import axios from 'axios';

import {
    BEFORE_FETCH_USER_SOCIAL_PLATFORMS,
    FETCH_USER_SOCIAL_PLATFORMS,
    NETWORK_ERROR_FETCHING_USER_SOCIAL_PLATFORMS,

    BEFORE_ADD_SOCIAL_MEDIA_HANDLE,
    ADD_SOCIAL_MEDIA_HANDLE,
    NETWORK_ERROR_ADDING_SOCIAL_MEDIA_HANDLE,

    BEFORE_VERIFY_SOCIAL_MEDIA_HANDLE,
    VERIFY_SOCIAL_MEDIA_HANDLE,
    NETWORK_ERROR_VERIFYING_SOCIAL_MEDIA_HANDLE,
    CLEAR_VERIFY_SOCIAL_MEDIA_HANDLE_MESSAGES,

    BEFORE_DELETE_SOCIAL_MEDIA_HANDLE,
    DELETE_SOCIAL_MEDIA_HANDLE,
    NETWORK_ERROR_DELETING_SOCIAL_MEDIA_HANDLE

} from '../types/social-platforms.types';


import {
    endpoint
} from 'config';


export const beforeFetchSocialPlatforms = () => (
    { type:  BEFORE_FETCH_USER_SOCIAL_PLATFORMS }
)

export const fetchSocialPlatforms = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}user-social-platforms`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
            });
            
            dispatch({ type: FETCH_USER_SOCIAL_PLATFORMS, payload: response });

        }catch(e){
            dispatch({ type: NETWORK_ERROR_FETCHING_USER_SOCIAL_PLATFORMS, payload: e.response });
        }
    }
}

/**
 * Actions for adding social platform to user
 */
export const beforeAddSocialMediaHandle = (id) => (
    { type:  BEFORE_ADD_SOCIAL_MEDIA_HANDLE, payload: {platform_id: id} }
)

export const addSocialMediaHandle = (token, data, url_path) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}user-social-platform/${url_path}`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data: data
            });
            
            dispatch({ type: ADD_SOCIAL_MEDIA_HANDLE, params: data, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_ADDING_SOCIAL_MEDIA_HANDLE, params: data, payload: e.response });
        }
    }
}


/**
 * Actions for verifiying social platform of user
 */

export const clearVerificationMessages = () => (
    { type:  CLEAR_VERIFY_SOCIAL_MEDIA_HANDLE_MESSAGES  }
)

 export const beforeVerifySocialMediaHandle = (id) => (
    { type:  BEFORE_VERIFY_SOCIAL_MEDIA_HANDLE, payload: {id} }
)

export const verifySocialMediaHandle = (token, data, url_path) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}user-social-platform/${url_path}/${data.id}/verify`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data: data
            });
            
            dispatch({ type: VERIFY_SOCIAL_MEDIA_HANDLE, params: data, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_VERIFYING_SOCIAL_MEDIA_HANDLE, params: data, payload: e.response });
        }
    }
}




/**
 * Actions for deleting social platform of user
 */
 export const beforeDeleteSocialMediaHandle = (id) => (
    { type:  BEFORE_DELETE_SOCIAL_MEDIA_HANDLE, payload: {id} }
)

export const deleteSocialMediaHandle = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'delete',
                url: `${endpoint}user-social-platform/${data.id}/delete`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
            });
            
            dispatch({ type: DELETE_SOCIAL_MEDIA_HANDLE, params: data, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_DELETING_SOCIAL_MEDIA_HANDLE, params: data, payload: e.response });
        }
    }
}
export const BEFORE_FETCH_USER_SOCIAL_PLATFORMS = 'BEFORE_FETCH_USER_SOCIAL_PLATFORMS';
export const FETCH_USER_SOCIAL_PLATFORMS = 'FETCH_USER_SOCIAL_PLATFORMS';
export const NETWORK_ERROR_FETCHING_USER_SOCIAL_PLATFORMS = 'NETWORK_ERROR_FETCHING_USER_SOCIAL_PLATFORMS';

export const BEFORE_ADD_SOCIAL_MEDIA_HANDLE = 'BEFORE_ADD_SOCIAL_MEDIA_HANDLE';
export const ADD_SOCIAL_MEDIA_HANDLE = 'ADD_SOCIAL_MEDIA_HANDLE';
export const NETWORK_ERROR_ADDING_SOCIAL_MEDIA_HANDLE = 'NETWORK_ERROR_ADDING_SOCIAL_MEDIA_HANDLE';

export const BEFORE_REMOVE_SOCIAL_MEDIA_HANDLE = 'BEFORE_REMOVE_SOCIAL_MEDIA_HANDLE';
export const REMOVE_SOCIAL_MEDIA_HANDLE = 'REMOVE_SOCIAL_MEDIA_HANDLE';
export const NETWORK_ERROR_REMOVING_SOCIAL_MEDIA_HANDLE = 'NETWORK_ERROR_REMOVING_SOCIAL_MEDIA_HANDLE';


export const BEFORE_VERIFY_SOCIAL_MEDIA_HANDLE = 'BEFORE_VERIFY_SOCIAL_MEDIA_HANDLE';
export const VERIFY_SOCIAL_MEDIA_HANDLE = 'VERIFY_SOCIAL_MEDIA_HANDLE';
export const NETWORK_ERROR_VERIFYING_SOCIAL_MEDIA_HANDLE = 'NETWORK_ERROR_VERIFYING_SOCIAL_MEDIA_HANDLE';
export const CLEAR_VERIFY_SOCIAL_MEDIA_HANDLE_MESSAGES = 'CLEAR_VERIFY_SOCIAL_MEDIA_HANDLE_MESSAGES';

export const BEFORE_DELETE_SOCIAL_MEDIA_HANDLE = 'BEFORE_DELETE_SOCIAL_MEDIA_HANDLE';
export const DELETE_SOCIAL_MEDIA_HANDLE = 'DELETE_SOCIAL_MEDIA_HANDLE';
export const NETWORK_ERROR_DELETING_SOCIAL_MEDIA_HANDLE = 'NETWORK_ERROR_DELETING_SOCIAL_MEDIA_HANDLE';



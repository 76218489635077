import axios from 'axios';

import {
    BEFORE_CREATE_ONBOARDING_DASHBOARD_USERNAME,
    CREATE_ONBOARDING_DASHBOARD_USERNAME,
    NETWORK_ERROR_CREATING_ONBOARDING_DASHBOARD_USERNAME,

    BEFORE_RESEND_EMAIL_VERIFICATION,
    RESEND_EMAIL_VERIFICATION,
    NETWORK_ERROR_RESENDING_EMAIL_VERIFICATION
} from '../types/onboarding.types';

import {
    endpoint,
    ssoUrl
} from 'config';



export const beforeCreateUsername = () => (
    { type:  BEFORE_CREATE_ONBOARDING_DASHBOARD_USERNAME }
)

export const createUsername = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}username`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });
            dispatch({ type: CREATE_ONBOARDING_DASHBOARD_USERNAME, payload: response });

        }catch(e){
            dispatch({type:  NETWORK_ERROR_CREATING_ONBOARDING_DASHBOARD_USERNAME, payload: e.response });
        }
    }
}



export const beforeResendVerification = () => (
    { type:  BEFORE_RESEND_EMAIL_VERIFICATION }
)

export const resendVerification = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}email/verification-link`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });
            dispatch({ type: RESEND_EMAIL_VERIFICATION, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_RESENDING_EMAIL_VERIFICATION, payload: e.response });
        }
    }
}
import axios from "axios";
import {endpoint, endpointV2 } from "config";
import { getLocalToken, clearLocalToken } from "helpers/authentication/token";

export function getRequest(version, path){
    return sendRequest(version, path, 'get', false)
}

export function postRequest(version, path, data){
    return sendRequest(version, path, 'post', data)
}

export function putRequest(version, path, data){
    return sendRequest(version, path, 'put', data)
}

export function deleteRequest(version, path, data){
    return sendRequest(version, path, 'delete', data)
}


export function sendRequest(version, path, method, data){

    return new Promise((resolve, reject)=>{

        let baseUrl = version.toLowerCase() === 'v1' ? endpoint : endpointV2
        let token = getLocalToken();

        if(token === undefined){
            window.location.href = "/"
        }
        let headers = {"Authorization": `Bearer ${token}`, "Accept": "application/json"}

        let options = {
            url:`${baseUrl}${path}`, 
            headers:headers,
            method: method
        }

        let requestMethod = method.toLowerCase();
        if(requestMethod === 'post' || requestMethod === 'put' ){
            options.data = data
        }


        axios(options).then((data)=>{
            resolve(data.data)
        }).catch((error) => {

            if(error.response !== undefined){
                if(error.response.status === 401){
                    clearLocalToken();
                    window.location.href = "/"
                }
            }
            reject(error)
        })

    })
    

}
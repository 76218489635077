import {
    BEFORE_FETCH_SETTINGS_DATA,
    FETCH_SETTINGS_DATA,
    NETWORK_ERROR_FETCHING_SETTINGS_DATA
} from '../types/settings-index.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_SETTINGS_DATA: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_SETTINGS_DATA: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_FETCHING_SETTINGS_DATA: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }
}
import {
    VIEW_BLOG,
    ERROR_VIEWING_BLOG,
    BEFORE_VIEW_BLOG
} from '../types/viewBlog.type';
import { getRequest, postRequest } from 'helpers/httpRequest/request';
import { networkNotification } from 'components/notifications/Notifications';


export const beforeViewBlog = () => { return {type:  BEFORE_VIEW_BLOG} }

export const viewBlog = (id) => {
    return async (dispatch) => {
        try{
            const response = await getRequest('v1', `blog/${id}`);
            dispatch({ type: VIEW_BLOG, payload: response });

        }catch(e){
            networkNotification(e.response?.data?.message  || 'Error viewing blog')
            dispatch({type: ERROR_VIEWING_BLOG, payload: e.response });
            window.location.href = '/blog';
        }
    }
}
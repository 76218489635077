import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as Actions from './actions/user-level.action';
import { Helmet } from "react-helmet";

import Layout from '../../../../layout/Layout';
import LevelBlock from "./components/levelBlock";
import PointRow from "./components/pointRow";
import { getLocalToken } from 'helpers/authentication/token';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';

class UserLevel extends Component{

    componentDidMount(){
        const { fetchLevel } = this.props.actions;

        fetchLevel(getLocalToken());
    }

    getReducer = () => {
        return this.props.dashboardLevelsReducer;
    }

    render(){

        const { fetch_level_loading, levels, current_level, points, todays_points, months_data, fetch_level_status_code } = this.getReducer();

        return(
            <Layout>

                <Helmet>
                    <title>Achievements | Cluster</title>
                </Helmet>

                <div className="app-content content">

                    <div className="content-overlay"> </div>

                    <div className="content-wrapper">
                        <div className="content-body">

                            <div className="content-header row">
                                <div className="content-header-left col-12 mb-2 mt-1">
                                    <div className="breadcrumbs-top">
                                    <h5 className="content-header-title float-left pr-1 mb-0">Achievements</h5>
                                    <div className="breadcrumb-wrapper d-none d-sm-block">
                                        <ol className="breadcrumb p-0 mb-0 pl-1">
                                        <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                        </li>
                                        <li className="breadcrumb-item">Profile
                                        </li>
                                        <li className="breadcrumb-item active">Achievements
                                        </li>
                                        </ol>
                                    </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">

                                <div className="col-md-6" style={{paddingLeft: "0", paddingRight: "0px"}}>

                                    {!fetch_level_loading && fetch_level_status_code &&
                                        <>
                                            {months_data &&
                                                <div className="alert alert-success mb-1 text-center" >
                                                    You have acquired <b>{months_data.points ? months_data.points.toLocaleString() : months_data.points}</b> points so far in {months_data.month} {months_data.year}.
                                                </div>
                                            }

                                            <div className="card mb-1">
                                                <div className="card-header">
                                                    <h3 className="greeting-text">{current_level.description}</h3>
                                                    <p className="mb-0">{current_level.name}</p>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div className="dashboard-content-left">
                                                            <h1 className="text-warning font-large-2 text-bold-500">{points ? points.toLocaleString() : points} Points</h1>
                                                            <p className="text-dark">You get &#8358; {current_level.price_per_click ? current_level.price_per_click.toLocaleString(): current_level.price_per_click} everytime people click your promotion links. <a href="/promoter/promotions">View all your promotion links here.</a></p>
                                                            {/*<button type="button" className="btn btn-warning glow">View Details</button>*/}
                                                        </div>
                                                        <div className="dashboard-content-right">
                                                            <img src={`/themefive/app-assets/images/icon/award${current_level.id}.png`} height="220" width="220" className="img-fluid" alt="trophy" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <div className="card ">
                                        <div className="card-header">
                                        <h4 className="card-title">
                                            <b>ACHIEVEMENTS</b>
                                        </h4>
                                        </div>
                                        <div className="card-body">
                                            {!fetch_level_loading && fetch_level_status_code 
                                            ?
                                                <ul className="timeline ps ps--active-y" style={{height: "100%"}}>

                                                    {levels.map(
                                                        level => 
                                                        <LevelBlock 
                                                            icon={level.completed ? "timeline-icon-warning": "timeline-icon-light"} 
                                                            level={level.id}  
                                                            points={level.threshold} 
                                                            amount={level.price_per_click}
                                                            title={level.name}
                                                            completed={level.completed}
                                                            currentlevel={current_level.id}
                                                        />
                                                    )}
                                                </ul>
                                            :
                                                <LoadingSection />
                                                
                                            }
                                            
                                        </div>
                                    </div>

                                </div>

                                {/*
                                <div className="col-md-6">
                                    <section id="numeral-js-wrapper">
                                        <div className="card">
                                            <div className="card-header">
                                            <h4 className="card-title">Top 10 Points</h4>
                                            </div>
                                            <div className="card-body">
                                            
                                            <div className="table-responsive">
                                                <table className="table ">
                                                <thead>
                                                    <tr>
                                                    <th>Description</th>
                                                    <th>Points</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <PointRow description="Clicks from game product" points="20" />
                                                    <PointRow description="Referral from John" points="20" />
                                                    <PointRow description="Daily Task" points="20" />
                                                    <PointRow description="Daily Task" points="20" />
                                                    <PointRow description="Referral from James" points="20" />
                                                    <PointRow description="Referral from Jude" points="20" />
                                                    <PointRow description="Referral from Jeneth" points="20" />
                                                    <PointRow description="Referral from Jones" points="20" />
                                                    <PointRow description="Referral from Jamil" points="20" />
                                                    <PointRow description="Clicks from super goods" points="20" />
                                                    
                                                </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                */}

                            </div>
                        
                    

                        </div>

                    </div>
              
                </div>

            </Layout>
        )
    }
}


const mapStateToProps = (state) => ({ dashboardLevelsReducer : state.dashboardLevelsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserLevel));
import {
    BEFORE_FETCH_USER_SOCIAL_PLATFORMS,
    FETCH_USER_SOCIAL_PLATFORMS,
    NETWORK_ERROR_FETCHING_USER_SOCIAL_PLATFORMS,

    BEFORE_ADD_SOCIAL_MEDIA_HANDLE,
    ADD_SOCIAL_MEDIA_HANDLE,
    NETWORK_ERROR_ADDING_SOCIAL_MEDIA_HANDLE,

    BEFORE_VERIFY_SOCIAL_MEDIA_HANDLE,
    VERIFY_SOCIAL_MEDIA_HANDLE,
    NETWORK_ERROR_VERIFYING_SOCIAL_MEDIA_HANDLE,
    CLEAR_VERIFY_SOCIAL_MEDIA_HANDLE_MESSAGES,

    BEFORE_DELETE_SOCIAL_MEDIA_HANDLE,
    DELETE_SOCIAL_MEDIA_HANDLE,
    NETWORK_ERROR_DELETING_SOCIAL_MEDIA_HANDLE
} from '../types/social-platforms.types';

let initialState = {

    user_social_platforms_loading: false,
    user_social_platforms: [],
    user_social_platforms_messages: [],
    user_social_platforms_status_code: null,

    add_social_media_loading: false,
    add_social_media_messages: [],
    add_social_media_status_code: null,

    verify_social_media_loading: false,
    verify_social_media_messages: [],
    verify_social_media_status_code: null,

    delete_social_media_messages: [],
    delete_social_media_status_code: null,

    static_social_form_data: [
        { id: 2, name: 'Twitter', url_path: 'twitter', add_loading: false },
        //{ id: 3, name: 'Instagram', url_path: 'instagram', add_loading: false },
    ] 
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_USER_SOCIAL_PLATFORMS: {
            return Object.assign({}, state, {
                user_social_platforms_loading: true
            });
        }
        case FETCH_USER_SOCIAL_PLATFORMS: {
            return Object.assign({}, state, {
                user_social_platforms_loading: false,
                user_social_platforms_status_code: action.payload.status,
                user_social_platforms_messages: action.payload.data.message,
                user_social_platforms: action.payload.data.result.user_social_platforms
            });
        }
        case NETWORK_ERROR_FETCHING_USER_SOCIAL_PLATFORMS: {
            return Object.assign({}, state, {
                user_social_platforms_loading: false,
                user_social_platforms_status_code: action.payload.status,
                user_social_platforms_messages: action.payload.data.message
            });
        }
        case BEFORE_ADD_SOCIAL_MEDIA_HANDLE: {
            const index = state.static_social_form_data.findIndex(
                row => row.id === action.payload.platform_id); 
            return Object.assign({}, state, {
                add_social_media_loading: true,
                static_social_form_data: [
                    ...state.static_social_form_data.slice(0, index),
                    {
                        ...state.static_social_form_data[index],
                        add_loading: true        
                    },
                    ...state.static_social_form_data.slice(index + 1)
                ]
            });
        }
        case ADD_SOCIAL_MEDIA_HANDLE: {
            const index = state.static_social_form_data.findIndex(
                row => row.id === action.params.platform_id); 
            return Object.assign({}, state, {
                add_social_media_loading: false,
                add_social_media_status_code: action.payload.status,
                add_social_media_messages: action.payload.data.message,
                static_social_form_data: [
                    ...state.static_social_form_data.slice(0, index),
                    {
                        ...state.static_social_form_data[index],
                        add_loading: false       
                    },
                    ...state.static_social_form_data.slice(index + 1)
                ]
            });
        }
        case NETWORK_ERROR_ADDING_SOCIAL_MEDIA_HANDLE: {
            const index = state.static_social_form_data.findIndex(
                row => row.id === action.params.platform_id); 
            return Object.assign({}, state, {
                add_social_media_loading: false,
                add_social_media_status_code: action.payload.status,
                add_social_media_messages: action.payload.data.message,
                static_social_form_data: [
                    ...state.static_social_form_data.slice(0, index),
                    {
                        ...state.static_social_form_data[index],
                        add_loading: false       
                    },
                    ...state.static_social_form_data.slice(index + 1)
                ]
            });
        }
        case CLEAR_VERIFY_SOCIAL_MEDIA_HANDLE_MESSAGES: {
            return Object.assign({}, state, {
                verify_social_media_messages: []
            });
        }
        case BEFORE_VERIFY_SOCIAL_MEDIA_HANDLE: {
            const index = state.user_social_platforms.findIndex(
                row => row.id === action.payload.id); 
            return Object.assign({}, state, {
                verify_social_media_loading: true, 
                user_social_platforms: [
                    ...state.user_social_platforms.slice(0, index),
                    {
                        ...state.user_social_platforms[index],
                        verification_loading: true       
                    },
                    ...state.user_social_platforms.slice(index + 1)
                ]
            });
        }
        case VERIFY_SOCIAL_MEDIA_HANDLE: {
            const index = state.user_social_platforms.findIndex(
                row => row.id === action.params.id); 
            return Object.assign({}, state, {
                verify_social_media_loading: false, 
                verify_social_media_status_code: action.payload.status,
                verify_social_media_messages: action.payload.data.message,
                user_social_platforms: [
                    ...state.user_social_platforms.slice(0, index),
                    {
                        ...state.user_social_platforms[index],
                        verification_loading: false      
                    },
                    ...state.user_social_platforms.slice(index + 1)
                ]
            });
        }
        case NETWORK_ERROR_VERIFYING_SOCIAL_MEDIA_HANDLE: {
            const index = state.user_social_platforms.findIndex(
                row => row.id === action.params.id); 
            return Object.assign({}, state, {
                verify_social_media_loading: false, 
                verify_social_media_status_code: action.payload.status,
                verify_social_media_messages: action.payload.data.message,
                user_social_platforms: [
                    ...state.user_social_platforms.slice(0, index),
                    {
                        ...state.user_social_platforms[index],
                        verification_loading: false      
                    },
                    ...state.user_social_platforms.slice(index + 1)
                ]
            });
        }
        case BEFORE_DELETE_SOCIAL_MEDIA_HANDLE: {
            const index = state.user_social_platforms.findIndex(
                user_social_platform => user_social_platform.id === action.payload.id); 
            return Object.assign({}, state, {
                user_social_platforms: [
                    ...state.user_social_platforms.slice(0, index),
                    {
                        ...state.user_social_platforms[index],
                        delete_loading: true        
                    },
                    ...state.user_social_platforms.slice(index + 1)
                ]
            });
        }
        case DELETE_SOCIAL_MEDIA_HANDLE: {
            const index = state.user_social_platforms.findIndex(
                user_social_platform => user_social_platform.id === action.params.id); 
            return Object.assign({}, state, {
                delete_social_media_status_code: action.payload.status,
                delete_social_media_messages: action.payload.data.message,
                user_social_platforms: [
                    ...state.user_social_platforms.slice(0, index),
                    ...state.user_social_platforms.slice(index + 1)
                ]
            });
        }
        case NETWORK_ERROR_DELETING_SOCIAL_MEDIA_HANDLE: {
            const index = state.user_social_platforms.findIndex(
                user_social_platform => user_social_platform.id === action.params.id); 
            return Object.assign({}, state, {
                delete_social_media_status_code: action.payload.status,
                delete_social_media_messages: action.payload.data.message,
                user_social_platforms: [
                    ...state.user_social_platforms.slice(0, index),
                    {
                        ...state.user_social_platforms[index],
                        delete_loading: false        
                    },
                    ...state.user_social_platforms.slice(index + 1)
                ]
            });
        }
        default: 
            return state;
    }
}
import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

/**
 * 
 * @param {Function} props setParentState
 * @param {String} props defaultSrc
 */
const ImageCropper2 = (props) => {

    const cropperRef = useRef(null);

    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        props.setParentState(cropper.getCroppedCanvas().toDataURL());
    };

    

    return (
        <Cropper
            src={props.defaultSrc}
            style={{ height: props.responsiveHeight, width: "100%" }}
            zoomTo={1}
            initialAspectRatio={1}
            aspectRatio={1.0}
            viewMode={1}
            guides={false}
            crop={onCrop}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            maxCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} 
            ref={cropperRef}
        />
    );
};

export default ImageCropper2;
import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Layout from '../../../layout/Layout';
import TaskCard from "./component/task-card";
import * as action from "./actions/task.action"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { LoadingSection } from 'components/loaders/PageSpinners';
import Blank from 'components/commonCards/Blank';

class Task extends Component{

    componentDidMount(){
        this.props.actions.fetchTask();
    }

    render(){
        let reducers = this.props.reducers;
        return(
            <Layout>

                <Helmet>
                    <title>Tasks | Cluster</title>
                </Helmet>

                <div className="app-content content">

                    <div className="content-overlay"> </div>

                    <div className="content-wrapper">
                        <div className="content-body">

                            <div className="content-header row">
                                <div className="content-header-left col-12 mb-2 mt-1">
                                    <div className="breadcrumbs-top">
                                    <h5 className="content-header-title float-left pr-1 mb-0">Tasks</h5>
                                    <div className="breadcrumb-wrapper d-none d-sm-block">
                                        <ol className="breadcrumb p-0 mb-0 pl-1">
                                        <li className="breadcrumb-item">
                                            <a href="/"><i className="bx bx-home-alt"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active">Tasks</li>
                                        </ol>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        
                            {reducers.fetch_task_loading? 
                                <LoadingSection />
                                : 
                                reducers.tasks.length > 0 ?
                                    reducers.tasks.map(
                                        (value, index) => 
                                            <TaskCard  
                                                data={value} 
                                                status={value.status} 
                                                key={index} 
                                                actions={this.props.actions} 
                                                reducers = {reducers}
                                            />
                                    )
                                    :
                                    <div className="col-sm-12">
                                        <Blank>
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3>No Task Currently Available.</h3>
                                                    <small>Please check back later or when you receive an email notification that a new task has been created.</small>
                                                </div>
                                            </div>
                                        </Blank>
                                    </div>
                            }
                            
                        </div>

                    </div>
              
                </div>

            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({ reducers: state.dashboardTaskReducer });
const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(action, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Task));

import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Pagination } from 'react-laravel-paginex';
import { Helmet } from "react-helmet";

import * as Actions from './actions/promotion.action';
import Layout from '../../../../layout/Layout';
import PromotionRowAlt from './components/PromotionRowAlt'
import TwitterTrend from './components/TwitterTrend';
import { LoadingSection } from 'components/loaders/PageSpinners';
import { changePage } from 'helpers/url/url-parameters';
import { getLocalToken } from 'helpers/authentication/token';
import {
	networkNotification,
    customNotification
} from 'components/notifications/Notifications';
import Modal from './components/modal';


class Promotions extends Component {

    constructor(props){
        super(props)
        this.state = {
            filter:{ },
            page : 1,

            modalIsOpen: false
        }
    }

    componentDidMount(){

        let urlParams =  new URLSearchParams(window.location.search);
        let page =  urlParams.get('page') || 1;
        this.setState({page: page})

        this.props.actions.fetchPromotion(page, this.state.filter)
    }

    updateState(e, index){
        this.setState({filter: {
            ...this.state.filter,
            [index]: e.target.value
        }})
    }

    applyFilter(){
        this.props.actions.fetchPromotion(this.state.page, this.state.filter)
    }

    verifyPromotion = async (event, promotionId, url, type) => {
        event.preventDefault();
        const { beforeVerifyPromotion, verifyPromotion } = this.props.actions;
        await beforeVerifyPromotion(promotionId);
        await verifyPromotion(getLocalToken(), {
            id: promotionId,
            url
        }, type);  
        
        let { verification_status_code, verification_messages } = this.getReducer();

        networkNotification(verification_messages, verification_status_code);

        if(verification_status_code == 200){
            let urlParams =  new URLSearchParams(window.location.search);
            let page =  this.state.page ? this.state.page: 1;
            this.setState({page: page})
            this.props.actions.fetchPromotion(page, this.state.filter)
        }
    }

    claimPromotion = async (event, promotionId, type) => {
        event.preventDefault();
        const { beforeClaimPromotion, claimPromotion } = this.props.actions;
        await beforeClaimPromotion(promotionId);
        await claimPromotion(getLocalToken(), {
            id: promotionId
        }, type);  
        
        let { claim_status_code, claim_messages } = this.getReducer();

        networkNotification(claim_messages, claim_status_code);

        if(claim_status_code == 200){
            let urlParams =  new URLSearchParams(window.location.search);
            let page =  this.state.page ? this.state.page: 1;
            this.setState({page: page})
            this.props.actions.fetchPromotion(page, this.state.filter)
        }
    }

    openModal = (modelPlatformData) => {
        this.setState({modalIsOpen: true})   
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    helpVideo = (e) => {
        e.preventDefault();

        this.openModal();
    }

    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
     getReducer = () => {
        return this.props.dashboardPromotionReducer;
    }


    render(){
        const {dashboardPromotionReducer} = this.props;  
        const {promotions, promotion_collection, cost_per_trend} = dashboardPromotionReducer;     

        return(
            <Layout>

                <Helmet>
                    <title>Your Promotions | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Promotions</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item"><span>Promoter</span>
                                    </li>
                                    <li className="breadcrumb-item active">Promotions
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">


                            <section id="bg-variants">

                                {/*
                                <div className="users-list-filter px-1">
                                    <form>
                                        <div className="row border rounded py-2 mb-2">

                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <label htmlFor="users-list-status">Status</label>
                                                <fieldset className="form-group">
                                                    <select className="form-control" id="users-list-status" onChange={(e)=>this.updateState(e, 'status')}>
                                                        <option value="">Any</option>
                                                        <option value="ongoing">Ongoing</option>
                                                        <option value="completed">Completed</option>
                                                    </select>
                                                </fieldset>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-4">
                                                <label htmlFor="users-list-verified">Payout</label>
                                                <fieldset className="form-group">
                                                    <select className="form-control" id="users-list-verified" onChange={(e)=>this.updateState(e, 'payout')}>
                                                        <option value="">Any</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                </fieldset>
                                            </div>
                                            
                                            <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
                                                <button type="reset" className="btn btn-outline-danger btn-block glow users-list-clear mb-0" onClick={()=>this.applyFilter()}>Filter</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                */}






                                {dashboardPromotionReducer.loading ?

                                <LoadingSection />

                                :
                                <div className="row">

                                    <div className="col-12">
                                        {
                                            Object.keys(dashboardPromotionReducer.promotions).length > 0 ?

                                                promotion_collection.length > 0 ?
                                                    promotion_collection.map((data)=> {
                                                        switch(data.advert.social_platform_type_id){
                                                            case 4: {
                                                                return <TwitterTrend key={data.id} data={data} costPerTrend={cost_per_trend} helpVideo={this.helpVideo} verifyPromotion={this.verifyPromotion} claimPromotion={this.claimPromotion} />
                                                            }
                                                            case 1:
                                                            default: {
                                                                return <PromotionRowAlt key={data.id} data={data} />
                                                            }    
                                                        }
                                                    })
                                                    :
                                                <div className="text-center" style={{padding: '100px 0'}}><i className="bx bxs-info-circle"></i> No data found</div>
                                            : 
                                            <div className="text-center" style={{padding: '100px 0'}}><i className="bx bxs-info-circle"></i> No data found</div>
                                        }
                                    </div>
                                </div>
                                }


                                {
                                    Object.keys(dashboardPromotionReducer.promotions).length > 0 ?
                                        (promotions.last_page >= promotions.current_page && promotions.last_page !== 1) &&    
                                            <nav aria-label="Page navigation" className="pagination pagination-danger pagination-borderless justify-content-center mt-2" >

                                            <Pagination 
                                                buttonIcons={true} 
                                                nextButtonIcon="bx bx-chevron-right" 
                                                prevButtonIcon="bx bx-chevron-left"
                                                changePage={changePage} 
                                                data={promotions} 
                                            />
                                            </nav>
                                    : ''
                                }


                                
                            </section>


                        </div>

                    </div>
                </div> 

                <Modal 
                    show= {this.state.modalIsOpen} 
                    closeModal= {() => this.closeModal()}
                />

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardPromotionReducer: state.dashboardPromotionReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Promotions));

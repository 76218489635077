import axios from 'axios';

import {
    BEFORE_FETCH_PROMOTER_EARNINGS,
    FETCH_PROMOTER_EARNINGS,
    NETWORK_ERROR_FETCHING_PROMOTER_EARNINGS,

    BEFORE_WITHDRAW_AVAILABLE_BALANCE,
    WITHDRAW_AVAILABLE_BALANCE,
    NETWORK_ERROR_WITHDRAWING_AVAILABLE_BALANCE,

    BEFORE_FETCH_EARNINGS_BAR_CHART_DATA,
    FETCH_EARNINGS_BAR_CHART_DATA,
    NETWORK_ERROR_FETCHING_EARNINGS_BAR_CHART_DATA,

    BEFORE_FETCH_EARNINGS_PIE_CHART_DATA,
    FETCH_EARNINGS_PIE_CHART_DATA,
    NETWORK_ERROR_FETCHING_EARNINGS_PIE_CHART_DATA
} from '../types/earnings.types';

import {
    endpoint
} from 'config';

export const beforeFetchEarnings = () => (
    { type:  BEFORE_FETCH_PROMOTER_EARNINGS }
)

export const fetchEarnings = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}earnings`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });
            console.log("Earning Stats: ", response);
            dispatch({ type: FETCH_PROMOTER_EARNINGS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_PROMOTER_EARNINGS, payload: e.response });
        }
    }
}


export const beforeWithdrawAvailableBalance = () => (
    { type:  BEFORE_WITHDRAW_AVAILABLE_BALANCE }
)

export const withdrawAvailableBalance = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}withdraw`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });
            
            dispatch({ type: WITHDRAW_AVAILABLE_BALANCE, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_WITHDRAWING_AVAILABLE_BALANCE, payload: e.response });
        }
    }
}


/**
 * Bar Chart Data
 */
export const beforeFetchBarChartData = () => (
    { type:  BEFORE_FETCH_EARNINGS_BAR_CHART_DATA }
)

export const fetchBarChartData = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}earnings/charts/bar`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });
            console.log("Recharts Data: ", response);
            dispatch({ type: FETCH_EARNINGS_BAR_CHART_DATA, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_EARNINGS_BAR_CHART_DATA, payload: e.response });
        }
    }
}



/**
 * Pie Chart Data
 */
export const beforeFetchPieChartData = () => (
    { type:  BEFORE_FETCH_EARNINGS_PIE_CHART_DATA }
)

export const fetchPieChartData = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}earnings/charts/pie`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });
            console.log("Recharts Data: ", response);
            dispatch({ type: FETCH_EARNINGS_PIE_CHART_DATA, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_EARNINGS_PIE_CHART_DATA, payload: e.response });
        }
    }
}
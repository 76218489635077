import {
    BEFORE_VIEW_BLOG,
    VIEW_BLOG,
    ERROR_VIEWING_BLOG
} from '../types/viewBlog.type';

let initialState = {
    loading: false,
    message: [],
    status_code: null,
    status: '',
    blog: {},
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_VIEW_BLOG: {
            return {...state, loading: true};
        }
        case VIEW_BLOG: {
            return {...state,
                loading: false,
                status: action.payload.status,
                status_code: action.payload.status_code,
                messages: action.payload.message,
                blog: action.payload.result,
                paragraphs: action.payload.result.content ? action.payload.result.content.split('\n'):[]
            };
        }
        case ERROR_VIEWING_BLOG: {
            return {...state,
                loading: false
            };
        }
        
        default: 
            return state;
    }
}
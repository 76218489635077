import React, { Component } from "react";
import { BrowserRouter, Route} from "react-router-dom";
import { connect } from "react-redux";

import Authenticated from '../middlewares/authenticated/Authenticated';
import Auth from '../themes/theme-five/pages/auth/Auth';

import Onboarding from '../themes/theme-five/pages/dashboard/onboarding/Onboarding';
import Campaign from '../themes/theme-five/pages/campaign/Campaign';

//import Dashboard from '../themes/theme-five/pages/dashboard/Dashboard';
import Index from '../themes/theme-five/pages/dashboard/index/Index';

import AdsHistory from '../themes/theme-five/pages/dashboard/ads/ads-history/AdsHistory';
import Create from '../themes/theme-five/pages/dashboard/ads/create/Create';
import Details from '../themes/theme-five/pages/dashboard/ads/details/Details';

import Earnings from '../themes/theme-five/pages/dashboard/promoter/earnings/Earnings';
import Campaigns from '../themes/theme-five/pages/dashboard/promoter/campaigns/Campaigns';
import Promotions from '../themes/theme-five/pages/dashboard/promoter/promotions/Promotions';
import TransactionHistory from '../themes/theme-five/pages/dashboard/transactions/transaction-history/TransactionHistory';

import SettingsIndex from 'themes/theme-five/pages/dashboard/profile/settings/settings-index/SettingsIndex';
import SocialPlatforms from 'themes/theme-five/pages/dashboard/profile/settings/social-platforms/SocialPlatforms';

import BankAccounts from 'themes/theme-five/pages/dashboard/profile/bank-accounts/BankAccounts';
import Notifications from 'themes/theme-five/pages/dashboard/profile/notifications/Notifications';
import NotificationDetails from 'themes/theme-five/pages/dashboard/profile/notification-details/NotificationDetails';
import Task from 'themes/theme-five/pages/dashboard/tasks/index';
import UserLevels from 'themes/theme-five/pages/dashboard/profile/user-levels/UserLevels';
import Referrals from 'themes/theme-five/pages/dashboard/profile/referrals/Referrals';
import Referrer from 'themes/theme-five/pages/referrer/Referrer';
import LeaderBoard from 'themes/theme-five/pages/dashboard/leader-board/LeaderBoard';
import WeeklyPromo from 'themes/theme-five/pages/dashboard/weekly-promo/WeeklyPromo';

import Blog from 'themes/theme-five/pages/home/Blog';
import ViewBlog from 'themes/theme-five/pages/home/ViewBlog';

//import Home from '../pages/theme-five/home/Home';

class ThemeFiveRoute extends Component {

	componentDidMount(){
		
	}

	render() {
		return (
			<BrowserRouter>
				
				<Route exact={true} path="/auth" render={(props) => <Auth />} />
				<Route exact={true} path="/campaign/:campaign_id" render={(props) => <Campaign />} />
				<Route exact={true} path="/referrer/:username" render={(props) => <Referrer />} />

				<Route exact={true} path="/" render={(props) => <Authenticated><Onboarding><Index /></Onboarding></Authenticated>} />
				<Route exact={true} path="/blog" render={(props) => <Blog />} />
				<Route exact={true} path="/blog/:id/:title?" render={(props) => <ViewBlog />} />
				<Route exact={true} path="/leader-board" render={(props) => <Authenticated><Onboarding><LeaderBoard /></Onboarding></Authenticated>} />
				<Route exact={true} path="/weekly-promo" render={(props) => <Authenticated><Onboarding><WeeklyPromo /></Onboarding></Authenticated>} />

				<Route exact={true} path="/ads/history" render={(props) => <Authenticated><Onboarding><AdsHistory /></Onboarding></Authenticated>} />
				<Route exact={true} path="/ads/history/:advert_id" render={(props) => <Authenticated><Onboarding><Details /></Onboarding></Authenticated>} />
				<Route exact={true} path="/ads/create" render={(props) => <Authenticated><Onboarding><Create /></Onboarding></Authenticated>} />

				<Route exact={true} path="/promoter/earnings" render={(props) => <Authenticated><Onboarding><Earnings /></Onboarding></Authenticated>} />
				<Route exact={true} path="/promoter/campaigns" render={(props) => <Authenticated><Onboarding><Campaigns /></Onboarding></Authenticated>} />
				<Route exact={true} path="/promoter/promotions" render={(props) => <Authenticated><Onboarding><Promotions /></Onboarding></Authenticated>} />

				<Route exact={true} path="/transaction/history" render={(props) => <Authenticated><Onboarding><TransactionHistory /></Onboarding></Authenticated>} />

				<Route exact={true} path="/profile/settings" render={(props) => <Authenticated><Onboarding><SettingsIndex /></Onboarding></Authenticated>} />
				<Route exact={true} path="/profile/settings/social-platforms" render={(props) => <Authenticated><Onboarding><SocialPlatforms /></Onboarding></Authenticated>} />
				<Route exact={true} path="/profile/bank-accounts" render={(props) => <Authenticated><Onboarding><BankAccounts /></Onboarding></Authenticated>} />
				<Route exact={true} path="/profile/notifications" render={(props) => <Authenticated><Onboarding><Notifications /></Onboarding></Authenticated>} />
				<Route exact={true} path="/profile/notifications/:notification_id" render={(props) => <Authenticated><Onboarding><NotificationDetails /></Onboarding></Authenticated>} />
				<Route exact={true} path="/profile/achievements" render={(props) => <Authenticated><Onboarding><UserLevels /></Onboarding></Authenticated>} />
				<Route exact={true} path="/profile/referrals" render={(props) => <Authenticated><Onboarding><Referrals /></Onboarding></Authenticated>} />
				
				<Route exact={true} path="/tasks" render={(props) => <Authenticated><Onboarding><Task /></Onboarding></Authenticated>} />
				
				
			</BrowserRouter>
		);
	}
}

export default connect(null, null)(ThemeFiveRoute);
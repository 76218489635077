import React, { Component } from 'react'

class PointRow extends Component{
    render(){
        return(
            <tr>
                <td>{this.props.description}</td>
                <td>{this.props.points}</td>
            </tr>
        )
    }
}

export default PointRow;
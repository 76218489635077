import axios from 'axios';
import { getRequest, postRequest } from 'helpers/httpRequest/request';
import {
    FETCH_POINTS_LOADING,
    FETCH_POINTS,
    ERROR_FETCHING_POINTS,
    FETCH_LEVEL_LOADING,
    FETCH_LEVEL,
    ERROR_FETCHTING_LEVEL,
} from '../types/user-level.type';


import {
    endpoint
} from 'config';

export const fetchLevel = (token, data = {}) => {
    return async (dispatch) => {
        try{
            dispatch({ type:  FETCH_LEVEL_LOADING });
            const response = await axios({
                method: 'get',
                url: `${endpoint}user/levels`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });
            console.log("House in here: ", response);
            dispatch({ type: FETCH_LEVEL, payload: response });
        }catch(e){
            dispatch({type: ERROR_FETCHTING_LEVEL, payload: e.response });
        }
    }
}


/*
 * Quick Notes:
 * On a 200 success call, 
 * the catch block still executes
 */
/*
export const fetchLevel = () => {
    return async (dispatch) => {
        try{
            dispatch({ type:  FETCH_LEVEL_LOADING });
            const response = await getRequest('v1', 'user/levels');
            console.log("fetch level response: ", response);
            dispatch({ type: FETCH_LEVEL, payload: response });
        }catch(e){
            console.log("Error response: ", e);
            dispatch({type: ERROR_FETCHTING_LEVEL, payload: e.response });
        }
    }
}

export const fetchPoints = () => {
    return async (dispatch) => {
        try{
            dispatch({ type:  FETCH_POINTS_LOADING });
            const response = await getRequest('v1', 'path');
            
            dispatch({ type: FETCH_POINTS, payload: response });

        }catch(e){
            dispatch({type: ERROR_FETCHING_POINTS, payload: e.response });
        }
    }
}
*/
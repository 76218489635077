import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
// import Footer from './components/footer';
import Nav from './components/nav';
// import Header from './components/header';
import { Helmet } from "react-helmet";
import menu from './scripts/menu';
import * as actions from './actions/blog.action';
import {baseUrl} from "../../../../config"
import SocialMedia from "./components/socialMedia";


const Blog = (props) => {

    useEffect(() => {
        menu();
        props.actions.beforeFetchBlog();
        props.actions.fetchBlog();
        props.actions.fetchHeaderBlog();
    }, []);

    return (
        <>
            <Helmet>
                <title>Blogs | Cluster Advertisement Network</title>
            </Helmet>

            <Nav />

            {
                props.blogReducer.loading ? <div style={{marginTop: '100px', textAlign: 'center'}}>Loading... </div> :
                
                <>
                
                    <section className="py-5" style={{marginTop: "60px"}}>
                        {props.blogReducer.blogHeaderLoading ? <div style={{marginTop: '100px', textAlign: 'center'}}>Loading... </div> :
                
                            props.blogReducer.blogHeader.title ?
                            <div className="container px-5">
                                <div className="card border-0 shadow rounded-3 overflow-hidden">
                                    <div className="card-body p-0">
                                        <div className="row gx-0">
                                            <div className="col-lg-6 col-xl-5 py-lg-5">
                                                <div className="p-4 p-md-5">
                                                    {/* <div className="badge bg-primary bg-gradient rounded-pill mb-2">News</div> */}
                                                    <div className="h2 fw-bolder">{props.blogReducer.blogHeader.title}</div>
                                                    <p>{props.blogReducer.blogHeader.content.slice(0, 150)} ...</p>
                                                    <Link className="stretched-link text-decoration-none" to={`/blog/${props.blogReducer.blogHeader.id}/${props.blogReducer.blogHeader.slug}`}>
                                                        Read more
                                                        <i className="bi bi-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-7"><div className="bg-featured-blog" style={{backgroundImage: `url('${baseUrl}${props.blogReducer.blogHeader.thumbnail}')`, backgroundSize: "cover"}}></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div style={{marginTop: '100px', textAlign: 'center'}}>No post found</div>
                            
                        }
                    </section>
                  
                    <section className="py-5 bg-light">
                   
                        <div className="container px-5">
                            <div className="row">
                                <div className="col-lg-8">

                                    {props.blogReducer.blogs.length > 0 ? 
                                        props.blogReducer.blogs.map((value, index) => (
                                            <div key={`blg${index}`} className="card border-0 shadow rounded-3 overflow-hidden mb-4">
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="h-100" style={{backgroundImage: `url('${baseUrl}${value.thumbnail}')`, backgroundSize: "cover"}}></div>
                                                        </div>
                                                        <div className="col-sm-7 ">
                                                            <div className="p-3">
                                                                {/* <div className="badge bg-primary bg-gradient rounded-pill mb-2">News</div> */}
                                                                <div className="h6 bold-400">{value.title}</div>
                                                                <p>{value.content.slice(0, 100)} ...</p>
                                                                <Link className="stretched-link text-decoration-none" to={`blog/${value.id}/${value.slug}`}>
                                                                    Read more
                                                                    <i className="bi bi-arrow-right"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    : <div> {/*No post found*/}</div>}

                                </div>
                                <div className="col-lg-4 md:margin-auto">
                                    <SocialMedia />
                                </div> 
                            </div>
                        </div>

                       

                        
                    </section>
                   
                </>
            }


        </>
    );

}
const mapStateToProps = (state) => ({ blogReducer: state.blogReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Blog));

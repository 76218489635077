import React, { useEffect } from "react";
import Nav from './components/nav';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import * as actions from './actions/viewBlog.action';
import {baseUrl} from "../../../../config";
import SocialMedia from "./components/socialMedia";
import moment from "moment";



const  ViewBlog = (props) => {
    useEffect(() => {
        props.actions.beforeViewBlog()

        const id = props.match.params.id 
        props.actions.viewBlog(id);

    }, []);

    // console.log(props.viewBlogReducer.blog.content ? props.viewBlogReducer.blog.content.replaceAll('\n', "<br />") : "Nothing yet")
    console.log(props.viewBlogReducer.paragraphs ? props.viewBlogReducer.paragraphs : "Nothing yet")

    
    return(
        <>
            <Helmet>
                <title>Blogs | Cluster Advertisement Network</title>
            </Helmet>

            <Nav />

            {
                props.viewBlogReducer.loading ? 
                    <div style={{textAlign: 'center', marginTop: '40px'}}>Loading...</div> :
                    Object.keys(props.viewBlogReducer.blog).length > 0 ?
                        <section className="py-5">
                            <div className="container my-5">
                                <div className="row gx-5">
                                    <div className="col-lg-2">
                                        <div className="d-flex align-items-center">
                                            <Link className="btn btn-default text-black" to="/blog" style={{color: "black"}}> 
                                            &#8592; Back to blogs</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        
                                        <article>
                                        
                                            <header className="mb-2">
                                            
                                                <h1 className="fw-bolder mb-1">{props.viewBlogReducer.blog.title}</h1>
                                                
                                                <div className="text-muted fst-italic mb-2">{moment(new Date(props.viewBlogReducer.blog.created_at)).format('LL')}</div>
                                            
                                                <Link className="badge bg-secondary text-decoration-none link-light" to="/blog">Blogs</Link>
                                                <span className="badge bg-secondary text-decoration-none link-light ml-1">{props.viewBlogReducer.blog.title}</span>
                                            </header>
                                        
                                            <figure className="mb-4"><img className="img-fluid rounded" src={`${baseUrl}${props.viewBlogReducer.blog.thumbnail}`} alt={props.viewBlogReducer.blog.title} /></figure>
                                        
                                            <section className="mb-5">
                                                {props.viewBlogReducer.paragraphs.map((value, key)=>(
                                                    <p key={`paragraph${key}`} className="fs-5 mb-1" style={{color: '#222', fontFamily: 'sans-serif', lineHeight: '32px'}}>{value}</p>
                                                ))}
                                                
                                            </section>
                                        </article>
                                    
                                        {/* <section>
                                            <div className="card bg-light">
                                                <div className="card-body">
                                                    
                                                    <form className="mb-4"><textarea className="form-control" rows="3" placeholder="Join the discussion and leave a comment!"></textarea></form>
                                                
                                                    <div className="d-flex mb-4">
                                                    
                                                        <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                                        <div className="ms-3">
                                                            <div className="fw-bold">Commenter Name</div>
                                                            If you're going to lead a space frontier, it has to be government; it'll never be private enterprise. Because the space frontier is dangerous, and it's expensive, and it has unquantified risks.
                                                        
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                                                <div className="ms-3">
                                                                    <div className="fw-bold">Commenter Name</div>
                                                                    And under those conditions, you cannot establish a capital-market evaluation of that enterprise. You can't get investors.
                                                                </div>
                                                            </div>
                                                        
                                                            <div className="d-flex mt-4">
                                                                <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                                                <div className="ms-3">
                                                                    <div className="fw-bold">Commenter Name</div>
                                                                    When you put money directly to a problem, it makes a good headline.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0"><img className="rounded-circle" src="https://dummyimage.com/50x50/ced4da/6c757d.jpg" alt="..." /></div>
                                                        <div className="ms-3">
                                                            <div className="fw-bold">Commenter Name</div>
                                                            When I look at the universe and all the ways the universe wants to kill us, I find it hard to reconcile that with statements of beneficence.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section> */}
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="-mt-1.5">
                                            <SocialMedia />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    : <div style={{textAlign: 'center', marginTop: '100px'}}>No blog found</div>
            }
        </>
    )

}

const mapStateToProps = (state) => ({ viewBlogReducer: state.viewBlogReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewBlog));

import {
    BEFORE_FETCH_ADVERT_DETAILS,
    FETCH_ADVERT_DETAILS,
    NETWORK_ERROR_FETCHING_ADVERT_DETAILS,

    BEFORE_FETCH_ADVERT_VIEWS_BAR_CHART_DATA,
    FETCH_ADVERT_VIEWS_BAR_CHART_DATA,
    NETWORK_ERROR_FETCHING_ADVERT_VIEWS_BAR_CHART_DATA
} from '../types/details.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,
    advert: null,
    statistics: null,

    bar_chart_loading: false,
    bar_chart_messages: [],
    bar_chart_status_code: null,
    bar_chart_data: []
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_ADVERT_DETAILS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_ADVERT_DETAILS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                advert: action.payload.data.result.advert,
                statistics: action.payload.data.result.statistics
            });
        }
        case NETWORK_ERROR_FETCHING_ADVERT_DETAILS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }


        case BEFORE_FETCH_ADVERT_VIEWS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: true
            });
        }
        case FETCH_ADVERT_VIEWS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: false,
                bar_chart_status_code: action.payload.status,
                bar_chart_messages: action.payload.data.message,
                bar_chart_data: action.payload.data.result.rechart_views_barchart
            });
        }
        case NETWORK_ERROR_FETCHING_ADVERT_VIEWS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: false,
                bar_chart_status_code: action.payload.status,
                bar_chart_messages: action.payload.data.message
            });
        }

        default: 
            return state;
    }
}
import React from 'react';


const logo = (typeId) => {

    let logo = "bx bxs-checkbox";

    switch(typeId){
        case 2: {
            logo = "bx bxl-twitter text-info";
            break;
        }
        case 3: {
            logo = "bx bxl-instagram text-danger";
            break;
        }
        default: {
            logo = "bx bxs-checkbox";
            break;
        }
    }

    return logo;
}

export const SocialHandle = (props) => {

    const { user_social_platform, deleteHandle } = props;

    return(
        <div className="list-group-item list-group-item-action border d-flex mb-1">
            <div className="list-icon">
                <i className={`badge-circle badge-circle-light-secondary ${logo(user_social_platform.type.id)} mr-1 text-primary`}></i>
            </div>
            <div className="list-content">
                <h5>
                    {user_social_platform.type.name} | @{user_social_platform.platform.handle} 
                   
                    &nbsp;
                    {(user_social_platform.platform && user_social_platform.platform.verified_at)
                        ?
                        <i className="bx bxs-check-circle text-success"></i>
                        :
                        null
                    }
                </h5>
                <p className="mb-0">
                    {!user_social_platform.platform.verified_at
                        ?
                        <button disabled={user_social_platform.delete_loading} className="btn btn-outline-danger btn-sm mr-1 mb-1" type="button" onClick={(e) => deleteHandle(e, user_social_platform.id)}>
                            {user_social_platform.delete_loading ? <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> : null}
                            Delete 
                        </button>
                        :
                        null
                    }

                    &nbsp;

                    {!user_social_platform.platform.verified_at
                        ?
                        <button className="btn btn-outline-success btn-sm mr-2  mb-1" type="button" onClick={() => props.openModal(user_social_platform)}>Verify </button>
                        :
                        null
                    }

                    {(user_social_platform.platform && user_social_platform.platform.verified_at)
                        ?
                        <span className="text-success">Account verified.</span>
                        :
                        null
                    }
                </p>

                {(user_social_platform.platform && !user_social_platform.platform.verified_at)
                    ?
                    <p className="mb-0 p-0"><small>Note that once you verify, this handle cannot be changed for this Cluster Account.</small></p>
                    :
                    null
                }
            </div>
        </div>
    );
}
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { PaystackButton } from 'react-paystack';
import { Helmet } from "react-helmet";
//import $ from "jquery";

import Layout from '../../../../layout/Layout';
import ImageCropper2 from './../../../../../../components/fileUpload/ImageCropper2';
import * as Actions from './actions/create.actions';
import { getLocalToken } from 'helpers/authentication/token';
import {
	networkNotification,
    customNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import {
    paystackPublicKey, flutterWavePublicKey
} from 'config';





class Create extends Component {

    constructor(props){
        super(props);

        this.shapeShifter = React.createRef()

        this.state = {
            caption: '',
            description: '',
            hashtag: '',
            social_platform_type_id: 1,
            price: 0,
            website: '',
            previewUrl: null,
            displayPreview: false,
            afterPaymentLoader: false,
            redirecting: false,
            croppedWindow: undefined,
            surveys: [],

            question: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            survey_type: 1,

            shapeShifterHeight: 400
        }
    }


    async componentDidMount(){
        this.setState({shapeShifterHeight: this.shapeShifter.current.offsetWidth});

        const { beforeFetchInitializationData, fetchInitializationData } = this.props.actions;

        await beforeFetchInitializationData();
        fetchInitializationData(getLocalToken());

    }

    /*
    |--------------------------------------------------------------------------
    | Flutterwave Related Functions
    |--------------------------------------------------------------------------
    | 1. config 
    | 2. fwConfig
    | 3. flutterWaveSuccessAction
    */ 
    config = () => {
        return {
            public_key: flutterWavePublicKey,
            tx_ref: Date.now(),
            amount: this.state.price,
            currency: 'NGN',
            payment_options: 'card,mobilemoney,ussd',
            customer: {
                email: this.props.auth_user.email
            }
        };
    }

    fwConfig = () => {
        return {
        ...this.config(),
        text: 'Pay and Upload',
        callback: (response) => {
            console.log('Flutter Wave Response: ', response);
            if(response.status === "successful" || response.status === "completed"){
                closePaymentModal(); // this will close the modal programmatically
                this.flutterWaveSuccessAction(response);
            }else{
                closePaymentModal();
                networkNotification(['Payment Attempt unsuccessful'], 400);
            }
            
        },
        onClose: (response) => {
           // console.log('Flutter Wace Modal Closed: ', response);
        },
      };
    }


    flutterWaveSuccessAction = (response) => {
        this.setState({
            afterPaymentLoader: true
        });
        let formData = new FormData();
        formData.append('transaction_reference', response.tx_ref);
        formData.append('transaction_id', response.transaction_id);
        this.createAdvert(formData);
    };



    /*
    |--------------------------------------------------------------------------
    | Paystack Related Functions
    |--------------------------------------------------------------------------
    | 1. paystackComponentProps 
    | 2. paystackCloseAction
    | 3. paystackSuccessAction 
    */ 

    /** PAYSTACK */
    paystackComponentProps = () => ({
        reference: (new Date()).getTime(),
        email: this.props.auth_user.email,
        amount: (this.state.price * 100), //convert to kobo
        publicKey: paystackPublicKey,
        text: 'Pay and Upload',
        onSuccess: (reference) => this.paystackSuccessAction(reference),
        onClose: this.paystackCloseAction,
        onLoad: this.ken
    })

    /** PAYSTACK */
    paystackCloseAction = () => {}

    /** 
     * PAYSTACK
     * If the payment is successful, this function
     * will send transaction data to our back end
     * to save and finally create the advert.
     */
    paystackSuccessAction = (reference) => {
        this.setState({
            afterPaymentLoader: true
        });
        let formData = new FormData();
        formData.append('transaction_reference', reference.trxref);
        formData.append('transaction_id', reference.transaction);
        this.createAdvert(formData);
    };

   /*
    |--------------------------------------------------------------------------
    | End of Paystack Related Functions
    |--------------------------------------------------------------------------
    */


    /**
     * After the user successfully fills the 
     * adverts form and clicks next, this function
     * will trigger to make an api call that will
     * validate the fields to make sure everything 
     * is appropriate before payment is made.
     */
    handleOnClickNextButton = (event) => {
        event.preventDefault();
        let formData = new FormData();
        formData.append('only_validation', 1);
        this.createAdvert(formData, 1);
    }


    /**
     * When the back button is pressed,
     * this function executes to dismiss
     * the preview window and reinstate form
     */
    handleOnClickPreviousButton = (event) => {
        event.preventDefault();
        this.setState({displayPreview: false});
    }


    /**
     * This function is responsible for making api calls 
     * to our backend to create or validate the data the
     * user has inputed into our create advert from.
     * It is called in 2 typical scenarios
     * 1. handleOnClickNextButton (i.e when user clicks next, to validate inputs)
     * 2. paystackSuccessAction (i.e when user successfully pays, to create advert)
     */
    createAdvert = async (formData, only_validation = null) => {
        
        const { beforeCreateAdvert, createAdvert } = this.props.actions;
        const { caption, description, price, website, croppedWindow, surveys, social_platform_type_id, hashtag } = this.state;
        const croppedImage = await this.dataUrlToFile(croppedWindow);

        formData.append('caption', caption);
        formData.append('description', description);
        formData.append('price', price);
        formData.append('website_url', website);
        formData.append('hashtag', hashtag);
        formData.append('surveys', JSON.stringify(surveys));
        //formData.append('social_platform_type_id', document.getElementById('social_platform_type_id').value);
        formData.append('social_platform_type_id', social_platform_type_id);
        await formData.append('images[]', croppedImage);
        await beforeCreateAdvert();
        await createAdvert(getLocalToken(), formData);
        let { status_code, messages, advert } = this.getReducer();
        
        

        if(status_code === 200){
            if(!only_validation && advert){
                //This triggers after payment Redirect to new page
                networkNotification(messages, status_code);
                setTimeout(function(){
                    window.location = `/ads/history/${advert.pid}`;
                }, 2000);
            }else{
                //Inputs are valid, display preview and payment page
                this.setState({
                    displayPreview: true
                });
            }
        }else{
            //Display alert if there are errors on form
            networkNotification(messages, status_code);
        }

    }


    selectedSocialPlatform = (platforms = []) => {
        const id = this.state.social_platform_type_id; //document.getElementById('social_platform_type_id').value;
        const platform = platforms.find(x => x.id == id);
        return platform ? platform : {};
    }


    /**
     * This converts the cropped window in base64 format
     * to, an object of File that can be easily validated
     * using Laravel on the backend.
     */
    dataUrlToFile = async (dataUrl, fileName = 'fixedname.png') => {
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }


    /**
     * Whenever updates are made to the form fields,
     * this function synchronizes with the state.
     */
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    /**
     * This function is passed to ImageCropper
     * to update the croppedWindow state directly.
     */
    setParentState = (value) => {
        this.setState({ croppedWindow: value });
    }


    /**
     * This handles image upload and 
     * image upload updates form.
     */
    onImageSelect = async (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = await e.dataTransfer.files;
        } else if (e.target) {
            files = await e.target.files;
        }

        if(files && files.length > 0){
            const reader = new FileReader();
            reader.onload = () => {
                this.setState({ previewUrl: reader.result });
            };
            reader.readAsDataURL(files[0]);
        }
    }


    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardCreateAdvertReducer;
    }


    addSurvey = (e) => {
        e.preventDefault();
        const {surveys, question, option_1, option_2, option_3, option_4, survey_type } = this.state;
        if(surveys.length > 1){
            customNotification(["Survey max limit reached."], "Oops", "warning");
        }else{
            const rawOptions = [option_1, option_2, option_3, option_4];
            const options = [];
            const errors = [];

            //I can even take the liberty to use a backend validator here i.e api call
            rawOptions.forEach(function(rawOption){
                if(rawOption !== ""){
                    options.push(rawOption);
                }
            });

            if(question == ""){
                errors.push("Survey question is required");
            }

            if(option_1 == "" || option_2 == ""){
                errors.push("Options A & B are mandatory for your survey question.");
            }

            var survey_type_id = document.querySelector(`input[name="survey_type"]:checked`)
                ? document.querySelector(`input[name="survey_type"]:checked`).value
                : survey_type;

            if(errors.length == 0){
                this.setState(prevState => ({
                    surveys: [ {id: (new Date()).getTime(), question, options, survey_type_id}, ...prevState.surveys]
                }));
                window.$("#survey-modal").modal("hide");
                customNotification(["Survey question added successfully."], "Success", "success");
            }else{
                customNotification(errors);
            }

            
        }
    }


    /**
     * Delete an object from the surveys state array
     * @param {*} e 
     * @param {int} id 
     */
    deleteSurvey = (e, id) => {
        e.preventDefault();
        this.setState({surveys: this.state.surveys.filter(function(survey) { 
            return survey.id !== id
        })});

        customNotification(["Survey question removed."], "Completed", "warning");
    }


    prepareSurveyForm = (e) => {
        e.preventDefault();
        //unset all the fields here
        this.setState({
            question: '',
            option_1: '',
            option_2: '',
            option_3: '',
            option_4: '',
            survey_type: 1,
        });

        document.getElementById("default_checked").checked = true;
    }

    surveyOptionWidth = (count) => {
        return 12/count;
    }


    dynamicWidth = (idAttribute) => {
        const elem = document.getElementById(idAttribute);
        return elem ? elem.offsetWidth : 400;
    }


    render(){

        const { 
            caption, description, price, website, hashtag, previewUrl, 
            displayPreview, afterPaymentLoader, croppedWindow, redirecting, surveys, social_platform_type_id
        } = this.state;

        const { question, option_1, option_2, option_3, option_4, survey_type } = this.state;

        const { loading, initialization_data, initialization_loading } = this.getReducer();

        const socialPlatformTypes = initialization_data && initialization_data.social_platform_types 
            ? initialization_data.social_platform_types : [];

        return(
            <Layout>

                <Helmet>
                    <title>Create Ad | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Ad Centre</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item"><span>Ad Centre</span>
                                    </li>
                                    <li className="breadcrumb-item active">Create Ad
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">
                            
                            {initialization_loading
                            ?
                                <section id="basic-vertical-layouts">
                                    <div className="row match-height">
                                        <div className="col-md-12">
                                            <LoadingSection />
                                        </div>
                                    </div>
                                </section> 
                            :
                                <section id="basic-vertical-layouts">
                                    <div className="row match-height">

                                        {!displayPreview &&
                                            <div className="col-md-6 col-lg-6 col-12">
                                            <div className="card">
                                                <div className="card-header">
                                                <h4 className="card-title">Provide Ad Details</h4>
                                                </div>
                                                <div className="card-body">
                                                    <form className="form form-vertical" onSubmit={this.handleOnClickNextButton}>
                                                        <div className="form-body">
                                                            <div className="row">

                                                                <div className="col-12">
                                                                    {!previewUrl &&
                                                                        <React.Fragment>
                                                                            <label for="image" style={{width: '100%'}}>
                                                                                <input type="file" name="image" id="image" style={{display: 'none'}} onChange={this.onImageSelect}  />
                                                                                <div ref={this.shapeShifter} className="text-center" style={{width:`'100%'`, height: `${this.state.shapeShifterHeight}px`, border: '2px dashed #555', display: 'flex', alignItems: 'center',  cursor: 'pointer'}}>
                                                                                    
                                                                                    <div style={{width: '100%'}}>
                                                                                    <i className="bx bx-image"></i> <br />Click to Upload AD Banner
                                                                                    </div>

                                                                                </div>
                                                                                <img style={{width: '100%', height: '0px' }} src="/logo-black.png" alt="logo" />
                                                                            </label>
                                                                        </React.Fragment>
                                                                    }

                                                                    {previewUrl &&
                                                                        <React.Fragment>
                                                                            <label for="banner-update">
                                                                                <input type="file" id="banner-update" style={{display: 'none'}}  onChange={this.onImageSelect}  />
                                                                                <span className="btn btn-secondary" type="button"><i className="bx bx-camera"></i> Update Banner</span>
                                                                            </label>
                                                                            <ImageCropper2 defaultSrc={previewUrl} responsiveHeight={this.state.shapeShifterHeight} setParentState={this.setParentState} /> 
                                                                        </React.Fragment>
                                                                    }

                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="form-group mt-1">
                                                                        <label for="first-name-vertical">Caption &nbsp;<span style={{color: "#c00"}}>*</span></label>
                                                                        <input 
                                                                            type="text" id="caption-vertical" 
                                                                            className="form-control" 
                                                                            name="caption"
                                                                            placeholder="Caption" 
                                                                            onChange={this.handleChange}  
                                                                            value={caption} 
                                                                            disabled={loading}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label for="email-id-vertical">Description &nbsp;<span style={{color: "#c00"}}>*</span></label>
                                                                        <fieldset className="form-group">
                                                                            <textarea 
                                                                                className="form-control" 
                                                                                id="basicTextarea" 
                                                                                rows="3" 
                                                                                placeholder="250 character limit" 
                                                                                name="description" 
                                                                                onChange={this.handleChange}
                                                                                disabled={loading}
                                                                            >
                                                                                {description}
                                                                            </textarea>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 col-12">
                                                                    <div className="form-group">
                                                                        <label for="contact-info-vertical">Amount &nbsp;<span style={{color: "#c00"}}>*</span></label>
                                                                        <input 
                                                                            type="number" 
                                                                            id="company-column" 
                                                                            className="form-control" 
                                                                            name="price" 
                                                                            onChange={this.handleChange}  
                                                                            value={price}
                                                                            placeholder="Amount" 
                                                                            disabled={loading}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6 col-12">
                                                                    <div className="form-group">
                                                                        <label for="contact-info-vertical">Target Platform &nbsp;<span style={{color: "#c00"}}>*</span></label>
                                                                        
                                                                        <select 
                                                                            className="form-control" 
                                                                            id="social_platform_type_id" 
                                                                            name="social_platform_type_id"
                                                                            onChange={this.handleChange}
                                                                            value={social_platform_type_id} 
                                                                        >
                                                                            {
                                                                                socialPlatformTypes.map(
                                                                                    socialPlatformType => <option value={socialPlatformType.id}>{socialPlatformType.name}</option>
                                                                                )
                                                                            }
                                                                        </select>

                                                                        <br />

                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6 col-12">
                                                                    <div className="form-group">
                                                                        <label for="contact-info-vertical">Website Address</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="email-id-column" 
                                                                            className="form-control" 
                                                                            name="website"
                                                                            onChange={this.handleChange}  
                                                                            value={website}
                                                                            placeholder="E.g. https://ads.cluster.live" 
                                                                            disabled={loading}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6 col-12">
                                                                    <div className="form-group">
                                                                        <label for="contact-info-vertical">HashTag</label>
                                                                        <input 
                                                                            type="text" 
                                                                            id="email-id-column" 
                                                                            className="form-control" 
                                                                            name="hashtag"
                                                                            onChange={this.handleChange}  
                                                                            value={hashtag}
                                                                            placeholder="Do not include # " 
                                                                            disabled={loading}
                                                                        />
                                                                    </div>
                                                                </div>



                                                                <div className="col-md-12">

                                                                    <hr />

                                                                </div>

                                                                <div className="col-12 mb-2">
                                                                    
                                                                    {(surveys.length < 2)
                                                                    ?
                                                                        <>
                                                                            <button onClick={this.prepareSurveyForm} className="btn btn-icon rounded-circle btn-primary" data-toggle="modal" data-target="#survey-modal">
                                                                                <i className="bx bx-plus"></i>
                                                                            </button>
                                                                            <span className="ml-1 font-weight-bold text-primary">ADD SURVEY QUESTION</span>
                                                                        </>
                                                                    :
                                                                        <span className="font-weight-bold text-success">SURVEY QUESTIONS</span>
                                                                    }


                                                                    <div className="modal fade text-left" id="survey-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1"
                                                                        aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-scrollable">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                            <h3 className="modal-title" id="myModalLabel1">Add Survey</h3>
                                                                            <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                                                                                <i className="bx bx-x"></i>
                                                                            </button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                            
                                                                                <div className="row justify-content-between" data-repeater-item>
                                                                                    <div className="col-md-12 form-group ">
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-md-6 mb-1">
                                                                                                <input 
                                                                                                    id="default_checked"
                                                                                                    onChange={this.handleChange}  
                                                                                                    value={1}  
                                                                                                    name="survey_type"
                                                                                                    type="radio"
                                                                                                /> Users can tick only one option when answering this question
                                                                                            </div>

                                                                                            <div className="col-12 col-md-6">
                                                                                                <input 
                                                                                                    onChange={this.handleChange}  
                                                                                                    value={2}  
                                                                                                    name="survey_type"
                                                                                                    type="radio" 
                                                                                                /> Users can tick multiple options when answering this question 
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-12 form-group ">
                                                                                        <label>Question &nbsp;<span style={{color: "#c00"}}>*</span></label>
                                                                                        <input 
                                                                                            onChange={this.handleChange}  
                                                                                            value={question}  
                                                                                            name="question"
                                                                                            type="text" className="form-control" 
                                                                                            placeholder="e.g. Do you like our product?" 
                                                                                        />
                                                                                    </div>

                                                                    
                                                                                    <div className="col-md-12 form-group d-flex align-items-center">
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend">
                                                                                                <span className="input-group-text" id="basic-addon1"><i className="bx bx-disc"></i>&nbsp;Option A &nbsp;<span style={{color: "#c00"}}>*</span></span>
                                                                                            </div>
                                                                                            <input 
                                                                                                onChange={this.handleChange}  
                                                                                                value={option_1}  
                                                                                                name="option_1"
                                                                                                type="text" 
                                                                                                className="form-control" 
                                                                                                placeholder="e.g. yes" 
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                

                                                                                    <div className="col-md-12 form-group d-flex align-items-center">
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend">
                                                                                                <span className="input-group-text" id="basic-addon1"><i className="bx bx-disc"></i>&nbsp;Option B &nbsp;<span style={{color: "#c00"}}>*</span></span>
                                                                                            </div>
                                                                                            <input 
                                                                                                onChange={this.handleChange}  
                                                                                                value={option_2}  
                                                                                                name="option_2"
                                                                                                type="text" 
                                                                                                className="form-control" 
                                                                                                placeholder="e.g. no" 
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="col-md-12 form-group d-flex align-items-center">
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend">
                                                                                                <span className="input-group-text" id="basic-addon1"><i className="bx bx-disc"></i>&nbsp;Option C &nbsp;&nbsp;&nbsp;</span>
                                                                                            </div>
                                                                                            <input 
                                                                                                onChange={this.handleChange}  
                                                                                                value={option_3}  
                                                                                                name="option_3"
                                                                                                type="text" 
                                                                                                className="form-control" 
                                                                                                placeholder="e.g. indifferent" 
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="col-md-12 form-group d-flex align-items-center">
                                                                                        <div className="input-group">
                                                                                            <div className="input-group-prepend">
                                                                                                <span className="input-group-text" id="basic-addon1"><i className="bx bx-disc"></i>&nbsp;Option D &nbsp;&nbsp;&nbsp;</span>
                                                                                            </div>
                                                                                            <input 
                                                                                                onChange={this.handleChange}  
                                                                                                value={option_4}  
                                                                                                name="option_4"
                                                                                                type="text" 
                                                                                                className="form-control" 
                                                                                                placeholder="e.g. All of the above." 
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className="modal-footer">
                                                                            <button type="button" className="btn btn-light-secondary" data-dismiss="modal">
                                                                                <i className="bx bx-x d-block d-sm-none"></i>
                                                                                <span className="d-none d-sm-block">Close</span>
                                                                            </button>
                                                                            <button type="button" className="btn btn-success ml-1" onClick={this.addSurvey}>
                                                                                <i className="bx bx-check d-block d-sm-none"></i>
                                                                                <span className="d-none d-sm-block">Add</span>
                                                                            </button>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>




                                                                </div>

                                                                <div className="col-md-12">
                                                                {
                                                                    surveys.map(
                                                                        survey => 
                                                                            <div key={survey.id} className="row justify-content-between border rounded mb-1" data-repeater-item>
                                                                                
                                                                                <div className="col-md-12 form-group mb-1 mt-1 d-flex align-items-center" >
                                                                                    <button onClick={(e) => this.deleteSurvey(e, survey.id)} className="btn btn-icon btn-outline-danger rounded-circle mr-1" type="button" data-repeater-delete>
                                                                                        <i className="bx bx-trash-alt"></i>
                                                                                    </button>
                                                                                    {survey.question}
                                                                                </div>
                                                                                
                                                                                {survey.options.map(
                                                                                    option =>
                                                                                    <div  className={`col-md-${this.surveyOptionWidth(survey.options.length)} col-12 form-group d-flex align-items-center`}>
                                                                                        <i className="bx bx-disc mr-1"></i>
                                                                                        <input type="text" disabled={true} value={option} className="form-control" placeholder="Option" />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                    )
                                                                }
                                                                </div>

                                                                <div className="col-12 d-flex justify-content-end mt-2">
                                                                    <button type="submit" disabled={loading} className="btn btn-success mr-1">
                                                                        <b>
                                                                            {loading &&
                                                                                <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                                            }
                                                                            Proceed to Preview &raquo;
                                                                        </b>
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {displayPreview && 
                                            
                                            (afterPaymentLoader
                                            ?
                                            <div className="col-md-12">
                                                <LoadingSection message="Payment Successful, Creating Advert Assets." />
                                            </div>
                                            :
                                            <div className="col-md-8 col-lg-8 col-12 order-1 order-md-2">
                                                <div className="card box-search">
                                                    <div className="card-body">
                                                    <div className="text-center mb-1">
                                                        {previewUrl &&
                                                            <img className="rounded" style={{width: '100%'}} src={croppedWindow ? croppedWindow : ''} alt="banner" />
                                                        }
                                                    </div>

                                                    <small className="text-warning"><i>Caption Preview</i></small>
                                                    <h5>{caption}</h5>
                                                    
                                                    {website !== "" 
                                                        ?
                                                            <p className="mt-1">
                                                                
                                                                <i className="bx bx-link-external pr-1 align-middle"></i>
                                                                <a href={website} className="align-middle" target="_blank" rel="noopener noreferrer">
                                                                    {website}
                                                                </a>
                                                            </p>
                                                        :
                                                            <p className="mt-1">
                                                                    
                                                                <i className="bx bx-link-external pr-1 align-middle"></i>
                                                                <span className="align-middle text-secondary">
                                                                    <i>Website Not Provided</i>
                                                                </span>
                                                            </p>
                                                    }

                                                    <small className="text-warning"><i>Description Preview</i></small>
                                                    <p className="card-text">
                                                        {description}
                                                    </p>

                                                    <small className="text-warning"><i>Target Platform</i></small>
                                                    <p className="card-text">
                                                        {this.selectedSocialPlatform(socialPlatformTypes).name}
                                                    </p>

                                                    {hashtag !== "" 
                                                        ?
                                                            <>
                                                                <small className="text-warning"><i>HashTag</i></small>
                                                                <p className="card-text">
                                                                    #{hashtag}
                                                                </p>
                                                            </>
                                                        :
                                                            <>
                                                                <small className="text-warning"><i>HashTag</i></small>
                                                                <p className="card-text">
                                                                    HashTag Not Provided
                                                                </p>
                                                            </>
                                                    }

                                                    <div className="row knowledge-panel text-center py-1">
                                                        
                                                        <div className="col d-inline-block">
                                                            <p className="mb-0">&#8358; {(price).toLocaleString()}</p>
                                                            <small className="text-warning"><i>Preview Amount</i></small>
                                                        </div>
                                                        
                                                    </div>

                                                    
                                                    {surveys.length > 0 && <small className="text-warning mb-1"><i>Survey Preview</i></small>}
                                                    
                                                    {
                                                        surveys.map(
                                                            survey => 
                                                                <div key={survey.id} className="row justify-content-between border rounded mb-1" data-repeater-item>
                                                                    
                                                                    <div className="col-md-12 form-group mb-1 mt-1 d-flex align-items-center text-dark" >
                                                                        
                                                                        <b>{survey.question}</b>
                                                                    </div>
                                                                    
                                                                    {survey.options.map(
                                                                        option =>
                                                                        <div  className={`col-md-${this.surveyOptionWidth(survey.options.length)} col-12 form-group d-flex align-items-center`}>
                                                                            <i className="bx bx-disc mr-1"></i>
                                                                            <input type="text" disabled={true} value={option} className="form-control" placeholder="Option" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                        )
                                                    }
                                                    
                                                    
                                                    <div className="knowledge-panel-suggestion">
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 d-flex mt-2 justify-content-end">
                                                                <button className="btn btn-outline-light btn-block" onClick={this.handleOnClickPreviousButton}>&laquo; Back to Edit</button>
                                                            </div>
                                                            <div className="col-12 col-md-6 d-flex mt-2 justify-content-end">
                                                                <FlutterWaveButton className="btn btn-success btn-block mr-1" {...this.fwConfig()} />
                                                                {/*<PaystackButton className="btn btn-success btn-block mr-1" {...this.paystackComponentProps()} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </section>
                            }
                            



                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardCreateAdvertReducer: state.dashboardCreateAdvertReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create));
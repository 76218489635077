export const FETCH_TASK_LOADING = 'FETCH_TASK_LOADING';
export const FETCH_TASK = 'FETCH_TASK';
export const ERROR_FETCHING_TASK = 'ERROR_FETCHING_TASK';

export const ACCEPT_TASK_LOADING = 'ACCEPT_TASK_LOADING';
export const ACCEPT_TASK = 'ACCEPT_TASK';
export const ERROR_ACCEPTING_TASK = 'ERROR_ACCEPTING_TASK';

export const CLAIM_TASK_LOADING = 'CLAIM_TASK_LOADING';
export const CLAIM_TASK = 'CLAIM_TASK';
export const ERROR_CLAIMING_TASK = 'ERROR_CLAIMING_TASK';
import React from 'react';


const Blank = (props) => {

    return (
        <div className="pt-5 pb-5 mt-5 mb-5 d-flex justify-content-center align-items-center">
            {props.message}
            {props.children}
        </div>
    );
}

export default Blank;
import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Helmet } from "react-helmet";

import { BarChart, Bar, Cell,   Brush, Label,
    ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    PieChart, Pie, Sector 
} from 'recharts';

import Blank from 'components/commonCards/Blank';
import * as Actions from './actions/details.actions';
import Layout from '../../../../layout/Layout';
import MonthFilter from 'components/monthFilter/MonthFilter';
import { getLocalToken } from 'helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';



const AdvertStatus = (props) => {
    const color = props.color ? props.color : 'light';
    const title = props.title ? props.title : '';

    return(
        <p className={`text-${color}`}>
            <span className={`bullet bullet-${color} bullet-sm`}></span>
            &nbsp; {title}
        </p>  
    );
}



class Details extends Component {

    constructor(props){
        super(props);

        this.state = {
            moment: moment()
        };
    }

    async componentDidMount(){
        //console.log("Auth:: ", this.props.auth_user);
        const { beforeFetchAdvertDetails, fetchAdvertDetails } = this.props.actions;
        const { beforeFetchBarChartViewsData, fetchBarChartViewsData } = this.props.actions;

        const pid = this.props.match.params.advert_id;

        await beforeFetchAdvertDetails();
        await beforeFetchBarChartViewsData();
        await Promise.all([
            fetchAdvertDetails(getLocalToken(), pid),
            fetchBarChartViewsData(getLocalToken(), { date: this.state.moment.format(), pid})
        ]);
    }

    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardAdvertDetailsReducer;
    }


    dynamicWidth = (idAttribute) => {
        const elem = document.getElementById(idAttribute);
        return elem ? elem.offsetWidth : 600;
    }


    /**
     * Note that the month is an object of moment
     */
    onFilterViewsByMonth = async (month) => {
        const { beforeFetchBarChartViewsData, fetchBarChartViewsData } = this.props.actions;
        this.setState({ moment: month });

        const pid = this.props.match.params.advert_id;

        await beforeFetchBarChartViewsData();
        await fetchBarChartViewsData(getLocalToken(), { date: month.format(), pid });
    }


    surveyOptionWidth = (count) => {
        return 12/count;
    }

    surveyAnswersPercent = (no, total) => {
        if(no && total){
            return Math.round(no/total * 100);
        }

        return 0;
    }


    render(){

        const {advert, status_code, statistics, bar_chart_data, bar_chart_loading } = this.getReducer();

        const { moment } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Ad Details & Metrics | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Advert Details</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    
                                    <li className="breadcrumb-item"><a href="/ads/history">Adverts History</a>
                                    </li>
                                    {advert &&
                                    <li className="breadcrumb-item active">
                                        {advert.caption}
                                    </li>
                                    }
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">

 
                            <section>
                                {status_code && status_code != 200 
                                    ?
                                        <Blank>
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <h3>Error fetching advert details</h3>
                                                    <small>Page could not be loaded, click button below to return to adverts history.</small>
                                                </div>
                                                <div className="col-md-12 text-center mt-2">
                                                    <a className="btn btn-danger" href="/ads/history">Adverts History</a>
                                                </div>
                                            </div>
                                        </Blank>
                                    :
                                    <div className="row mb-4 flex">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mt-1">

                                            <div className="card h-100 p-1">
                                                
                                                <div className="card-body p-0" id="cluster-chart-container">


                                                    {advert &&
                                                    <div className="mt-2">
                                                        
                                                        <div className="card-body py-0">
                                                            <div className="pb-0">
                                                                {advert.active_at 
                                                                ?   
                                                                    (advert.completed_at
                                                                        ?
                                                                        <AdvertStatus color="primary" title="Live" />
                                                                        :
                                                                        <AdvertStatus color="success" title="In Progress" />
                                                                    )
                                                                :
                                                                    <AdvertStatus color="warning" title="Under Review" />
                                                                }
                                                            </div>

                                                            <div className="d-flex row border rounded">
                                                                
                                                                <div className="user-profile-images col-sm-12 col-md-6 p-0">
                                                                    <img src={`${advert.mediable.full_uri}`} alt="post" style={{width: '100%'}} />
                                                                </div>
                                                                <div className="p-1 col-sm-12 col-md-6">
                                                                    <h5 className="text-dark">{advert.caption}</h5>
                                                                    <p className="user-profile-ellipsis">{advert.description}</p>
                                                                    <div className="mt-1">
                                                                        <p className="text-muted"><small><i className="bx bx-time"></i> {advert.date_created}</small></p>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>

                                                        

                                                        <div className="card-body border rounded mb-3 p-0 mt-3">
                                                            <div className="row text-center">
                                                                <div className="col-md-4 border-right p-1">
                                                                    <div className="badge-circle badge-circle-lg badge-circle-light-dark mx-auto my-1">
                                                                        <i className="bx bx-show-alt font-medium-5"></i>
                                                                    </div>
                                                                    <p className="mb-0">{statistics ? statistics.reach : '--'}</p>
                                                                    <small>Reach</small>
                                                                </div>
                                                                <div className="col-md-4 border-right p-1">
                                                                    <div className="badge-circle badge-circle-lg badge-circle-light-dark mx-auto my-1">
                                                                        <i className="bx bx-group font-medium-5"></i>
                                                                    </div>
                                                                    <p className="mb-0">{statistics ? statistics.promoters : '--'}</p>
                                                                    <small>Promoters</small>
                                                                </div>
                                                                <div className="col-md-4 d-inline-block p-1">
                                                                    <div className="badge-circle badge-circle-lg badge-circle-light-dark mx-auto my-1">
                                                                        <i className="bx bx-globe font-medium-5"></i>
                                                                    </div>
                                                                    <p className="mb-0">{statistics ? statistics.website_clicks : '--'}</p>
                                                                    <small>Website Clicks</small>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="card-body border rounded mb-3 mt-3">

                                                            <div className="badge-circle badge-circle-lg badge-circle-light-dark mx-auto my-1">
                                                                <i className="bx bxs-hourglass-top font-medium-5"></i>
                                                            </div>
                                                            <p className="text-center">Progress</p>

                                                            <p className="text-muted mb-2 text-center line-ellipsis">{advert.progress}%</p>

                                                            <div className="progress progress-bar-success">
                                                                <div className="progress-bar" role="progressbar" aria-valuenow={`${advert.progress}`} aria-valuemin={`${advert.progress}`} aria-valuemax="100" style={{width: `${advert.progress}%`}}>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        {advert.surveys.length > 0 && 
                                                            <div className="card-body border rounded">

                                                                <div className="badge-circle badge-circle-lg badge-circle-light-dark mx-auto my-1">
                                                                    <i className="bx bx-search-alt font-medium-5"></i>
                                                                </div>
                                                                <p className="text-center">Surveys</p>
                                                                {advert.surveys.map(
                                                                    survey => 
                                                                        <div key={survey.id} className="row justify-content-between  mb-1" data-repeater-item>
                                                                            
                                                                            <div className="col-md-12 form-group mb-1 mt-1 d-flex align-items-center text-dark" >
                                                                                <b>{survey.value}</b>
                                                                            </div>
                                                                            
                                                                            {survey.options.map(
                                                                                option =>
                                                                                <div className={`col-md-${this.surveyOptionWidth(survey.options.length)} col-12 form-group d-flex align-items-center`}>
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text bg-primary text-white" id="basic-addon1"><i className="bx bx-user"></i>&nbsp;{this.surveyAnswersPercent(option.answers.length, survey.answers.length)} %</span>
                                                                                    </div>
                                                                                    <div className="form-control" style={{border: '0px'}}>{option.value}</div>
                                                                                </div>

                                                                                
                                                                            )}
                                                                        </div>
                                                                )}

                                                            </div>
                                                        }
                                                        
                                                    </div>
                                                    }


                                                    <br />
                                                    <br />
                                                    
                                                    
                                                    <MonthFilter callback={this.onFilterViewsByMonth} isLoading={bar_chart_loading} />
                                                    
                                                    {bar_chart_loading
                                                    ?
                                                        <LoadingSection />
                                                    :
                                                        <BarChart
                                                            width={this.dynamicWidth("cluster-chart-container")}
                                                            height={this.dynamicWidth("cluster-chart-container") * 0.5}
                                                            data={bar_chart_data}
                                                            margin={{
                                                                top: 0,
                                                                right: 0,
                                                                left: 0,
                                                                bottom: 30,
                                                            }}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis label={{ value: `Days (${moment ? moment.format('MMM/YYYY') : moment().format('MMM/YYYY')})`, angle: 0, position: 'bottom' }} dataKey="day">
                                                                
                                                            </XAxis>
                                                            <YAxis label={{ value: 'Views', angle: -90, position: 'insideLeft' }} />
                                                            <Tooltip />
                                                            <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                            <ReferenceLine y={0} stroke="#000" />
                                                            
                                                            <Bar dataKey="views" fill="#39da8a" />
                                                        </BarChart>
                                                    }

                                                </div>
                                            
                                            </div>        
                                        </div>

                                    </div>
                                }
                            </section>
                        
                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardAdvertDetailsReducer: state.dashboardAdvertDetailsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details));
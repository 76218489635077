import React, { Component } from 'react';
import moment from 'moment';


class MonthFilter extends Component {

    constructor(props){
        super(props);
        this.state = {
            month: moment()
        };
    }
    
    _decreaseMonth = () => {
        this.setState(
            prevState => ({ month: prevState.month.subtract(1, 'month') })
            //, this._filterByMonth
        )
    }

    _increaseMonth = () => {
        this.setState(
            prevState => ({ month: prevState.month.add(1, 'month') })
            //, this._filterByMonth
        )
    }

    _filterByMonth = () => {
        const month = this.state.month.clone();
        if(this.props.callback){
            this.props.callback(month);
        }
    }


    render(){
        return(
            <div className="text-right">
                <button className="btn btn-icon btn-sm btn-outline-success mr-1" onClick={this._decreaseMonth}>
                    <i className="bx bx-chevrons-left"></i>
                </button>

                <span>{this.state.month.format('MMM YYYY')}</span>

                {this.state.month.clone().add(1, 'hour') > moment() 
                ?
                <></>
                :
                
                    <button className="btn btn-icon btn-sm btn-outline-success ml-1" onClick={this._increaseMonth}>
                        <i className="bx bx-chevrons-right"></i>
                    </button>
                }

                <button className="btn btn-sm btn-success ml-1" disabled={this.props.isLoading} onClick={this._filterByMonth}>
                    {this.props.isLoading
                        ?
                            <><i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> Filtering</>
                        :
                        <>Filter</>
                    }
                </button>
            </div>
        );
    }
}

export default MonthFilter;
import axios from 'axios';

import {
    BEFORE_FETCH_LEADER_BOARD,
    FETCH_LEADER_BOARD,
    NETWORK_ERROR_FETCHING_LEADER_BOARD
} from '../types/leader-board.types';

import {
    endpoint
} from 'config';


export const beforeFetchLeaderBoard = () => ({
    type: BEFORE_FETCH_LEADER_BOARD
});


export const fetchLeaderBoard = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}leader-board`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            dispatch({ type: FETCH_LEADER_BOARD, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_LEADER_BOARD, payload: e.response });
        }
    }
}
import {
    BEFORE_CREATE_ADVERT,
    CREATE_ADVERT,
    NETWORK_ERROR_CREATING_ADVERT,

    BEFORE_FETCH_INITIALIZATION_DATA,
    FETCH_INITIALIZATION_DATA,
    NETWORK_ERROR_FETCHING_INITIALIZATION_DATA
} from '../types/create.types';

let initialState = {
    initialization_loading: false,
    initialization_messages: [],
    initialization_status_code: null,
    initialization_data: null,

    loading: false,
    messages: [],
    status_code: null,
    advert: null
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_INITIALIZATION_DATA: {
            return Object.assign({}, state, {
                initialization_loading: true
            });
        }
        case FETCH_INITIALIZATION_DATA: {
            return Object.assign({}, state, {
                initialization_loading: false,
                initialization_status_code: action.payload.status,
                initialization_messages: action.payload.data.message,
                initialization_data: action.payload.data.result.initialization_data
            });
        }
        case NETWORK_ERROR_FETCHING_INITIALIZATION_DATA: {
            return Object.assign({}, state, {
                initialization_loading: false,
                initialization_status_code: action.payload.status,
                initialization_messages: action.payload.data.message
            });
        }
        case BEFORE_CREATE_ADVERT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case CREATE_ADVERT: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                advert: action.payload.data.result.advert
            });
        }
        case NETWORK_ERROR_CREATING_ADVERT: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }
        default: 
            return state;
    }
}
import React, { Component } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Pagination } from 'react-laravel-paginex';
import { Helmet } from "react-helmet";

import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import Blank from 'components/commonCards/Blank';
import Layout from '../../../../layout/Layout';
import * as Actions from './actions/ads-history.actions';
import { getLocalToken } from 'helpers/authentication/token';
import { getParameter, setParameters } from 'helpers/url/url-parameters';

class AdsHistory extends Component {

    constructor(props){
        super(props);
        this.state = {
            search: getParameter('search', ''),
            status: getParameter('status', 0)
        }
    }

    async componentDidMount(){
        const { beforeFetchAdvertsHistory, fetchAdvertsHistory } = this.props.actions;

        await beforeFetchAdvertsHistory();
        fetchAdvertsHistory(getLocalToken(), {
            search: getParameter('search', null),
            status: getParameter('status', 0),
            page: getParameter('page', 1),
        });
    }


    /**
     * This is the callback that is triggered whenever 
     * the user clicks on a pagination option.
     * It receives the page data object of the event.
     */
    paginateAdverts = (data) => {
        setParameters([
            { name: 'page', value: data.page },
        ]);
    }


    /**
     * This method handles updating the local state
     * whenever the fields on the filter form are updated.
     */
    handleChange = (e) => {
        e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
    }


    /**
     * This function is triggered whenever the user
     * clicks the filter button on the filter form.
     * It will sync all the filter values to the current 
     * url, and cause a redirect with the updated parameters.
     * This component will be recreated and componentDidMount
     * will make a fresh fetch api call with these params.
     */
    filterAdverts = (event) => {
        event.preventDefault();
        const { search, status } = this.state;
        setParameters([
            { name: 'search', value: search },
            { name: 'status', value: status },
        ]);
    }


    /**
     * Use this method to dynamically create
     * select form options on a set of objects. E.g
     * [{id: 1, title: 'foo'}, {id: 2, title: 'bar'}]
     */
    createOptions = (dataArray) => {
        return dataArray.map(
            opt => <option value={opt.id}>{opt.title}</option>
        );
    }


    /** 
     * A very easy and convenient way to set and 
     * retrieve the reducer responsible for rendering 
     * this page under one generic function name.
     */
    getReducer = () => {
        return this.props.dashboardAdvertsHistoryReducer;
    }


    render(){

        const { adverts, loading, status_code, pagination, options } = this.getReducer();
        const { search, status } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Ads History | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-md-9 col-sm-12  mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                    <h5 className="content-header-title float-left pr-1 mb-0">Adverts History</h5>
                                    <div className="breadcrumb-wrapper d-none d-sm-block">
                                        <ol className="breadcrumb p-0 mb-0 pl-1">
                                            <li className="breadcrumb-item"><i className="bx bx-home-alt"></i>
                                            </li>
                                            <li className="breadcrumb-item">Ad Centre
                                            </li>
                                            <li className="breadcrumb-item active">History
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-12  mb-2 text-right">
                                <a className="btn btn-success" href="/ads/create">Create Ad</a>
                            </div>
                        </div>

                        <div className="content-body">

                            {loading
                            ?
                                <LoadingSection />

                            :
                                <section id="bg-variants">

                                    <div className="users-list-filter px-1">
                                        <form onSubmit={this.filterAdverts}>
                                            <div className="row border rounded py-2 mb-2">
                                                
                                                <div className="col-12 col-sm-6 col-lg-6">
                                                    <label for="users-list-verified">Search</label>
                                                    <input className="form-control" placeholder="Search Advert Keywords" name="search" value={search} onChange={(e) => this.handleChange(e)} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label for="users-list-status">Status</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" id="users-list-status" name="status" value={status} onChange={(e) => this.handleChange(e)}>
                                                            {this.createOptions([{id: 0, title: 'All'}])}
                                                            {options && options.status && this.createOptions(options.status)}
                                                        </select>
                                                    </fieldset>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
                                                    <button type="submit" className="btn btn-outline-danger btn-block glow users-list-clear mb-0">Filter</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                  

                                    <div className="row">

                                        {(!loading && adverts.length == 0 && status_code == 200) &&
                                            <div className="col-md-12">
                                                <Blank>
                                                    <i>
                                                        <i className="bx bxs-info-circle"></i> No available data.
                                                    </i>
                                                </Blank>
                                            </div>
                                        }


                                        {!loading && adverts.map( 
                                            advert =>  
                                                <div className="col-md-12 mb-1 mt-0">
                                                    <div className="card p-1 mb-0 mt-0">
                                                        <div className="row">
                                                            <div className="col-4 col-sm-4 col-md-1 mb-0">
                                                                <a href={`/ads/history/${advert.pid}`}>
                                                                    <img className="mr-1" src={advert.mediable.full_uri} style={{width:'100%'}} alt="card" />
                                                                </a>
                                                            </div>
                                                            <div className="col-8 col-sm-8 col-md-4">
                                                                <p className="line-ellipsis pt-0 pb-1 mb-0 mt-0"><b>{advert.caption}</b></p>
                                                                <p className="line-ellipsis mb-0 mt-0">{advert.description}</p>
                                                                <small>{advert.date_created}</small>
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-2 mt-1">
                                                                NGN {advert.total_charge_amount ? (advert.total_charge_amount).toLocaleString() : 0}
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-2 mt-1">
                                                                <i className="bx bx-user font-size-large align-middle mr-50"></i> {advert && advert.promotions_count && advert.promotions_count} Promoters
                                                            </div>
                                                            
                                                            <div className="col-12 col-sm-12 col-md-3 text-right">
                                                                <div className="pt-1 pb-1">
                                                                    <div className="progress progress-bar-success">
                                                                        <div className="progress-bar" role="progressbar" aria-valuenow={`${advert.progress}`} aria-valuemin={`${advert.progress}`} aria-valuemax="100" style={{width: `${advert.progress}%`}}>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                        )}

                                    </div>




                                    <div className="row">
                                        <div className="col-sm-12">
                                            <nav aria-label="Page navigation" className="pagination pagination-danger pagination-borderless justify-content-center mt-2" >
                                                {(pagination && status_code === 200 && pagination.last_page >= pagination.current_page && pagination.last_page !== 1) &&    
                                                    <Pagination 
                                                        buttonIcons={true} 
                                                        nextButtonIcon="bx bx-chevron-right" 
                                                        prevButtonIcon="bx bx-chevron-left"
                                                        changePage={this.paginateAdverts} 
                                                        data={pagination} 
                                                    />
                                                }

                                                </nav>
                                        </div>
                                    </div>
                                </section>
                            }

                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardAdvertsHistoryReducer: state.dashboardAdvertsHistoryReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdsHistory));
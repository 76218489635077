import React, { Component } from "react";
import DateDiff from "helpers/date/dateDifference";
import moment from "moment";

class TaskCard extends Component{
    constructor(props){
        super(props);
        this.myRef = React.createRef();
    }

    async claimTask(id){
        let currentRef = this.myRef.current;
        currentRef.innerHTML = "Claiming..."
        currentRef.setAttribute("disabled", true)
        const response = await this.props.actions.claimTask(id);
        if(response.status === "success"){
            this.props.actions.fetchTask();
        }
        currentRef.innerHTML = "Claim"
        currentRef.removeAttribute("disabled")
    }

    async acceptTask(id){
        let currentRef = this.myRef.current;
        currentRef.innerHTML = "Joining..."
        currentRef.setAttribute("disabled", true)
        const response = await this.props.actions.acceptTask(id);
        if(response.status === "success"){
            this.props.actions.fetchTask();
        }
        currentRef.innerHTML = "Enter"
        currentRef.removeAttribute("disabled")
    }

    render(){
        const { task, progress } = this.props.data;
        const { reducers } = this.props;
        let endDate = task.end;
        let date = DateDiff.inHours(new Date(endDate.replace(" ", "T")), new Date())
        
        console.log("props", this.props);

        let taskActionMessage = "";
        switch(this.props.status){
            case "inactive":
                taskActionMessage = "Task pending";
                break;
            case "active":
                taskActionMessage = "Task in progress";
                break;
            case "claimed":
                taskActionMessage = "Task completed"; 
                break;
            default:
                taskActionMessage = "";     
        }
        return(
            <div className="card w-100 border rounded-5 shadow" style={{boxShadow: "none"}}>
                <div className="p-1">
                    <div className="inline-block w-100">
                        <div className="b float-left inline w-75" style={{marginBottom: "10px"}}>
                            <p style={{marginBottom: "5px"}}>{task.description}</p> 
                            <div style={{marginTop: "20px"}}>
                                {task.objectives.map((value, index)=><span key={`objective${index}`} className={`badge badge-light-success badge-pill ${index > 0 ? "ml-50" : ""}`}> {value.objective_id === 2 ? "Referrals" : "Clicks"}: {" "} 
                                    <b>
                                    {this.props.status !== 'new' ? value.objective_id === 2 ? progress.referrals + " / " : progress.clicks + " / " : ""} {value.goal}
                                    </b> 
                                </span>)}
                            </div>
                        </div>
                        <span className={`background-green float-right badge ${this.props.status === 'active' ? 'badge-danger' : this.props.status === 'completed' ? 'badge-warning' : this.props.status === 'claimed' ? 'badge-success' : "badge-light"}`}> {this.props.status}</span>
                    </div>
                    <div className="inline-block w-100" style={{clear: "both"}}>
                        <span className="badge badge-light-success badge-pill"> Reward: <b>{task.points} Points</b></span>
                    </div>
                     
                    <div className="inline-block w-100" style={{clear: "both", marginTop: "20px"}}>
                        {this.props.status === 'new' ?
                            <button className="btn btn-success btn-sm mt-1 float-left inline px-3" style={{fontSize: "15px"}} ref={this.myRef} onClick={()=>this.acceptTask(task.pid)}><i className="bx bx-log-in"></i> Join Task</button>
                        : this.props.status === 'completed' ?
                            <button className="btn btn-red btn-md mt-1 float-left inline px-2" ref={this.myRef} onClick={()=>this.claimTask(task.pid)}> <i className="bx bx-gift"></i> Claim Reward</button>
                        : <span className="float-left mt-1">{taskActionMessage}</span>}
                        <span className="float-right inline mt-1"> {date <= 0  ? moment(task.end).format("LL")  : `Expires in ${date}hrs`} </span>
                    </div>
                    
                </div>
            </div>
        )
    }

}

export default TaskCard;
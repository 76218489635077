import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './style/modal.css'
import {
    LoadingSection
} from 'components/loaders/PageSpinners';

var embed = require("embed-video");

const Modal = (props) =>{
    
    if(!props.show) {
        return null;
    }

    const showModal = props.show ? 'show' : '';

    const display = props.show ? 'block' : 'none';

    const modelPlatformData = props.modelPlatformData ? props.modelPlatformData : {};

    const messages = props.messages ? props.messages : [];

    return(
        <div className='modalBackground'>

            <div className='modalContent' style={{position: 'relative', margin: 'auto', top: 0, left: 0, marginTop: '10%' }}>

            
                <React.Fragment>

                    <div style={{display}} className={`modal fade text-left ${showModal}`} id="default" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
                      aria-hidden="true">
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">

                          <div className="modal-header">
                            <h3 className="modal-title" id="myModalLabel1">How To Promote Twitter Trends</h3>
                            <button onClick={props.closeModal} type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                              <i className="bx bx-x"></i>
                            </button>
                          </div>

                          <div className="modal-body">

                            
                                <div className="">
                                <iframe src="https://s3.us-east-2.amazonaws.com/cluster.live/how-to-videos/how-to-promote-twitter-trends.MP4" frameborder="0" allowfullscreen style={{width: '100%', height: '400px', backgroundColor: '#000'}}></iframe>  
                                </div>
                            
                            
                          </div>  
                        </div>
                      </div>
                    </div>
                   
                </React.Fragment>
            
            </div>
        </div>
       
    )
}

export default Modal
import React from 'react';

const platforms = (typeId) => {

    switch(typeId){
        case 3: {
            return {
                id: 3,
                name: "Instagram",
                icon: "bx bxl-instagram",
                color_class: 'danger'
            }
            break;
        }
        case 2:
        default: {
            return {
                id: 2,
                name: "Twitter",
                icon: "bx bxl-twitter",
                color_class: 'info'
            }
            break;
        }
    }
}

export const SocialForm = (props) => {

    const { typeId, name, value, disabled, addHandle, handleChange, formTypeData } = props;

    const info = platforms(typeId);

    return (
        <div className="row mb-2 pb-2" style={{borderBottom: '2px dashed #eee'}}>
            <div className="col-12 col-md-6 mb-1">
                <fieldset>
                <p className={`text-${info.color_class}`}><i className={`bx ${info.icon} font-medium-5 text-${info.color_class}`}></i> {info.name} add and verify handle.</p>    
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="sizing-addon1"><i className={info.icon}></i></span>
                    </div>

                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder={`${info.name} Handle`}
                        aria-label={`${info.name}`} 
                        name={name}
                        onChange={handleChange}  
                        value={value} 
                        disabled={formTypeData.add_loading}
                    />

                    <div className="input-group-append">
                        <button disabled={formTypeData.add_loading} className={`btn btn-${info.color_class}`} type="button" onClick={(e) => addHandle(e, typeId, formTypeData.url_path)}>
                            {formTypeData && formTypeData.add_loading ? <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> : <i className="bx bx-plus"></i>} Add
                       </button>
                    </div>
                </div>
                </fieldset>
            </div>
        </div>
    );
}
import {
    BEFORE_FETCH_BANKS_LIST,
    FETCH_BANKS_LIST,
    NETWORK_ERROR_FETCHING_BANKS_LIST,

    BEFORE_FETCH_USER_BANK_ACCOUNTS,
    FETCH_USER_BANK_ACCOUNTS,
    NETWORK_ERROR_FETCHING_USER_BANK_ACCOUNTS,

    BEFORE_CREATE_BANK_ACCOUNT,
    CREATE_BANK_ACCOUNT,
    NETWORK_ERROR_CREATING_BANK_ACCOUNT,

    BEFORE_MAKE_DEFAULT_BANK_ACCOUNT,
    MAKE_DEFAULT_BANK_ACCOUNT,
    NETWORK_ERROR_MAKING_DEFAULT_BANK_ACCOUNT,

    BEFORE_DELETE_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    NETWORK_ERROR_DELETING_BANK_ACCOUNT,
} from '../types/bank-accounts.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,
    banks: [],

    loading_create: false,
    messages_create: [],
    status_code_create: null,

    loading_bank_accounts: true,
    messages_bank_accounts: [],
    status_code_bank_accounts: null,
    bank_accounts: [],

    loading_bank_account_default: false,
    messages_bank_account_default: [],
    status_code_bank_account_default: null,

    messages_bank_account_delete: [],
    status_code_bank_account_delete: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_BANKS_LIST: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_BANKS_LIST: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                banks: action.payload.data.result.banks,
            });
        }
        case NETWORK_ERROR_FETCHING_BANKS_LIST: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }


        case BEFORE_CREATE_BANK_ACCOUNT: {
            return Object.assign({}, state, {
                loading_create: true
            });
        }
        case CREATE_BANK_ACCOUNT: {
            return Object.assign({}, state, {
                loading_create: false,
                status_code_create: action.payload.status,
                messages_create: action.payload.data.message
            });
        }
        case NETWORK_ERROR_CREATING_BANK_ACCOUNT: {
            return Object.assign({}, state, {
                loading_create: false,
                status_code_create: action.payload.status,
                messages_create: action.payload.data.message
            });
        }


        case BEFORE_FETCH_USER_BANK_ACCOUNTS: {
            return Object.assign({}, state, {
                loading_bank_accounts: true
            });
        }
        case FETCH_USER_BANK_ACCOUNTS: {
            return Object.assign({}, state, {
                loading_bank_accounts: false,
                status_code_bank_accounts: action.payload.status,
                messages_bank_accounts: action.payload.data.message,
                bank_accounts: action.payload.data.result.bank_accounts,
            });
        }
        case NETWORK_ERROR_FETCHING_USER_BANK_ACCOUNTS: {
            return Object.assign({}, state, {
                loading_bank_accounts: false,
                status_code_bank_accounts: action.payload.status,
                messages_bank_accounts: action.payload.data.message,
            });
        }


        case BEFORE_MAKE_DEFAULT_BANK_ACCOUNT: {
            const index = state.bank_accounts.findIndex(
                bank_account => bank_account.id === action.payload.account_id);  
            return Object.assign({}, state, {
                bank_accounts: [
                    ...state.bank_accounts.slice(0, index),
                    {
                        ...state.bank_accounts[index],
                        loading_as_default: true        
                    },
                    ...state.bank_accounts.slice(index + 1)
                ]
            }); 
        }
        case MAKE_DEFAULT_BANK_ACCOUNT: {
            return Object.assign({}, state, {
                status_code_bank_account_default: action.payload.status,
                messages_bank_account_default: action.payload.data.message,
                bank_accounts: action.payload.data.result.bank_accounts
            });
        }
        case NETWORK_ERROR_MAKING_DEFAULT_BANK_ACCOUNT: {
            return Object.assign({}, state, {
                status_code_bank_account_default: action.payload.status,
                messages_bank_account_default: action.payload.data.message,
            });
        }


        case BEFORE_DELETE_BANK_ACCOUNT: {
            const index = state.bank_accounts.findIndex(
                bank_account => bank_account.id === action.payload.account_id);  
            return Object.assign({}, state, {
                bank_accounts: [
                    ...state.bank_accounts.slice(0, index),
                    {
                        ...state.bank_accounts[index],
                        loading: true        
                    },
                    ...state.bank_accounts.slice(index + 1)
                ]
            }); 
        }
        case DELETE_BANK_ACCOUNT: {
            const index = state.bank_accounts.findIndex(
                bank_account => bank_account.id === action.params.account_id); 
            return Object.assign({}, state, {
                status_code_bank_account_delete: action.payload.status,
                messages_bank_account_delete: action.payload.data.message,
                bank_accounts: [
                    ...state.bank_accounts.slice(0, index),
                    ...state.bank_accounts.slice(index + 1)
                ]
            });     
        }
        case NETWORK_ERROR_DELETING_BANK_ACCOUNT: {
            return Object.assign({}, state, {
                status_code_bank_account_delete: action.payload.status,
                messages_bank_account_delete: action.payload.data.message,
            });
        }
        default: 
            return state;
    }
}
import axios from 'axios';
import {
    BEFORE_FETCH_DASHBOARD_LAYOUT_DATA,
    FETCH_DASHBOARD_LAYOUT_DATA,
    NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA
} from '../types/layout.types';

import {
    endpoint
} from 'config';


export const beforeFetchLayoutData = () => ({
    type: BEFORE_FETCH_DASHBOARD_LAYOUT_DATA
});


export const fetchLayoutData = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}user/layout`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });

            dispatch({ type: FETCH_DASHBOARD_LAYOUT_DATA, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA, payload: e.response });
        }
    }
}
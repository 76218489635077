import {
    BEFORE_CREATE_ONBOARDING_DASHBOARD_USERNAME,
    CREATE_ONBOARDING_DASHBOARD_USERNAME,
    NETWORK_ERROR_CREATING_ONBOARDING_DASHBOARD_USERNAME,

    BEFORE_RESEND_EMAIL_VERIFICATION,
    RESEND_EMAIL_VERIFICATION,
    NETWORK_ERROR_RESENDING_EMAIL_VERIFICATION
} from '../types/onboarding.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,
    verification_loading: false,
    verification_status_code: null, 
    verification_messages: []
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_CREATE_ONBOARDING_DASHBOARD_USERNAME: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case CREATE_ONBOARDING_DASHBOARD_USERNAME: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_CREATING_ONBOARDING_DASHBOARD_USERNAME: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }
        case BEFORE_RESEND_EMAIL_VERIFICATION: {
            return Object.assign({}, state, {
                verification_loading: true
            });
        }
        case RESEND_EMAIL_VERIFICATION: {
            return Object.assign({}, state, {
                verification_loading: false,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_RESENDING_EMAIL_VERIFICATION: {
            return Object.assign({}, state, {
                verification_loading: false,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }
}
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './style/modal.css'
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import { icon } from '../components/Icons';


const urlPath = (typeId) => {

  switch(typeId){
      case 2: {
          return "twitter";
          break;
      }
      case 3: {
          return "instagram";
          break;
      }
      default: {
          return "twitter";
          break;
      }
  }
}


const Modal = (props) =>{
    
    if(!props.show) {
        return null;
    }

    const showModal = props.show ? 'show' : '';

    const display = props.show ? 'block' : 'none';

    const modelPlatformData = props.modelPlatformData ? props.modelPlatformData : {};

    const messages = props.messages ? props.messages : [];

    return(
        <div className='modalBackground'>

            <div className='modalContent' style={{position: 'relative', margin: 'auto', top: 0, left: 0, marginTop: '10%' }}>

            
                <React.Fragment>

                    <div style={{display}} className={`modal fade text-left ${showModal}`} id="default" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
                      aria-hidden="true">
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">

                          <div className="modal-header">
                            <h3 className="modal-title" id="myModalLabel1">Verify {modelPlatformData.type.name} Account</h3>
                            <button onClick={props.closeModal} type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                              <i className="bx bx-x"></i>
                            </button>
                          </div>

                          <div className="modal-body">

                            
                                <div className="">

                                    <div className="mt-1 mb-1">
                                      1. Copy the text below and make a {modelPlatformData.type.id == 2 ? 'tweet' : 'post' } using the {modelPlatformData.type.name} account with handle &nbsp;<span className="badge badge-pill badge-light-info">{modelPlatformData.platform.handle}</span>
                                      <div className="alert bg-rgba-warning alert-dismissible mb-2 mt-1" role="alert">
                                          
                                          <CopyToClipboard text={modelPlatformData.platform.verification_string} onCopy={props.onCopyToClipboard}>
                                            <button type="button" className="close">
                                              <span aria-hidden="true"><i className="bx bx-copy"></i></span>
                                            </button>
                                          </CopyToClipboard>
                                          <div className="d-flex align-items-center">
                                            <span>
                                                <span>{modelPlatformData.platform.verification_string}</span>
                                            </span>
                                          </div>
                                      </div>
                                    </div>
                                    
                                    <div className="mt-1 mb-1">
                                      2. After tweeting, get the link of the tweet e.g  <b>https://twitter.com/username/status/1234567890</b>
                                    </div>

                                    <div className="mt-1 mb-1">
                                      3. Insert the tweet link you obtain into the text box below.
                                      <input 
                                        className="form-control mt-1" 
                                        onChange={props.handleChange} 
                                        name="handle_verification_url"
                                        value={props.handleVerificationUrl} 
                                        placeholder="Insert tweet url here" 
                                        disabled={props.modalLoading}
                                      />
                                    </div>

                                    <div className="mt-1 mb-2">
                                      4. Click the "Finish Verification" button below to complete the verification.
                                    </div>

                                    {messages.length 
                                    ?
                                      <div className="text-center text-danger mt-2">
                                          <ul>
                                              {
                                                messages.map(
                                                  message => 
                                                  <li>{message}</li>
                                                )
                                              }
                                          </ul>
                                      </div>
                                    :
                                      null
                                    }

                                    <div className="text-center mt-2">
                                        <button className="btn btn-success" disabled={props.modalLoading} onClick={(event) => props.finishVerification(event, modelPlatformData.id, urlPath(modelPlatformData.type.id))}> 
                                          {props.modalLoading  ? <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> : <i className={`${(icon(modelPlatformData.type.id)).icon}`}></i>} Finish Verification
                                        </button>
                                    </div>
                                </div>
                            
                            
                          </div>  
                        </div>
                      </div>
                    </div>
                   
                </React.Fragment>
            
            </div>
        </div>
       
    )
}

export default Modal
function menu(){
    document.getElementById('body-tag').setAttribute( "class", "d-flex flex-column h-100");
    document.getElementById('root').setAttribute( "class", "flex-shrink-0");


    const dynamicStyles = [
        "/themefive/app-assets/css/home/styles.css", 
        "/themefive/app-assets/css/home/footer.css"
    ];

    dynamicStyles.forEach(function(styleHref){
        const style = document.createElement("link");
        style.href = styleHref;
        style.rel = "stylesheet";
        style.type = "text/css";
        document.head.appendChild(style);
    });


    const array = [
            'apex-charts', 'swiper-min', 'bootstrap-extended', 'colors', 'components', 
        'dark-layout', 'semi-dark-layout', 'vertical-menu', 'dashboard-ecommerce', 'custom-style'
    ];

    array.forEach(function(style){
        const element =  document.getElementById(style);
        if(element && element.parentNode){
            element.parentNode.removeChild(element);
        }
    });
    

    const script = document.createElement("script");
    script.src = "/themefive/app-assets/js/home/home.bootstrap.bundle.min.js";
    script.async = true;
    document.body.appendChild(script);
}

export default menu;
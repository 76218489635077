import {
    FETCH_TASK_LOADING,
    FETCH_TASK,
    ERROR_FETCHING_TASK,
    ACCEPT_TASK_LOADING,
    ACCEPT_TASK,
    ERROR_ACCEPTING_TASK,
    CLAIM_TASK_LOADING,
    CLAIM_TASK,
    ERROR_CLAIMING_TASK,
} from '../types/task.type';

let initialState = {
    fetch_task_loading: false,
    tasks: [],
    fetch_task_messages: [],

    accept_task_loading: false,
    accept_task: null,
    accept_task_messages: [],

    claim_task_loading: false,
    claim_task: null,
    claim_task_messages: [],
    
}

export default function (state = initialState, action){
    switch(action.type){
        case FETCH_TASK_LOADING: {
            return Object.assign({}, state, {
                fetch_task_loading: true
            });
        }
        case FETCH_TASK: {
            return Object.assign({}, state, {
                fetch_task_loading: false,
                tasks: action.payload,
            });
        }
        case ERROR_FETCHING_TASK: {
            return Object.assign({}, state, {
                fetch_task_loading: false,
                fetch_task_message: action.payload.data.message,
            });
        }

        case ACCEPT_TASK_LOADING: {
            return Object.assign({}, state, {
                accept_task_loading: true
            });
        }
        case ACCEPT_TASK: {
            return Object.assign({}, state, {
                accept_task_loading: false,
                task: action.payload.data,
            });
        }
        case ERROR_ACCEPTING_TASK: {
            return Object.assign({}, state, {
                accept_task_loading: false,
                accept_task_messages: action.payload.data.message,
            });
        }

        case CLAIM_TASK_LOADING: {
            return Object.assign({}, state, {
                claim_task_loading: true
            });
        }
        case CLAIM_TASK: {
            return Object.assign({}, state, {
                claim_task_loading: false,
                claim_task: action.payload.data,
            });
        }
        case ERROR_CLAIMING_TASK: {
            return Object.assign({}, state, {
                claim_task_loading: false,
                claim_task_messages: action.payload.data.message,
            });
        }
        
        default: 
            return state;
        }
}
export const BEFORE_FETCH_DASHBOARD_NOTIFICATION_DETAILS = 'BEFORE_FETCH_DASHBOARD_NOTIFICATION_DETAILS';
export const FETCH_DASHBOARD_NOTIFICATION_DETAILS = 'FETCH_DASHBOARD_NOTIFICATION_DETAILS';
export const NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATION_DETAILS = 'NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATION_DETAILS';


/**
 * Note that in the notification details reducer,
 * you will not find these TYPES below.
 * This is because they are already defined in the 
 * Layout reducer and we connect to that automatically.
 */
export const BEFORE_FETCH_DASHBOARD_LAYOUT_DATA = 'BEFORE_FETCH_DASHBOARD_LAYOUT_DATA';
export const FETCH_DASHBOARD_LAYOUT_DATA = 'FETCH_DASHBOARD_LAYOUT_DATA';
export const NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA = 'NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA';

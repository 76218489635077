import axios from 'axios';

import {
    BEFORE_FETCH_SETTINGS_DATA,
    FETCH_SETTINGS_DATA,
    NETWORK_ERROR_FETCHING_SETTINGS_DATA
} from '../types/settings-index.types';


import {
    endpoint
} from 'config';


import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Layout from '../../../layout/Layout';
import Blank from 'components/commonCards/Blank';
import * as Actions from './actions/leader-board.actions';
import { getLocalToken } from 'helpers/authentication/token';

import {
    LoadingSection
} from 'components/loaders/PageSpinners';

var toonavatar = require('cartoon-avatar');

class LeaderBoard extends Component {

    async componentDidMount(){
        const dynamicStyles = [
            "/themefive/app-assets/css/leader-board/leader-board.css"
        ];

        dynamicStyles.forEach(function(styleHref){
            const style = document.createElement("link");
            style.href = styleHref;
            style.rel = "stylesheet";
            style.type = "text/css";
            document.head.appendChild(style);
        });

        const { beforeFetchLeaderBoard, fetchLeaderBoard } = this.props.actions;

        await beforeFetchLeaderBoard();
        fetchLeaderBoard(getLocalToken(), {});
    }


    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardLeaderboardReducer;
    }



    render(){

        const { loading, current_leader_board, previous_leader_board, status_code, leader_board_winners_count } = this.getReducer();

        return(
            <Layout>

                <Helmet>
                    <title>LeaderBoard | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">


                        <div className="content-header row">
                            <div className="content-header-left col-md-9 col-sm-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                    <h5 className="content-header-title float-left pr-1 mb-0">Leaderboard</h5>
                                    <div className="breadcrumb-wrapper d-none d-sm-block">
                                        <ol className="breadcrumb p-0 mb-0 pl-1">
                                        <li className="breadcrumb-item">
                                            <a href="/"><i className="bx bx-home-alt"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active">Leaderboard</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12  mb-2 text-right">
                                <a className="btn btn-success" href="/profile/achievements"><i className="bx bx-layer"></i> &nbsp;Your Points</a>
                            </div>
                        </div>


                        <div className="content-body">

                            {loading
                            ?
                                <LoadingSection />

                            :
                            <section className="">
                            <div className="">
                                
                                {previous_leader_board && previous_leader_board.list && previous_leader_board.list.length > 0 && 
                                    <h3 className="mb-2"><i className="bx bxs-gift"></i> Winners for {previous_leader_board.month} {previous_leader_board.year}</h3>
                                }

                                <div className="row">

                                    {previous_leader_board && previous_leader_board.list && previous_leader_board.list.map( 
                                        winner =>
                                            <div className="col-sm-4">
                                                <div className={`leaderboard-card leaderboard-card${winner.ranking.position === 1 ? '--first' : ''}`}>
                                                    <div className="leaderboard-card__top ">
                                                        <h3 className={`text-center ${winner.ranking.position === 1 ? 'text-white' : 'text-dark'}`}>{winner.months_points ? winner.months_points.toLocaleString() : winner.months_points} Points</h3>
                                                    </div>
                                                    <div className="leaderboard-card__body">
                                                        <div className="text-center">
                                                            <img src={`/themefive/app-assets/images/icon/leader${winner.ranking.position}.png`} className="circle-img mb-2 bg-white" alt="User Img" />
                                                            <h5 className="mb-0">{winner.user && winner.user.first_name}</h5>
                                                            <p className="text-muted mb-0">@{winner.user.username}</p>
                                                            <hr />
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <span><i className="bx bxs-gift"></i> {winner.ranking && winner.ranking.prize && winner.ranking.prize.place}</span>
                                                                <button className="btn btn-outline-success btn-lg">&#8358; {(winner.ranking && winner.ranking.prize && winner.ranking.prize.amount.toLocaleString())}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )}

                                </div>


                                {current_leader_board && current_leader_board.list && current_leader_board.list.length > 0 &&
                                    <>
                                        
                                        <div className="mt-4">
                                            <h3 className="mb-1 "><i className="bx bx-chalkboard"></i> LeaderBoard - {current_leader_board.month} {current_leader_board.year}</h3>
                                            
                                            <span className="badge badge-danger mb-1">{current_leader_board.days_remaining} Days Left</span>
                                            &nbsp;
                                            <span className="badge badge-success mb-1">🔥 Only Top {leader_board_winners_count} Will Win</span>
                                        </div>
                                    </>
                                }


                                {
                                    <div className="row">

                                        {(!loading && current_leader_board && current_leader_board.list && current_leader_board.list.length == 0 && status_code == 200) &&
                                            <div className="col-md-12 mb-0 mt-0">
                                                <div className="alert alert-success mb-1 text-center" >
                                                 
                                                    No leaderboard data available yet for {current_leader_board.month} {current_leader_board.year}.
                                                    
                                                </div>

                                                <div className="d-flex justify-content-center flex-wrap">
                                                    <div className="text-center bg-primary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
                                                        <span className="align-middle"></span>
                                                    </div>
                                                    <div className="text-center bg-secondary colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
                                                        <span className="align-middle"></span>
                                                    </div>
                                                    <div className="text-center bg-success colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50  my-1 shadow">
                                                        <span className="align-middle"></span>
                                                    </div>
                                                    <div className="text-center bg-danger colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50  my-1 shadow">
                                                        <span className="align-middle"></span>
                                                    </div>
                                                    <div className="text-center bg-warning colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50  my-1 shadow">
                                                        <span className="align-middle"></span>
                                                    </div>
                                                    <div className="text-center bg-info colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center my-1 ml-50 shadow">
                                                        <span className="align-middle"></span>
                                                    </div>
                                                </div>
                                            </div>  
                                        }

                                        

                                        {current_leader_board && current_leader_board.list && current_leader_board.list.map( 
                                            leader =>  
                                                
                                                <div className="col-md-12 mb-0 mt-0">
                                                    <div className="card p-1 mb-1 mt-0">
                                                        <div className="row">
                                                            <div className="col-8 col-sm-8 col-md-5 mb-1">
                                                                <div className="d-flex align-items-center">
                                                                    <img src={toonavatar.generate_avatar({id: (leader.ranking && leader.ranking.position) ? leader.ranking.position : 11 })} className="circle-img circle-img--small mr-2" alt="User Img" />
                                                                    <div className="user-info__basic">
                                                                        <h5 className="mb-0">{leader.user.first_name}</h5>
                                                                        <p className="text-muted mb-0">@{leader.user.username}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="col-4 col-sm-4 col-md-3">
                                                                <div className="">
                                                                    <h4 className="mr-1 mb-0">{leader.months_points ? leader.months_points.toLocaleString() : leader.months_points}</h4>
                                                                    <small className="text-success">Points</small>
                                                                </div>
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-1">
                                                                <b className="text-dark" style={{fontSize:25}}>
                                                                <i className="bx bx-hash"></i><span>{leader.ranking && leader.ranking.position}</span>
                                                                </b>
                                                            </div>

                                                            <div className="col-6 col-sm-6 col-md-3 text-right">
                                                                {leader.ranking && leader.ranking.prize &&
                                                                    <button className="btn btn-outline-light btn-lg">&#8358; {leader.ranking.prize.amount && leader.ranking.prize.amount.toLocaleString()}</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                        )}
                                    </div>
                                }
                    
                    
                            </div>
                        </section> 
                            }

                        </div>
                    
                    </div>
                </div> 

            </Layout>
        );
    }

}

const mapStateToProps = (state) => ({ dashboardLeaderboardReducer: state.dashboardLeaderboardReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaderBoard));
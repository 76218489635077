import React from 'react';
import { store } from 'react-notifications-component';
import { 
    FacebookShareButton, FacebookIcon, 
    TwitterShareButton, TwitterIcon, 
    WhatsappShareButton, WhatsappIcon 
} from 'react-share';



const PromotionRowAlt = (props)=>{
    let {data} = props;

    const copyToClipboard = (text) => {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        
        store.addNotification({
            title: 'Copied to clipboard',
            message: " ",
            type: "success",
            insert: "top",
            container: 'top-center',
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 1000,
                showIcon: false,
                onScreen: false
            }
        });
      };
    
    return(


        <div class="col-lg-12">
                  
            <div class="card">
                <div class="card-body">
                
                    <div class="tab-content pl-0">
                        <div class="tab-pane active" id="user-status" aria-labelledby="user-status-tab"
                        role="tabpanel">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <div class="col-sm-12 col-md-2">
                                        
                                            <img src={data.advert && data.advert.images && data.advert.images.length > 0 ? data.advert.images[0].full_uri : ''}
                                            alt="Advert Image" style={{width: '100%'}} />
                                            <span class="avatar-status-online"></span>
                                        
                                        </div>
                                        <div class="col-sm-12 col-md-10">
                                            <p className="mt-1 line-ellipsis"><b>{data.advert.caption}</b></p>
                                            <p className="line-ellipsis">{data.advert.description}</p>

                                            <p>
                                                <small>Joined: {data.date_created}</small>
                                            </p>
                                            
                                            <div className="pt-0 pb-1">
                                                <div className="progress progress-bar-success">
                                                    <div className="progress-bar" role="progressbar" aria-valuenow={`${data.advert.progress}`} aria-valuemin={`${data.advert.progress}}`} aria-valuemax="100" style={{width: `${data.advert.progress}%`}}>
                                                    </div>
                                                </div>
                                            </div>

                                            <p>
                                                <small className="text-success"><i>Funds you earned from promoting this Ad will be available for withdrawal when the progress bar above is full.</i></small>
                                            </p>

                                           

                                            <div class="card-footer p-0">
                                                <FacebookShareButton
                                                    url={data.social_link}
                                                    quote={data.advert.caption}
                                                    hashtag={data.advert.hashtag ? `#${data.advert.hashtag}` : ""}
                                                    description={data.advert.description}
                                                    className="cursor-pointer mr-1 pt-50"
                                                    
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>

                                                <TwitterShareButton
                                                    title={data.advert.caption}
                                                    url={data.social_link}
                                                    hashtags={[data.advert.hashtag ? `${data.advert.hashtag}` : ""]}
                                                    className="cursor-pointer mr-1 pt-50"
                                                >
                                                    <TwitterIcon size={32} round />
                                                </TwitterShareButton>

                                                <WhatsappShareButton
                                                    title={data.advert.caption}
                                                    url={data.social_link}
                                                    className="cursor-pointer mr-1 pt-50"
                                                >
                                                    <WhatsappIcon size={32} round />
                                                </WhatsappShareButton>

                                                <span class=" float-sm-right d-flex flex-sm-row flex-column justify-content-end">
                                                    <button onClick={(e) => e.preventDefault()} class="btn btn-light-success mr-0 my-1 my-sm-0 mr-sm-1">Earned &#8358; {data.total_earning}</button>
                                                </span>

                                            </div>
                                            
                                        </div>
                                    </div>

                                    

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                                       
    )
}


export default PromotionRowAlt;
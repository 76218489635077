import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Pagination } from 'react-laravel-paginex';
import { Helmet } from "react-helmet";

import * as Actions from './actions/campaigns.actions';
import CampaignCard from './components/campaignCard'
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import Layout from '../../../../layout/Layout';
import Blank from 'components/commonCards/Blank';
import Modal from './components/modal';
import { changePage } from 'helpers/url/url-parameters';
import { getParameter, setParameters } from 'helpers/url/url-parameters';
import { customNotification } from 'components/notifications/Notifications';




class Campaigns extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalIsOpen: false,
            promoteId: '',
            surveys: [],
            modelAdvert: null
        }

        this.promoteAdvert = this.promoteAdvert.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal(id, surveys = [], advert) {
        this.setState({modalIsOpen: true, promoteId: id, surveys, modelAdvert: advert})   
    }
    
    closeModal(){
        this.setState({modalIsOpen: false})
    }

    componentDidMount(){
        let urlParams =  new URLSearchParams(window.location.search);
        let page =  urlParams.get('page') || 1;

        this.props.actions.fetchCampaign(page)
    }

    promoteAdvert(event, surveys){
        
        event.preventDefault();

        let ans = [];

        surveys.forEach(function(survey){
            if(survey.survey_type_id == 1){
                if(document.querySelector(`input[name="survey_${survey.id}"]:checked`)){
                    var answer = document.querySelector(`input[name="survey_${survey.id}"]:checked`).value;
                    ans.push({ id: survey.id, answers: [answer] });
                }
            }else{
                if(document.querySelectorAll(`input[type=checkbox][name=survey_${survey.id}]:checked`).length > 0){
                    var answer = Array.from(document.querySelectorAll(`input[type=checkbox][name=survey_${survey.id}]:checked`)).map(e => e.value);
                    ans.push({ id: survey.id, answers: answer });
                }
            } 
        });

        if(ans.length !== surveys.length){
            customNotification(["You need to fill out the survey form completely"], "Action Required", "danger");
        }else{
            this.props.actions.promoteAdvert({advert_id: this.state.promoteId, survey_answers: JSON.stringify(ans) }, this.closeModal)
        }
    }

    render(){

        let actions = this.props.actions;
        let {campaigns, loading, promoteLoading} = this.props.reducers

        return(
            <Layout>

                <Helmet>
                    <title>Jobs | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Jobs</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item"><span >Promoter</span>
                                    </li>
                                    <li className="breadcrumb-item active">Available Jobs
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">

                            {loading ?
                                <LoadingSection />
                            :

                                <section id="content-types">
                                    <div className="row">
                                    
                                    {campaigns.data ? 
                                        campaigns.data.length > 0 ?
                                            campaigns.data.map((data)=>{
                                                return <CampaignCard
                                                            key={data.id} 
                                                            {...data} 
                                                            actions={actions} 
                                                            openModal = {this.openModal}
                                                            advert={data}  
                                                        />
                                            })
                                            : 
                                            (getParameter('page', 0) > campaigns.last_page 
                                            ?
                                                <div className="col-sm-12">
                                                    <Blank>
                                                        <div className="row">
                                                            <div className="col-md-12 text-center">
                                                                <h3>Error fetching required page.</h3>
                                                                <small>We believe your intent is to go to the Available Jobs page, please click the link below to go there.</small>
                                                            </div>
                                                            <div className="col-md-12 text-center mt-2">
                                                                <a className="btn btn-success" href="/promoter/campaigns">Available Jobs</a>
                                                            </div>
                                                        </div>
                                                    </Blank>
                                                </div>
                                            :
                                                <div className="col-sm-12">
                                                    <Blank>
                                                        <span className="text-dark">
                                                            There are no available jobs at the moment, please check back later.
                                                        </span>
                                                    </Blank>
                                                </div>
                                            )
                                        : 
                                        ''
                                    }
                    

                                    {
                                        campaigns.data ?
                                            (campaigns.last_page >= campaigns.current_page && campaigns.last_page !== 1) &&    
                                                <div className="col-sm-12">
                                                    <nav aria-label="Page navigation" className="pagination pagination-danger pagination-borderless justify-content-center mt-2" >

                                                        <Pagination 
                                                            buttonIcons={true} 
                                                            nextButtonIcon="bx bx-chevron-right" 
                                                            prevButtonIcon="bx bx-chevron-left"
                                                            changePage={changePage} 
                                                            data={campaigns} 
                                                        />
                                                    </nav>
                                                </div>
                                        : ''
                                    } 
                                   
                                        
                                    
                                    </div>
                                </section>
                            }
                        </div>

                    </div>
                </div> 

                <Modal 
                    show = {this.state.modalIsOpen} 
                    closeModal = {()=>this.closeModal()} 
                    promoteAdvert = {this.promoteAdvert} 
                    modalLoading = {promoteLoading} 
                    surveys={this.state.surveys}
                    advert={this.state.modelAdvert}
                />

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ reducers: state.dashboardCampaignReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campaigns));

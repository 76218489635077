import {
    BEFORE_CLAIM_WEEKLY_PROMO,
    CLAIM_WEEKLY_PROMO,
    NETWORK_ERROR_CLAIMING_WEEKLY_PROMO,

    BEFORE_FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS,
    FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS,
    NETWORK_ERROR_FETCHING_PREVIOUS_WEEKLY_PROMO_WINNERS
} from '../types/weekly-promo.types';

let initialState = {
    loading: false,
    previous_winners: [],
    status_code: null,
    messages: [],


    loading_claim: false,
    status_code_claim: null,
    messages_claim: []
}

export default function(state = initialState, action){

    switch(action.type){
        case BEFORE_CLAIM_WEEKLY_PROMO: {
            return Object.assign({}, state, {
                loading_claim: true
            });
        }
        case CLAIM_WEEKLY_PROMO: {
            return Object.assign({}, state, {
                loading_claim: false,
                status_code_claim: action.payload.status,
                messages_claim: action.payload.data.message
            });
        }
        case NETWORK_ERROR_CLAIMING_WEEKLY_PROMO: {
            return Object.assign({}, state, {
                loading_claim: false,
                status_code_claim: action.payload.status,
                messages_claim: action.payload.data.message
            });
        }

        case BEFORE_FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                previous_winners: action.payload.data.result.previous_winners,
                messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_FETCHING_PREVIOUS_WEEKLY_PROMO_WINNERS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }

}
import {
    BEFORE_FETCH_CAMPAIGNS,
    FETCH_CAMPAIGNS,
    ERROR_FETCHING_CAMPAIGNS,
    BEFORE_PROMOTE_ACTION,
    RESPONSE_PROMOTE_ACTION,
    ERROR_PROMOTE_ACTION
} from '../types/campaigns.types';


import { getRequest, postRequest } from 'helpers/httpRequest/request';
import { networkNotification } from 'components/notifications/Notifications';


export const beforeFetchCampaign = () => (
    { type:  BEFORE_FETCH_CAMPAIGNS }
)

export const fetchCampaign = (page) => {
    return async (dispatch) => {
        try{
            dispatch({type: BEFORE_FETCH_CAMPAIGNS});
            const response = await getRequest('v1', `campaigns?page=${page}`);

            dispatch({ type: FETCH_CAMPAIGNS, payload: response });

        }catch(e){
            dispatch({type:  ERROR_FETCHING_CAMPAIGNS, payload: e.response });
        }
    }
}

export const promoteAdvert = (data, closeModal) =>{
    return async (dispatch) =>{
        try{
            
            dispatch({type: BEFORE_PROMOTE_ACTION})

            const response = await postRequest('v1', `campaign/promote/${data.advert_id}`, {survey_answers: data.survey_answers})

            networkNotification(response.message, response.status_code)
            closeModal();
            dispatch({ type: RESPONSE_PROMOTE_ACTION, payload: response.result });

            if(response.status_code == 200){
                window.location = '/promoter/promotions';
            }

        }catch(e){
            let message = [e.message];
            closeModal();
            if(e.response){
                if(e.response.data){
                    if(e.response.data.message)  message = e.response.data.message
                }
            }
            dispatch({ type: ERROR_PROMOTE_ACTION})
        
            networkNotification(message, 400)
        }
    }
}
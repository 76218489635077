import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
    ssoUrl,
    singleSignOnCallBack
} from '../../../../config';


class Referrer extends Component{

    componentDidMount(){
        const username = this.props.match.params.username;
        window.location = `${ssoUrl}/register?referrer=${username}&redirect_url=${singleSignOnCallBack}`;
    }

    render(){
        return(
            <>
            <Helmet>
                <title>Referrer | Cluster Advertisement Network</title>
            </Helmet>
            <div> redirecting... </div>
            </>
        );
    }

}

export default withRouter(Referrer);
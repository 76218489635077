import React, {Component} from "react"

class LevelBlock extends Component{

    icon = (id) => {
        return `/themefive/app-assets/images/icon/award${id}.png`;
    }
    
    render(){
        return(
            <li className={` pl-1 timeline-item ${this.props.icon} active`} style={{background: this.props.level == this.props.currentlevel ? '#eee' : ''}}>
                <div className="timeline-time">
                    <img src={this.icon(this.props.level)} width={50} className="img-fluid" alt="trophy" style={{opacity: this.props.completed ? 1 : 0.2 }} />
                </div>
                <h6 className="timeline-title"><h5>Level {this.props.level} - <span className="text-">{this.props.title}</span></h5></h6>
                <p className="timeline-text"><span className="text-warning"><b>{this.props.points ? this.props.points.toLocaleString(): this.props.points}</b> points required.</span></p> 
                <div className="timeline-content">
                    You earn &nbsp;<span className="text-success"><b>₦ {this.props.amount}</b></span>&nbsp; per click on this level.
                </div>
            </li>
        )
    }
}

export default LevelBlock;
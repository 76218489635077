import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Pagination } from 'react-laravel-paginex';
import marked from 'marked';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";

import * as Actions from './actions/notifications.actions';
import Layout from '../../../../layout/Layout';
import Blank from 'components/commonCards/Blank';
import MonthFilter from 'components/monthFilter/MonthFilter';
import { getLocalToken } from 'helpers/authentication/token';
import { getParameter, setParameters } from 'helpers/url/url-parameters';
import {
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';


class Notifications extends Component {

    constructor(props){
        super(props);

        this.state = {
            moment: moment(),
            status: getParameter('status', 0),
            order: getParameter('order', 1)
        };
    }

    async componentDidMount(){
        //console.log("Auth:: ", this.props.auth_user);

        const { beforeFetchNotifications, fetchNotifications } = this.props.actions;

        await beforeFetchNotifications();
        fetchNotifications(getLocalToken(), {
            page: getParameter('page', 1),
            status: getParameter('status', 0),
            order: getParameter('order', 1)
        });
    }


    /**
     * This is the callback that is triggered whenever 
     * the user clicks on a pagination option.
     * It receives the page data object of the event.
     */
    paginateNotifications = (data) => {
        setParameters([
            { name: 'page', value: data.page },
        ]);
    }


        /**
     * This function is triggered whenever the user
     * clicks the filter button on the filter form.
     * It will sync all the filter values to the current 
     * url, and cause a redirect with the updated parameters.
     * This component will be recreated and componentDidMount
     * will make a fresh fetch api call with these params.
     */
    filterNotifications = (event) => {
        event.preventDefault();
        const { order, status } = this.state;
        setParameters([
            { name: 'order', value: order },
            { name: 'status', value: status }
        ]);
    }


    /**
     * Use this method to dynamically create
     * select form options on a set of objects. E.g
     * [{id: 1, title: 'foo'}, {id: 2, title: 'bar'}]
     */
    createOptions = (dataArray) => {
        return dataArray.map(
            opt => <option key={opt.id} value={opt.id}>{opt.title}</option>
        );
    }


    /**
     * This method handles updating the local state
     * whenever the fields on the filter form are updated.
     */
    handleChange = (e) => {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value});
    }
    

    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardNotificationsReducer;
    }


    render(){

        const { loading, status_code , notifications, pagination } = this.getReducer();

        const { moment, status, order } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Notifications | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Notifications</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item">Profile
                                    </li>
                                    <li className="breadcrumb-item active">Notifications
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>


                        <div className="content-body">


                            {loading
                            ?
                                <LoadingSection />

                            :
                                <section id="bg-variants">
                                    <div className="users-list-filter px-1">
                                        <form onSubmit={this.filterNotifications}>
                                            <div className="row border rounded py-2 mb-2">
                                                <div className="col-12 col-sm-12 col-lg-4">
                                                    <label htmlFor="users-list-verified">Status</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" id="users-list-verified" name="status" value={status} onChange={(e) => this.handleChange(e)}>
                                                            {this.createOptions([
                                                                {id: 0, title: 'All'},
                                                                {id: 1, title: 'Read'},
                                                                {id: 2, title: 'Un Read'},
                                                            ])}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12 col-sm-12 col-lg-4">
                                                    <label htmlFor="users-list-role">Order</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" id="users-list-role" name="order" value={order} onChange={(e) => this.handleChange(e)}>
                                                            {this.createOptions([
                                                                {id: 1, title: 'Newest'},
                                                                {id: 2, title: 'Oldest'},
                                                            ])}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12 col-sm-12 col-lg-4 d-flex align-items-center">
                                                    <button type="submit" className="btn btn-outline-danger btn-block glow users-list-clear mb-0">Filter</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                    <div className="row">

                                        {(!loading && notifications.length == 0 && status_code == 200) &&
                                            <div className="col-md-12 mb-0 mt-0">
                                                <Blank>
                                                    <i>
                                                        No data available.
                                                    </i>
                                                </Blank>
                                            </div>  
                                        }

                                        {notifications.map( 
                                            notification =>  
                                               
                                                    <div className="col-md-12 mb-0 mt-0">
                                                        <div className="card p-1 mb-1 mt-0">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-3 col-md-2 mb-1">
                                                                    {notification.readable_date}
                                                                </div>
                                                                <div className="col-12 col-sm-9 col-md-6">
                                                                    <p>
                                                                        <i className={`bx  mr-1 ${notification.read_at ? 'bx-disc' : 'bxs-disc text-success'}`}></i>
                                                                        <span className="text-dark">{notification.data.subject}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-md-2">
                                                                    {notification.read_at ? <span className="">Read</span> : <span className="text-success">Un-read</span>}
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-md-2 text-right">
                                                                    <a className="btn btn-sm btn-outline-light" href={`/profile/notifications/${notification.id}`}>open</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                        )}
                                    </div>


                                    <div className="row">
                                        <div className="col-sm-12">

                                            <nav aria-label="Page navigation" className="pagination pagination-danger pagination-borderless justify-content-center mt-2" >

                                                {(pagination && status_code === 200 && pagination.last_page >= pagination.current_page && pagination.last_page !== 1) &&    
                                                    <Pagination 
                                                        buttonIcons={true} 
                                                        nextButtonIcon="bx bx-chevron-right" 
                                                        prevButtonIcon="bx bx-chevron-left"
                                                        changePage={this.paginateNotifications} 
                                                        data={pagination} 
                                                    />
                                                }

                                            </nav>

                                        </div>
                                    </div>
                                </section>  
                            }

                        </div>
                    
                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardNotificationsReducer: state.dashboardNotificationsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
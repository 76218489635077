import React from 'react';
import { store } from 'react-notifications-component';
import { 
    TwitterShareButton, TwitterIcon
} from 'react-share';



/**
 * Quickly gather the url before finally posting
 * to the desired function verifyPromotion 
 * @param {*} event 
 * @param {*} promotionId 
 * @param {*} verifyPromotion 
 */
const submitVerification = (event, promotionId, verifyPromotion) => {
    event.preventDefault();
    const url = document.getElementById(`verify_url_feed_${promotionId}`).value;
    verifyPromotion(event, promotionId, url, 'twitter-trend');
}

const TwitterTrend = (props)=>{
    let { data } = props;
    const { verifyPromotion, claimPromotion, costPerTrend, helpVideo } = props;
    
    return(


        <div className="col-lg-12">
                  
            <div className="card">
                <div className="card-body">
                
                    <div className="tab-content pl-0">
                        <div className="tab-pane active" id="user-status" aria-labelledby="user-status-tab"
                        role="tabpanel">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group row">
                                        <div className="col-12 text-right">
                                            <span className="badge badge-info">Twitter #Trend</span>
                                        </div>
                                        <div className="col-sm-12 col-md-2">
                                        
                                            <img src={data.advert && data.advert.images && data.advert.images.length > 0 ? data.advert.images[0].full_uri : ''}
                                            alt="Advert Image" style={{width: '100%'}} />
                                            <span className="avatar-status-online"></span>
                                        
                                        </div>
                                        <div className="col-sm-12 col-md-10">
                                            <p className="mt-1 line-ellipsis"><b>{data.advert.caption}</b></p>
                                            <p className="line-ellipsis">{data.advert.description}</p>

                                            <p>
                                                <small>Joined: {data.date_created}</small>
                                            </p>
                                            
                                            <div className="pt-0 pb-1">
                                                <div className="progress progress-bar-success">
                                                    {(data.promotionable && data.promotionable.claimed_at) 
                                                    ?
                                                    <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={100} aria-valuemax="100" style={{width: `${100}%`}}>
                                                    </div>
                                                    :
                                                    <div className="progress-bar" role="progressbar" aria-valuenow={`${data.advert.progress}`} aria-valuemin={`${data.advert.progress}}`} aria-valuemax="100" style={{width: `${data.advert.progress}%`}}>
                                                    </div>
                                                    }
                                                </div>
                                            </div>

                                            <p>
                                                <small className="text-success"><i>Funds you earned from promoting this Ad will be available for withdrawal after you claim.</i></small>
                                            </p>

                                            

                                            <div class="card-footer p-0">
                                                
                                                {(data.promotionable && data.promotionable.claimed_at) ? <span className="badge badge-danger">Claimed</span> : null }
                                                {costPerTrend && !data.promotionable  && !data.completed_at ? <span className="badge badge-warning">Fixed Reward:  &#8358; {costPerTrend}</span> : null }
                                                <span class=" float-sm-right d-flex flex-sm-row flex-column justify-content-end">
                                                    <button onClick={(e) => e.preventDefault()} class="btn btn-light-success mr-0 my-1 my-sm-0 mr-sm-1">Earned &#8358; {data.total_earning}</button>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>

                                
                                    {data.completed_at || (data.promotionable && data.promotionable.claimed_at)
                                    ?
                                        null
                                    :
                                        <div className="card-footer p-0" style={{border: '2px dashed #eee'}}>
                                            {data.has_verified_social_media
                                            ?
                                                <>
                                                    {data.promotionable && data.promotionable.verified_at
                                                        ?
                                                        <div>
                                                            <div class="" role="alert">
                                                                <div class="text-right p-1">
                                                                    {data.promotionable.claimable
                                                                    ?
                                                                        <>
                                                                            <small>Claim the earnings to move it to your available balance.</small>
                                                                            <button className="btn btn-info ml-2" onClick={(e) => claimPromotion(e, data.id, 'twitter-trend')}>
                                                                                {data.claim_loading ? <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> : null} Claim &#8358; {data.total_earning}
                                                                            </button>
                                                                        </>
                                                                    :
                                                                    <>
                                                                        <small>Your earnings on this promotion will be available for claim after {data.promotionable.claim_count_down}</small>
                                                                        <button className="btn btn-info ml-2" onClick={(e) => e.preventDefault()} disabled={true}>Claim &#8358; {data.total_earning}</button>
                                                                    </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="p-1">
                                                            <div className="col-12">
                                                                <small>Please follow these instructions to promote this ad successfully. &nbsp;
                                                                    <a className="text-danger" onClick={(e) => helpVideo(e)}><u> Watch Example Video</u></a>
                                                                <ol>
                                                                    <li>
                                                                        Click here &nbsp;
                                                                        <TwitterShareButton
                                                                            title={data.advert.caption}
                                                                            hashtags={[data.advert.hashtag ? `${data.advert.hashtag}` : ""]}
                                                                            className="cursor-pointer pt-50"
                                                                            url={data.social_link}
                                                                        >
                                                                            <TwitterIcon size={32} round />
                                                                        </TwitterShareButton> 
                                                                        &nbsp;
                                                                        to tweet this ad via the twitter handle you registered with Cluster.
                                                                    </li>
                                                                    <li>After tweeting, get the link of the tweet e.g  <b>https://twitter.com/username/status/1234567890</b></li>
                                                                    <li>Insert the tweet link you obtain into the text box below.</li>
                                                                    <li>Click the "Verify Tweet Link" button to finish the process.</li>
                                                                    <li>If you perform all the steps correctly, your promotion will be successfully verified.</li>
                                                                </ol>
                                                                </small>
                                                            </div>

                                                            <div className="col-12">
                                                                <fieldset>
                                                                    <div className="form-group">
                                                                        <input disabled={data.verification_loading} type="text" className="form-control" id={`verify_url_feed_${data.id}`} placeholder="Insert Tweet Link" aria-label="url" />
                                                                        
                                                                    </div>
                                                                    <div className="form-group text-right">
                                                                        <button disabled={data.verification_loading} onClick={(e) => submitVerification(e, data.id, verifyPromotion)} className="btn btn-danger" type="button">{data.verification_loading ? <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> : null} Verify Tweet Link </button>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            :
                                                <div>
                                                    <div class="alert bg-rgba-warning alert-dismissible mb-0" role="alert">
                                                        <div class="d-flex align-items-center">
                                                            <small>
                                                                <b>Twitter</b> 
                                                                <span> handle setup is required to proceed. &nbsp;
                                                                    <a href="/profile/settings/social-platforms"><u>Click here to setup Twitter.</u></a>
                                                                </span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }    



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                                       
    )
}


export default TwitterTrend;
import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import SkyLight from 'react-skylight';

import { SocialHandle } from './components/SocialHandle';
import { SocialForm } from './components/SocialForm';
import * as Actions from './actions/social-platforms.actions';
import Layout from 'themes/theme-five/layout/Layout';
import { getLocalToken } from 'helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import Modal from './components/modal';


class SocialPlatforms extends Component {

    constructor(props){
        super(props);

        this.state = {
            handle_2: '', //twitter
            handle_3: '', //instagram
            loading_handle_2: false,
            loading_handle_3: false,

            handle_verification_url: '', //url for verifing social media account
            modalIsOpen: false,
            modelPlatformData: null
        };
    }

    openModal = (modelPlatformData) => {
        this.setState({modalIsOpen: true, modelPlatformData})   
    }
    
    closeModal = () => {
        this.setState({modalIsOpen: false, handle_verification_url: ''})
    }

    async componentDidMount(){
        const { beforeFetchSocialPlatforms, fetchSocialPlatforms } = this.props.actions;

        await beforeFetchSocialPlatforms();
        fetchSocialPlatforms(getLocalToken());
    }


    addHandle = async (event, platform_id, url_path) => {
        event.preventDefault();

        const { beforeAddSocialMediaHandle, addSocialMediaHandle } = this.props.actions;

        const handle = this.state[`handle_${platform_id}`];

        await beforeAddSocialMediaHandle(platform_id);
        await addSocialMediaHandle(getLocalToken(), {
            platform_id, handle
        }, url_path);

        let { add_social_media_messages, add_social_media_status_code } = this.getReducer();

        networkNotification(add_social_media_messages, add_social_media_status_code);

        if(add_social_media_status_code === 200){
            const { beforeFetchSocialPlatforms, fetchSocialPlatforms } = this.props.actions;

            await beforeFetchSocialPlatforms();
            fetchSocialPlatforms(getLocalToken());
        }
    }


    finishVerification = async (event, id, url_path) => {
        event.preventDefault();

        const { clearVerificationMessages, beforeVerifySocialMediaHandle, verifySocialMediaHandle } = this.props.actions;
        const { handle_verification_url } = this.state;

        await clearVerificationMessages();

        await beforeVerifySocialMediaHandle(id);

        await verifySocialMediaHandle(getLocalToken(), { id: id, url: handle_verification_url}, url_path);

        let { verify_social_media_messages, verify_social_media_status_code } = this.getReducer();

        networkNotification(verify_social_media_messages, verify_social_media_status_code);
        
        if(verify_social_media_status_code == 200){
            this.closeModal();
            const { beforeFetchSocialPlatforms, fetchSocialPlatforms } = this.props.actions;
            await beforeFetchSocialPlatforms();
            await fetchSocialPlatforms(getLocalToken());
        }
    }


    deleteHandle = async (event, id) => {
        event.preventDefault();
        const { beforeDeleteSocialMediaHandle, deleteSocialMediaHandle } = this.props.actions;
        await beforeDeleteSocialMediaHandle(id);
        await deleteSocialMediaHandle(getLocalToken(), {id});

        let { delete_social_media_messages, delete_social_media_status_code } = this.getReducer();
        networkNotification(delete_social_media_messages, delete_social_media_status_code);
    }


    /**
     * Whenever updates are made to the form fields,
     * this function synchronizes with the state.
     */
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    onCopyToClipboard = () => {
        //customNotification(["Text copied to clipboard."], "Copied", "success");
        alert("Text copied to clipboard");
    }


    getReducer = () => {
        return this.props.dashboardSettingsSocialPlatformReducer;
    }


    render(){

        const { handle_2, handle_3, loading_handle_2, loading_handle_3 } = this.state;

        const { user_social_platforms, user_social_platforms_loading, user_social_platforms_status_code, static_social_form_data, verify_social_media_messages, verify_social_media_loading } = this.getReducer();

        return(
            <Layout>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Settings</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item"><span>Profile</span>
                                    </li>
                                    <li className="breadcrumb-item"><span><a href="/profile/settings">Settings</a></span>
                                    </li>
                                    <li className="breadcrumb-item active">Social Platforms
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">


                            <section className="kb-content">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Social Platforms</h4>
                                            </div>
                                            <div className="card-body">

                                                {user_social_platforms_loading 
                                                ?
                                                <LoadingSection message="Fetching your social platforms..." />
                                                :
                                                null
                                                }


                                                {!user_social_platforms_loading && user_social_platforms_status_code
                                                    ?
                                                    user_social_platforms.map(
                                                        user_social_platform => 
                                                            <SocialHandle 
                                                                user_social_platform={user_social_platform} 
                                                                openModal={this.openModal}
                                                                deleteHandle={this.deleteHandle}
                                                            />
                                                    )
                                                    :
                                                    null
                                                }


                                                {!user_social_platforms_loading && user_social_platforms_status_code
                                                    ? 
                                                    static_social_form_data.map(
                                                        type => {
                                                            return user_social_platforms.find(user_social_platform => user_social_platform.social_platform_type_id === type.id)
                                                            ?
                                                            null
                                                            :
                                                            <SocialForm 
                                                                formTypeData={type}
                                                                typeId={type.id} 
                                                                handleChange={this.handleChange}
                                                                addHandle={this.addHandle}
                                                                name={`handle_${type.id}`}
                                                                value={window[`handle_${type.id}`]}
                                                            />
                                                        }
                                                    )
                                                    :
                                                    null
                                                }

                                                

                                            </div>
                                        </div>
                                    </div>

                                  
                                
                                </div>
                            </section>


                        </div>

                    </div>
                </div> 

                <Modal 
                    show = {this.state.modalIsOpen} 
                    closeModal = {() => this.closeModal()}
                    modalLoading = {verify_social_media_loading}
                    modelPlatformData={this.state.modelPlatformData}
                    finishVerification={this.finishVerification}
                    handleChange={this.handleChange}
                    handleVerificationUrl={this.state.handle_verification_url}
                    onCopyToClipboard={this.onCopyToClipboard}
                    messages={verify_social_media_messages}
                />

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardSettingsSocialPlatformReducer: state.dashboardSettingsSocialPlatformReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialPlatforms));
import axios from 'axios';

import {
    BEFORE_CREATE_ADVERT,
    CREATE_ADVERT,
    NETWORK_ERROR_CREATING_ADVERT,

    BEFORE_FETCH_INITIALIZATION_DATA,
    FETCH_INITIALIZATION_DATA,
    NETWORK_ERROR_FETCHING_INITIALIZATION_DATA

} from '../types/create.types';

import {
    endpoint
} from '../../../../../../../config';


export const beforeFetchInitializationData = () => (
    { type:  BEFORE_FETCH_INITIALIZATION_DATA }
)

export const fetchInitializationData = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}constants/adverts/initialization`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });
            dispatch({ type: FETCH_INITIALIZATION_DATA, payload: response });

        }catch(e){
            
            await dispatch({type:  NETWORK_ERROR_FETCHING_INITIALIZATION_DATA, payload: e.response });
        
        }
    }
}


export const beforeCreateAdvert = () => (
    { type:  BEFORE_CREATE_ADVERT }
)

export const createAdvert = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}advert`,
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });
            dispatch({ type: CREATE_ADVERT, payload: response });

        }catch(e){
            if(!e.response){
                await dispatch({type:  NETWORK_ERROR_CREATING_ADVERT, payload: {status: 500, data: {status: 'failed', message: ["There was an error connecting to the server, please try again."]}} });
            }else{
                await dispatch({type:  NETWORK_ERROR_CREATING_ADVERT, payload: e.response });
            }
        }
    }
}
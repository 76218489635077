import React, { Component } from 'react';
import axios from 'axios';

import ConnectionError from 'themes/theme-five/pages/errors/connection-error/ConnectionError';
import { getParameter } from '../../../../helpers/url/url-parameters';
import { clearLocalToken, setLocalToken } from '../../../../helpers/authentication/token';
import {
    endpoint
} from 'config';



class Auth  extends Component {
    /**
     * 1. If auth is call for any reason, we will clear the local token
     * 2. Check for the authorization_token on the URL
     * 3. If the authorization token is Null maybe do  a redirect to auth.cluster.live
     * 4. If there is an authorization token we use to verify the user
     * 5. If verification returns true we make another call to authenticate the user on this platform
     * 6. 
     * 
     */

    constructor(props){
        super(props);

        this.state = {
            connectionError: false
        }
    } 

    async componentDidMount(){

        //Step 1:
        clearLocalToken();
        
        //Step 2:
        const authorizationToken = getParameter('token', null);
        const referrerUsername = getParameter('referrer', null);

        // const redirect = 'https://auth.cluster.live';

        //Step 3:
        if(!authorizationToken){
            //window.location = redirect;
        }

        //Step 4:
        const data = {
            authorization_token : authorizationToken,
            referrer: referrerUsername
        };

        const res = await this.verify(data);
            
        if(res.token){
            setLocalToken(res.token);
            window.location = '/';
        }else{
            if(res.type === 3){
                //Auth SSO server down, show connection error component
                this.setState({ connectionError: true });
            }else{
                //Redirect user to home/login
                window.location = '/';
            }
        }
    }

    verify = async (data) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}login`,
                headers: {
                    "content-type": "application/json",
                    "Accept" : "application/json",
                },
                data
            });

            return { type: 1, token: response.data.result.token };

        }catch(error){
            if(!error.response){
                return { type: 3, token: null };
            }else{
                return { type: 2, token: null };
            }
        }
    }

    render(){
        const { connectionError } = this.state;
        return(
            connectionError
            ?
                (<ConnectionError />)
            :
                (null)
        );
    }

}


export default Auth;
import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Layout from 'themes/theme-five/layout/Layout';
import Blank from 'components/commonCards/Blank';
import * as Actions from './actions/bank-accounts.actions';
import { getLocalToken } from 'helpers/authentication/token';
// import { getParameter, setParameters, onSearchFormUpdated } from 'helpers/url/url-parameters';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import {
	networkNotification
} from 'components/notifications/Notifications';


class BankAccounts extends Component {

    constructor(props){
        super(props);

        this.state = {
            account_number: '',
            account_name: '',
            bank_id: 0,
        }
    }

    /**
     * At initialization we set up the form to Add 
     * Accounts by getting a list of all the banks. 
     * Next we fetch all the users bank accounts.
     */
    async componentDidMount(){
        const { 
            beforeFetchBanksList, 
            fetchBanksList, 
            beforeFetchBankAccounts, 
            fetchBankAccounts 
        } = this.props.actions;

        await beforeFetchBanksList(); 
        await fetchBanksList(getLocalToken(), {});
        await beforeFetchBankAccounts();
        fetchBankAccounts(getLocalToken(), {});
    }


    createBankAccountRecipient = async (event) => {
        event.preventDefault();
        const { 
            beforeCreateBankAccount, 
            createBankAccount,
            beforeFetchBankAccounts, 
            fetchBankAccounts 
        } = this.props.actions;

        await beforeCreateBankAccount();
        await createBankAccount(getLocalToken(), this.state);

        const { 
            status_code_create,
            messages_create,
        } = this.getReducer();

        await networkNotification(messages_create, status_code_create);

        if(status_code_create === 200){
            this.setState({
                account_number: '',
                account_name: '',
                bank_id: 0
            });
            await beforeFetchBankAccounts(); 
            fetchBankAccounts(getLocalToken(), {});
        }
    }


    makeDefaultBankAccountRecipient = async (event, account_id) => {
        event.preventDefault();
        const { 
            beforeMakeDefaultBankAccount, 
            makeDefaultBankAccount 
        } = this.props.actions;

        await beforeMakeDefaultBankAccount(account_id);
        await makeDefaultBankAccount(getLocalToken(), { account_id });

        let { 
            status_code_bank_account_default, 
            messages_bank_account_default 
        } = this.getReducer();

        networkNotification(
            messages_bank_account_default, 
            status_code_bank_account_default
        );
    }


    deleteBankAccountRecipient = async(event, account_id) => {
        event.preventDefault();
        const { 
            beforeDeleteBankAccount, 
            deleteBankAccount
        } = this.props.actions;

        await beforeDeleteBankAccount(account_id);
        await deleteBankAccount(getLocalToken(), { account_id });

        let { 
            status_code_bank_account_delete, 
            messages_bank_account_delete 
        } = this.getReducer();

        networkNotification(
            messages_bank_account_delete, 
            status_code_bank_account_delete
        );
    }


    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    /** 
     * A very easy and convenient way to set and 
     * retrieve the reducer responsible for rendering 
     * this page under one generic function name.
     */
    getReducer = () => {
        return this.props.dashboardBanksListReducer;
    }


    render(){
        const { account_number, account_name, bank_id} = this.state;
        const {
            loading, banks, bank_accounts, 
            loading_bank_accounts, status_code_bank_accounts, loading_create
        } = this.getReducer();

        return(
            <Layout>

                <Helmet>
                    <title>Bank Accounts | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Bank Accounts</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                        <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span>Profile</span>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            Bank Accounts    
                                        </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">
                            {loading 
                            ?
                                <LoadingSection />
                            :
                                <section id="bg-variants">

                                    <div className="users-list-filter px-1">
                                        <form onSubmit={this.createBankAccountRecipient}>
                                            <div className="row border rounded py-2 mb-2">
                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label for="users-list-verified">Bank Name</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" onChange={this.handleChange} name="bank_id" value={bank_id} id="users-list-verified" disabled={loading_create}>
                                                            <option value={0}>-- Select Bank --</option>
                                                            {banks.map(
                                                                bank => 
                                                                <option value={bank.id}>{bank.name}</option>
                                                            )}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label for="users-list-role">Account Name</label>
                                                    <input className="form-control" placeholder="Enter Account Name" onChange={this.handleChange} name="account_name" value={account_name} disabled={loading_create} />
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label for="users-list-status">Account Number</label>
                                                    <input className="form-control" placeholder="NUBAN" onChange={this.handleChange} name="account_number" value={account_number} disabled={loading_create}  />
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
                                                    <button type="submit" className="btn btn-success btn-block glow users-list-clear mb-0">
                                                        {loading_create
                                                            ?
                                                                <><i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> Processing...</>
                                                            :
                                                            <>Add</>
                                                        }
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                    <div className="">
                                        <div className="row">
                                            

                                                    {loading_bank_accounts 
                                                    ?
                                                        <div className="col-md-12 mb-0 mt-0">
                                                            <LoadingSection />
                                                        </div>
                                                    :
                                                        (!loading_bank_accounts && bank_accounts.length > 0 && status_code_bank_accounts === 200)
                                                        ?
                                                            
                                                            bank_accounts.map( 
                                                                account =>  
                                                                    <div className="col-md-12 mb-0 mt-0">
                                                                        <div className="card p-1 mb-1 mt-0">
                                                                            <div className="row">
                                                                                <div className="col-12 col-sm-3 col-md-2 mb-1">
                                                                                    <b>{account.bank.name}</b>
                                                                                </div>
                                                                                <div className="col-6 col-sm-9 col-md-4">
                                                                                    <p>
                                                                                        <span className="text-dark">{account.account_name}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-6 col-sm-6 col-md-2">
                                                                                    {account.protected_account_number}
                                                                                </div>
                                                                                <div className="col-6 col-sm-6 col-md-2">
                                                                                    {account.default !== 1 
                                                                                    ?
                                                                                        <button className="btn btn-sm btn-outline-light" onClick={(event) => this.makeDefaultBankAccountRecipient(event, account.id)}>
                                                                                            {account.loading_as_default &&
                                                                                                <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                                                            }
                                                                                            Make Default
                                                                                        </button>
                                                                                    :
                                                                                        <b className="badge badge-light-success">Default</b>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-6 col-sm-6 col-md-2 text-right">
                                                                                    <button className="btn btn-icon round btn-outline-danger" onClick={(event) => this.deleteBankAccountRecipient(event, account.id)}>
                                                                                        {account.loading 
                                                                                            ?
                                                                                                <i className="bx bx-loader-circle bx-spin bx-flip-horizontal"></i>
                                                                                            :  
                                                                                                <i className="bx bx-trash"></i>  
                                                                                        }
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                            )
                                                            
                                                        :
                                                        <div className="col-md-12 mb-0 mt-0">
                                                            <Blank>
                                                                <span>
                                                                    You do not have any Bank Account registered for payout, add a new Bank Account above.
                                                                </span>
                                                            </Blank>
                                                        </div>
                                                    }

                                                
                                        </div>
                                    </div>

                                </section>  
                            }
                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardBanksListReducer: state.dashboardBanksListReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankAccounts));
import React, { Component } from 'react';

// import {
//     singleSignOnCallBack
// } from 'config';

class ConnectionError extends Component {


    componentDidMount(){
        console.log("Path", window.location.pathname );
    }

    render(){
        return(
            <div  className="vertical-layout vertical-menu-modern 1-column  navbar-sticky footer-static bg-full-screen-image  blank-page" data-open="click" data-menu="vertical-menu-modern" data-col="1-column">
            
                <div className="app-content content">
                
                    <div className="content-overlay">

                    </div>
                    
                    <div className="content-wrapper">
                        
                        <div className="content-header row">
                        </div>
                        
                        <div className="content-body">
                            
                            <section className="row flexbox-container">
                                <div className="col-xl-6 col-md-7 col-9">
                                    <div className="card bg-transparent shadow-none">
                                        <div className="card-body text-center bg-transparent">

                                            <img  src="/logo-black.png" className="mb-2" height="100" alt="Cluster" />
                                            
                                            <h1 className="error-title text-white">
                                                {this.props.title
                                                ?
                                                    (this.props.title)
                                                :
                                                    ("Trouble Connecting to Cluster Services :(")
                                                }
                                            </h1>
                                            
                                            <p className="">
                                                {this.props.message
                                                ?
                                                    (this.props.message)
                                                :
                                                    ("It appears that there is a connectivity issue. Please ensure your internet is working properly and try again by clicking the button below.")
                                                }
                                           </p>
                                           
                                            <a href={window.location} className="btn btn-danger btn-lg  glow mt-1">Try again</a>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default ConnectionError;
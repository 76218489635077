import axios from 'axios';
import {
    BEFORE_FETCH_DASHBOARD_NOTIFICATIONS,
    FETCH_DASHBOARD_NOTIFICATIONS,
    NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATIONS
} from '../types/notifications.types';

import {
    endpoint
} from 'config';


export const beforeFetchNotifications = () => ({
    type: BEFORE_FETCH_DASHBOARD_NOTIFICATIONS
});


export const fetchNotifications = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}notifications`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            console.log("NOTIFICATIONS HISTORY", response);

            dispatch({ type: FETCH_DASHBOARD_NOTIFICATIONS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATIONS, payload: e.response });
        }
    }
}
import React from 'react';
import { store } from 'react-notifications-component';


const messagesToUnorderedList = (messages) => (
    <ul>
        {
            messages.map((element, index) => 
                <li key={`notify${index}`}>{element}</li>
            )
        }
    </ul>
) 

export const networkNotification = async (messages, status_code) => {
    
    store.addNotification({
        title: (status_code === 200) ? "Successful." : "Just a moment!",
        message: messagesToUnorderedList(messages),
        type: (status_code === 200) ? "success" : "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true,
            pauseOnHover: true
        },
    });

}


export const customNotification = (messages, title = "Just a moment!", type = "danger", position = "top-right") => {
    //type = (type !== 'success') ? 'danger' : 'success'
    
    store.addNotification({
        title,
        message: messagesToUnorderedList(messages),
        type,
        insert: "top",
        container: position,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true,
            pauseOnHover: true
        }
    });
}





import React from 'react';


export const SpinnerBorder = (props) => {

    return (
        <div className="spinner-border spinner-border-lg" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}


export const LoadingSection = (props) => {

    return (
        <section id="bg-variants">  
            <div className="pt-5 pb-5">
                <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
                    <SpinnerBorder />
                </div>
                {props.message && <p className="p-1 text-center">{props.message}</p>}
            </div>
        </section>
    );
}
import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Pagination } from 'react-laravel-paginex';
import marked from 'marked';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from "react-helmet";

import * as Actions from './actions/notification-details.actions';
import Layout from '../../../../layout/Layout';
import Blank from 'components/commonCards/Blank';
import MonthFilter from 'components/monthFilter/MonthFilter';
import { getLocalToken } from 'helpers/authentication/token';
import { getParameter, setParameters } from 'helpers/url/url-parameters';
import {
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';


class Notifications extends Component {

    constructor(props){
        super(props);

        this.state = {
            moment: moment()
        };
    }

    async componentDidMount(){
        //console.log("Auth:: ", this.props.auth_user);

        const { beforeFetchNotificationDetails, fetchNotificationDetails } = this.props.actions;

        await beforeFetchNotificationDetails();
        await fetchNotificationDetails(getLocalToken(), {id: this.props.match.params.notification_id});

        /**
         * Its important to note that the layout details load before
         * the notification details, when a notification is read, the
         * layout is unaware of this change. To resolve this issue we
         * make api call to the endpoints below to update Layout.
         */
        const { beforeFetchLayoutData, fetchLayoutData} = this.props.actions;
        await beforeFetchLayoutData();
        fetchLayoutData(getLocalToken());
    }


    /**
     * This is the callback that is triggered whenever 
     * the user clicks on a pagination option.
     * It receives the page data object of the event.
     */
    paginateNotifications = (data) => {
        setParameters([
            { name: 'page', value: data.page },
        ]);
    }


    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardNotificationDetailsReducer;
    }


    render(){

        const { loading, status_code , notification } = this.getReducer();

        const { moment } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Notification Details | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Notification Details</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                        <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                        </li>
                                        <li className="breadcrumb-item"><a href={window.location} onClick={(event)=>{event.preventDefault()}}>Profile</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="/profile/notifications">Notifications</a>
                                        </li>
                                        {notification &&
                                        <li className="breadcrumb-item active">{notification.data.subject}
                                        </li>
                                        }
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>


                        <div className="content-body">


                            {loading
                            ?
                                <LoadingSection />

                            :
                                <section id="bg-variants">
                                    
                                    {status_code && status_code != 200 
                                        ?
                                            <Blank>
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <h3>Error fetching notification details</h3>
                                                        <small>Page could not be loaded, click button below to return to notifications.</small>
                                                    </div>
                                                    <div className="col-md-12 text-center mt-2">
                                                        <a className="btn btn-danger" href="/profile/notifications">Notifications</a>
                                                    </div>
                                                </div>
                                            </Blank>
                                        :
                                        <div className="card">
                                            {notification &&
                                                ReactHtmlParser(notification.data.body)
                                            }
                                        </div>
                                    }

                                </section>  
                            }

                        </div>
                    
                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardNotificationDetailsReducer: state.dashboardNotificationDetailsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import {
    ssoUrl,
    singleSignOnCallBack
} from '../../../../config';
//import './styles/styles.css';
import Footer from './components/footer';
import Nav from './components/nav';
import Header from './components/header';


class Home extends Component {


    articles = () => {
        return [
            {
                source: 'Instagram',
                icon: '/themefive/app-assets/images/icon/instagram-rounded.png',
                image: '/themefive/app-assets/images/home/home-banner-02.jpeg',
                title: 'Jobs any Nigerian can do.',
                description: `Earning Money as a Nigerian doesn't have to be Hard. 
                Here are a legitimate way you can earn money as a Nigerian without any special skill or certificate
                Imagine getting paid to use social media. As a Cluster Promoter, you get paid to post on Instagram, Facebook, Whatsapp and any social media platform you use.
                It's free to join. And you get paid for sharing ads across your social media platforms.
                All you have to do is head to “ads.cluster.live” (link in our bio)
                Signup on the website, verify your account, start sharing ads
                And €arn 🤑🤑
                
                It’s that simple!
                Click on the link in our bio and get started!!`,
                account_name: 'clusterliveapp',
                date: 'Nov 24, 2021',
                duration: '1 min',
                article_url: 'https://www.instagram.com/p/CWlMwBqMs3c/',
                page_url: `https://www.instagram.com/clusterliveapp/`
            },
            {
                source: 'Facebook',
                icon: '/themefive/app-assets/images/icon/facebook-rounded-blue.png',
                image: '/themefive/app-assets/images/home/home-banner-01.jpeg',
                title: 'How to make money from your blog.',
                description: `Are you a blogger in Nigeria?
                Are you struggling to get make money from your blog?
                Here's how you can make money from your blog even without Google adsense or thousands of traffic on your blog
                Go to ads.cluster.live today and get started!`,
                account_name: 'ads.cluster.live',
                date: 'Dec 21, 2021',
                duration: '1 min',
                article_url: 'https://www.facebook.com/Adsclusterlive-104384148694755/photos/pcb.132153812584455/132153122584524/',
                page_url: 'https://www.facebook.com/Adsclusterlive-104384148694755'
            },
            {
                source: 'Facebook',
                icon: '/themefive/app-assets/images/icon/facebook-rounded-blue.png',
                image: '/themefive/app-assets/images/home/home-banner-03.jpeg',
                title: '3 Reasons why passive income is so important.',
                description: `Making more money while you sleep or without you doing anything is the beauty of passive income 
                It's a dream for everyone, but in case you are not convinced enough, here are 3 reasons to earn passive income
                To start earning passive income now, head on to the link in our bio.`,
                account_name: 'ads.cluster.live',
                date: 'Dec 13, 2021',
                duration: '1 min',
                article_url: 'https://www.facebook.com/Adsclusterlive-104384148694755/photos/pcb.130155942784242/130155812784255/',
                page_url: 'https://www.facebook.com/Adsclusterlive-104384148694755'
            }
        ];
    }

    async componentDidMount(){

        document.getElementById('body-tag').setAttribute( "class", "d-flex flex-column h-100");
        document.getElementById('root').setAttribute( "class", "flex-shrink-0");


        const dynamicStyles = [
            "/themefive/app-assets/css/home/styles.css", 
            "/themefive/app-assets/css/home/footer.css"
        ];

        dynamicStyles.forEach(function(styleHref){
            const style = document.createElement("link");
            style.href = styleHref;
            style.rel = "stylesheet";
            style.type = "text/css";
            document.head.appendChild(style);
        });


        const array = [
             'apex-charts', 'swiper-min', 'bootstrap-extended', 'colors', 'components', 
            'dark-layout', 'semi-dark-layout', 'vertical-menu', 'dashboard-ecommerce', 'custom-style'
        ];

        array.forEach(function(style){
            const element =  document.getElementById(style);
            if(element && element.parentNode){
                element.parentNode.removeChild(element);
            }
        });
        

        const script = document.createElement("script");
		script.src = "/themefive/app-assets/js/home/home.bootstrap.bundle.min.js";
		script.async = true;
		document.body.appendChild(script);
    }

    render(){

        return(
            <>

                <Helmet>
                    <title>Home | Cluster Advertisement Network</title>
                </Helmet>

                <Nav />


                <Header />


                <section className="pb-4 bg-dark text-white" id="introduction">
                    <div className="container px-5 my-0">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <div className="fs-4 mb-0 fst-italic">
                                        <u>ads.cluster.live</u> is a unique platform that pays YOU simply for sharing ads with your friends or across your social network.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            
                <section className="py-5" id="how-it-works">
                    <div className="container px-5 my-5">
                        <div className="row gx-5">
                            <div className="col-lg-4 mb-5 mb-lg-0"><h2 className="fw-bolder mb-0 text-dark">How It Works.</h2></div>
                            <div className="col-lg-8">
                                <div className="row gx-5 row-cols-1 row-cols-md-2">
                                    <div className="col mb-5 h-100">
                                        <div className="feature bg-danger bg-gradient text-white rounded-3 mb-3">
                                            <span className="bx bxs-user-check"></span>
                                        </div>
                                        <h2 className="h5 text-dark">1. Register Account</h2>
                                        <p className="mb-0">
                                            Before you can start earning, you will first need to register an account to become a member of our advertisement network.
                                            Your account will consist of a dashboard with many functions including finding ads to promote, viewing & withdrawing earnings and so much more.
                                            </p>
                                    </div>
                                    <div className="col mb-5 h-100">
                                        <div className="feature bg-danger bg-gradient text-white rounded-3 mb-3">
                                            <span className="bx bxs-megaphone"></span>
                                        </div>
                                        <h2 className="h5 text-dark">2. Promote Ads</h2>
                                        <p className="mb-0">
                                            After successfully setting up your account, navigate to the Jobs section of your dashboard to find ads to promote.
                                            Apply to promote any ad of your choosing, this will generate a monetized link that you can share with peers & followers accross your social media channels.
                                        </p>
                                    </div>
                                    <div className="col mb-5 mb-md-0 h-100">
                                        <div className="feature bg-danger bg-gradient text-white rounded-3 mb-3 p-2">
                                            <span className="bx bx-money"></span>
                                        </div>
                                        <h2 className="h5 text-dark">3. Start Earning</h2>
                                        <p className="mb-0">
                                            As people click on your monetized links, you will immediately start to earn. 
                                            On your dashboard you can view all your earnings by clicking the Earnings link on the navigation menu.
                                        </p>
                                    </div>
                                    <div className="col h-100">
                                        <div className="feature bg-danger bg-gradient text-white rounded-3 mb-3 p-2">
                                            <span className="bx bxs-trophy"></span>
                                        </div>
                                        <h2 className="h5 text-dark">4. Level Up</h2>
                                        <p className="mb-0">
                                            On Cluster we reward you with points for promoting ads and referring friends to the platform. 
                                            As you accumulate points and ascend the level ranks, so does your earnings increase per click.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="py-5 bg-light">
                    <div className="container px-5 my-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-10 col-xl-7">
                                <div className="text-center">
                                    <div className="fs-4 mb-4 fst-italic">
                                        "Google, Facebook and other companies have been making a lot of money from Nigeria through advertising. They get paid to display ads to you and make money from you.
                                        With Cluster you can now participate and benifit from advertising either by sharing ads across your numerous social media platforms e.g Facebook, Instagram, Whatsapp, 
                                        Twitter e.t.c or by utilizing our promoters network to go viral on your personal or corporate advertisements."
                                    </div>
                                    {/*
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img className="rounded-circle me-3" src="https://dummyimage.com/40x40/ced4da/6c757d" alt="..." />
                                        <div className="fw-bold">
                                            Tom Ato
                                            <span className="fw-bold text-primary mx-1">/</span>
                                            CEO, Pomodoro
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="py-5" id="articles">
                    <div className="container px-5 my-5">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 col-xl-6">
                                <div className="text-center">
                                    <h2 className="fw-bolder text-dark">Articles.</h2>
                                    <p className="lead fw-normal text-muted mb-5">Find out more and get the latest updates by exploring posts and articles on our social media pages.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row gx-5">

                            {this.articles().map(
                                article =>
                                <div className="col-lg-4 mb-5">
                                    <div className="card h-100 shadow border-0">
                                        <a className="text-decoration-none" href={article.article_url} target="_blank">
                                            <img className="card-img-top" src={article.image} alt="..." />
                                        </a>
                                        <div className="card-body p-4">
                                            <div className="badge bg-danger bg-gradient rounded-pill mb-2">{article.source}</div>
                                            <a className="text-decoration-none link-dark" href={article.article_url} target="_blank"><h5 className="card-title mb-3">{article.title ? `${article.title.substring(0, 25)}...` : article.title}</h5></a>
                                            <p className="card-text mb-0">{article.description ? `${article.description.substring(0, 130)}...`: article.description}</p>
                                        </div>
                                        <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                                            <div className="d-flex align-items-end justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <a className="text-decoration-none" href={article.page_url} target="_blank">
                                                        <img className="rounded-circle me-3" src={article.icon} width="40" alt="..." />
                                                    </a>
                                                    <div className="small">
                                                        <div className="fw-bold">{article.account_name}</div>
                                                        <div className="text-muted">{article.date} &middot; {article.duration} read</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                           
                        </div>
                    
                        {/*
                        <aside className="bg-danger bg-gradient rounded-3 p-4 p-sm-5 mt-5">
                            <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
                                <div className="mb-4 mb-xl-0">
                                    <div className="fs-3 fw-bold text-white">New products, delivered to you.</div>
                                    <div className="text-white-50">Sign up for our newsletter for the latest updates.</div>
                                </div>
                                <div className="ms-xl-4">
                                    <div className="input-group mb-2">
                                        <input className="form-control" type="text" placeholder="Email address..." aria-label="Email address..." aria-describedby="button-newsletter" />
                                        <button className="btn btn-outline-light" id="button-newsletter" type="button">Sign up</button>
                                    </div>
                                    <div className="small text-white-50">We care about privacy, and will never share your data.</div>
                                </div>
                            </div>
                        </aside>
                        */}
                    </div>
                </section>



                <Footer />
            </>
        );
    }
}

export default Home;
import {
    BEFORE_FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA,
    FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA,
    NETWORK_ERROR_FETCHING_DASHBOARD_EARNINGS_BAR_CHART_DATA
} from '../types/index.types';

let initialState = {
    bar_chart_loading: false,
    bar_chart_messages: [],
    bar_chart_status_code: null,
    bar_chart_data: [],
    card_statistics: null
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: true
            });
        }
        case FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: false,
                bar_chart_status_code: action.payload.status,
                bar_chart_messages: action.payload.data.message,
                bar_chart_data: action.payload.data.result.rechart_earnings_expenditure_barchart,
                card_statistics: action.payload.data.result.card_statistics
            });
        }
        case NETWORK_ERROR_FETCHING_DASHBOARD_EARNINGS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: false,
                bar_chart_status_code: action.payload.status,
                bar_chart_messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }
}
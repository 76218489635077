import axios from 'axios';

import {
    BEFORE_FETCH_DASHBOARD_TRANSACTIONS,
    FETCH_DASHBOARD_TRANSACTIONS,
    NETWORK_ERROR_FETCHING_DASHBOARD_TRANSACTIONS
} from '../types/transaction-history.types';

import {
    endpoint
} from 'config';


export const beforeFetchTransactions = () => ({
    type: BEFORE_FETCH_DASHBOARD_TRANSACTIONS
});


export const fetchTransactions = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}transactions`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            console.log("TRANSACTION HISTORY", response);

            dispatch({ type: FETCH_DASHBOARD_TRANSACTIONS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_DASHBOARD_TRANSACTIONS, payload: e.response });
        }
    }
}
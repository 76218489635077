import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Helmet } from "react-helmet";

import { BarChart, Bar, Cell,   Brush, Label,
    ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    PieChart, Pie, Sector 
} from 'recharts';

import * as Actions from './actions/earnings.actions';
import Layout from '../../../../layout/Layout';
import MonthFilter from 'components/monthFilter/MonthFilter';
import { getLocalToken } from 'helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';



const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
  
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`₦ ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

class Earnings extends Component {

    constructor(props){
        super(props);

        this.state = {
            activeIndex: 0,
            moment: moment()
        };
    }

    async componentDidMount(){
        console.log("Auth:: ", this.props.auth_user);
        const {
            beforeFetchEarnings, 
            fetchEarnings, 
            beforeFetchBarChartData,
            fetchBarChartData,
            beforeFetchPieChartData, 
            fetchPieChartData
        } = this.props.actions;

        await beforeFetchEarnings();
        await fetchEarnings(getLocalToken());
        await Promise.all([beforeFetchBarChartData(), beforeFetchPieChartData()]);
        await Promise.all([
            fetchBarChartData(getLocalToken(), { date: this.state.moment.format() }), 
            fetchPieChartData(getLocalToken())
        ]);
    }

    withdraw = async (event) => {
        event.preventDefault();
        const {beforeWithdrawAvailableBalance, withdrawAvailableBalance} = this.props.actions;
        await beforeWithdrawAvailableBalance();
        await withdrawAvailableBalance(getLocalToken());

        const { withdraw_status_code, withdraw_messages } = this.getReducer();

        networkNotification(withdraw_messages, withdraw_status_code);

        const {beforeFetchEarnings, fetchEarnings} = this.props.actions;
        await beforeFetchEarnings();
        fetchEarnings(getLocalToken());
    }


    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardEarningsReducer;
    }

    dynamicWidth = (idAttribute) => {
        const elem = document.getElementById(idAttribute);
        return elem ? elem.offsetWidth : 600;
    }

    dynamicHeight = (idAttribute) => {
        const elem = document.getElementById(idAttribute);
        const height = elem ? elem.offsetHeight : 600;
        console.log("PIE JACK", height);
        return height;
    }

    onPieEnter = (event, index) => {
        //event.preventDefault();
        this.setState({
            activeIndex: index,
        });
    };



    /**
     * Note that the month is an object of moment
     */
    onFilterEarningByMonth = async (month) => {
        const { beforeFetchBarChartData, fetchBarChartData } = this.props.actions;
        this.setState({ moment: month });
        await beforeFetchBarChartData(); 
        fetchBarChartData(getLocalToken(), { date: month.format() });
    }


    render(){

        const { 
            loading, earnings, bank_account, withdraw_loading, 
            bar_chart_data, 
            bar_chart_loading, 
            bar_chart_status_code,
            pie_chart_data,
            pie_chart_loading, 
            pie_chart_status_code
        } = this.getReducer();

        const { moment } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Earnings | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Earnings</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item">Promoter
                                    </li>
                                    <li className="breadcrumb-item active">Earnings
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">

                            <section id="widgets-Statistics" className="">

                                <div className="row">

                                    <div className="col-xl-6 mt-1">
                                        <div className="card h-100">
                                            <div className="card-header">
                                                <h3 className="greeting-text">Available Balance</h3>
                                                <span className="badge badge-warning">Minimum withdraw balance is &#8358; 120</span>
                                            </div>
                                            <div className="card-body pt-0 pb-0">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h1 className="text-success font-large-2 text-bold-500">
                                                                {loading 
                                                                ?
                                                                    <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                                :
                                                                    <>&#8358; {earnings && earnings.available_balance.toLocaleString()}</>  
                                                                }
                                                            </h1>
                                                            <p>Current amount available to withdraw.</p>
                                                            <p><small class="text-warning"><b>Withdrawal Charges &#8358; 10.75 (&#8358; 5,000 & below)</b></small></p>
                                                            <p><small class="text-warning"><b>Withdrawal Charges &#8358; 26.875 (&#8358; 5,001 & above)</b></small></p>
                                                            <hr />

                                                            {!loading && bank_account &&
                                                                <p>
                                                                    {bank_account.account_name} 
                                                                    <br />NUBAN: {bank_account.protected_account_number} 
                                                                    <br/>{bank_account.bank.name} 
                                                                    <a className="ml-1" href="/profile/bank-accounts">Change</a>
                                                                </p>
                                                            }

                                                            {!loading && !bank_account &&
                                                                <p className="text-warning">
                                                                    Payout setting required. Setup your payout bank account <a href="/profile/bank-accounts"><b>here</b></a> before you withdraw.
                                                                </p>
                                                            }

                                                            <button disabled={withdraw_loading || loading || (earnings && earnings.available_balance < 120) || (bank_account == null) ? true : false} className="btn btn-lg btn-success glow" onClick={this.withdraw}>
                                                                {withdraw_loading 
                                                                ?
                                                                    <><i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> Withdrawing...</>
                                                                :
                                                                    <>Withdraw Balance</>
                                                                }
                                                                
                                                            </button>
                                                        </div>
                                                        
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-xl-6 mt-1">
                                        <div className="card h-100">
                                            <div className="card-body d-flex justify-content-center align-items-center p-0" id="cluster-chart-container-2">

                                            {loading || pie_chart_loading
                                                ?
                                                    <LoadingSection />
                                                :
                                                    <>
                                                        {(earnings && earnings.total_earned == 0)
                                                        ?
                                                            <div className="p-5">
                                                                <div id="cluster-chart-container-3">
                                                                    <div className="d-flex justify-content-center align-items-center bg-rgba-warning"  style={{borderRadius: '50%', width: `${200}px`, height: `${200}px`}}><span className="text-success">No data available</span></div>
                                                                </div>
                                                            </div> 
                                                        :
                                                            <PieChart width={this.dynamicWidth("cluster-chart-container-2")} height={250}>
                                                                <Pie
                                                                    activeIndex={this.state.activeIndex}
                                                                    activeShape={renderActiveShape}
                                                                    data={pie_chart_data}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    innerRadius={60}
                                                                    outerRadius={80}
                                                                    fill="#39da8a"
                                                                    dataKey="value"
                                                                    onMouseOver={this.onPieEnter}
                                                                />
                                                            </PieChart>
                                                        }
                                                    </>
                                            }    
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="row mt-1">
                                    <div className="col-md-4 col-sm-12 mb-1">
                                        <div className="card h-100">
                                            <div className="card-body d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar bg-rgba-success m-0 p-25 mr-75 mr-xl-2">
                                                        <div className="avatar-content">
                                                            <i className="bx bx-sort-down text-success font-medium-2"></i>
                                                        </div>
                                                    </div>
                                                    <div className="total-amount">
                                                        <h5 className="mb-0">
                                                            {loading 
                                                            ?
                                                                <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                            :
                                                                <>&#8358; {earnings && earnings.total_earned}</>  
                                                            }
                                                        </h5>
                                                        <small className="text-muted">Total Earned (Including withdrawn & Pending)</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-12 mb-1">
                                        <div className="card h-100">
                                            <div className="card-body d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar bg-rgba-success m-0 p-25 mr-75 mr-xl-2">
                                                    <div className="avatar-content">
                                                        <i className="bx bx-sort-up text-success font-medium-2"></i>
                                                    </div>
                                                    </div>
                                                    <div className="total-amount">
                                                        <h5 className="mb-0">
                                                            {loading 
                                                            ?
                                                                <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                            :
                                                                <>&#8358; {earnings && earnings.total_withdrawn}</>  
                                                            }
                                                        </h5>
                                                        <small className="text-muted">Total Withdrawn</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                            
                                    <div className="col-md-4 col-sm-12 mb-1">
                                        <div className="card h-100">
                                            <div className="card-body d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar bg-rgba-success m-0 p-25 mr-75 mr-xl-2">
                                                        <div className="avatar-content">
                                                            <i className="bx bx-time text-success font-medium-2"></i>
                                                        </div>
                                                    </div>
                                                    <div className="total-amount">
                                                        <h5 className="mb-0">
                                                            {loading 
                                                            ?
                                                                <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                            :
                                                                <>&#8358; {earnings && earnings.balance_pending}</>  
                                                            }
                                                        </h5>
                                                        <small className="text-muted">Balance Pending</small>
                                                        <br />
                                                        <hr />
                                                        <small className="text-warning">Funds will be released together or in-part for Ad campaigns that has been completed.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>


                            <section>
                                <div className="row mb-4 flex">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                                        <div className="card h-100 p-1">

                                            <div className="card-body p-0" id="cluster-chart-container">
                                                
                                                <MonthFilter callback={this.onFilterEarningByMonth} isLoading={bar_chart_loading} />
                                                
                                                {loading || bar_chart_loading
                                                ?
                                                    <LoadingSection />
                                                :
                                                    <BarChart
                                                        width={this.dynamicWidth("cluster-chart-container")}
                                                        height={this.dynamicWidth("cluster-chart-container") * 0.5}
                                                        data={bar_chart_data}
                                                        margin={{
                                                            top: 0,
                                                            right: 0,
                                                            left: 0,
                                                            bottom: 30,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis label={{ value: `Days (${moment ? moment.format('MMM/YYYY') : moment().format('MMM/YYYY')})`, angle: 0, position: 'bottom' }} dataKey="day">
                                                            
                                                        </XAxis>
                                                        <YAxis label={{ value: 'Earnings (₦)', angle: -90, position: 'insideLeft' }} />
                                                        <Tooltip />
                                                        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                        <ReferenceLine y={0} stroke="#000" />
                                                        
                                                        <Bar dataKey="earnings" fill="#39da8a" />
                                                    </BarChart>
                                                }
                                            </div>
                                        
                                        </div>        
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardEarningsReducer: state.dashboardEarningsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Earnings));
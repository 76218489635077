import axios from 'axios';
import {
    BEFORE_FETCH_DASHBOARD_NOTIFICATION_DETAILS,
    FETCH_DASHBOARD_NOTIFICATION_DETAILS,
    NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATION_DETAILS,
    BEFORE_FETCH_DASHBOARD_LAYOUT_DATA,
    FETCH_DASHBOARD_LAYOUT_DATA,
    NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA
} from '../types/notification-details.types';

import {
    endpoint
} from 'config';


export const beforeFetchNotificationDetails = () => ({
    type: BEFORE_FETCH_DASHBOARD_NOTIFICATION_DETAILS
});


export const fetchNotificationDetails = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}notification/${data.id}`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });

            console.log("NOTIFICATIONS HISTORY", response);

            dispatch({ type: FETCH_DASHBOARD_NOTIFICATION_DETAILS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATION_DETAILS, payload: e.response });
        }
    }
}



/**
 * Action for updating Layout.
 * This Action is connected to the layout reducer and not notification 
 * details reducer, because the TYPE is defined in layout reducer only.
 */
export const beforeFetchLayoutData = () => ({
    type: BEFORE_FETCH_DASHBOARD_LAYOUT_DATA
});

/**
 * Action for updating Layout.
 * This Action is connected to the layout reducer and not notification 
 * details reducer, because the TYPE is defined in layout reducer only.
 */
export const fetchLayoutData = (token) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}user/layout`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });

            console.log("Notifications data: ", response);
            dispatch({ type: FETCH_DASHBOARD_LAYOUT_DATA, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA, payload: e.response });
        }
    }
}
import {
    BEFORE_FETCH_ADVERT,
    FETCH_ADVERT,
    NETWORK_ERROR_FETCHING_ADVERT,
    BEFORE_SUMBITING_SURVEY,
    SUBMIT_SURVEY_ACTION,
    ERROR_SUBMITTING_SURVEY
} from '../types/campaign.types';

let initialState = {
    loading: false,
    message: [],
    status_code: null,
    advert: null,
    surveyAnswerLoading: false,
    surveyAnswered: false
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_ADVERT: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_ADVERT: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                advert: action.payload.data.result.advert
            });
        }
        case NETWORK_ERROR_FETCHING_ADVERT: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }
        case BEFORE_SUMBITING_SURVEY: {
            return Object.assign({}, state, {
                surveyAnswerLoading: true
            });
        }

        case SUBMIT_SURVEY_ACTION: {
            return Object.assign({}, state, {
                surveyAnswerLoading: false,
                surveyAnswered: true
            });
        }

        case ERROR_SUBMITTING_SURVEY: {
            return Object.assign({}, state, {
                surveyAnswerLoading: false
            });
        }
        default: 
            return state;
    }
}
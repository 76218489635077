import React from "react";
import {
    ssoUrl,
    singleSignOnCallBack
} from '../../../../../config';


const header = () => {
    return (<>
        <header className="bg-dark py-5">
            <div className="container px-5">
                <div className="row gx-5 align-items-center justify-content-center">
                    <div className="col-lg-8 col-xl-7 col-xxl-6">
                        <div className="my-5 text-center text-xl-start">
                            <h1 className="display-5 fw-bolder text-white mb-2">Make Money Sharing Ads on Cluster Advertisement Network.</h1>
                            <p className="lead fw-normal text-white-50 mb-4">
                                With Cluster anyone can participate and benefit from advertising.
                            </p>
                            <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                                <a className="btn btn-danger btn-lg px-4 me-sm-3" href={`${ssoUrl}/register?redirect_url=${singleSignOnCallBack}`}>Register</a>
                                <a className="btn btn-outline-light btn-lg px-4" href={`${ssoUrl}?redirect_url=${singleSignOnCallBack}`}>Login</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"><img className="img-fluid rounded-3 my-5" src="/cluster.png" alt="Cluster" /></div>
                </div>
            </div>
        </header>
    </>)

}

export default header;
import axios from 'axios';

import {
    BEFORE_CLAIM_WEEKLY_PROMO,
    CLAIM_WEEKLY_PROMO,
    NETWORK_ERROR_CLAIMING_WEEKLY_PROMO,

    BEFORE_FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS,
    FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS,
    NETWORK_ERROR_FETCHING_PREVIOUS_WEEKLY_PROMO_WINNERS
} from '../types/weekly-promo.types';

import {
    endpoint
} from 'config';


export const beforeClaimWeeklyPromo = () => ({
    type: BEFORE_CLAIM_WEEKLY_PROMO
});


export const claimWeeklyPromo = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}weekly-promo/claim`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });

            dispatch({ type: CLAIM_WEEKLY_PROMO, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_CLAIMING_WEEKLY_PROMO, payload: e.response });
        }
    }
}


export const beforeFetchPreviousWeeklyPromoWinners = () => ({
    type: BEFORE_FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS
});


export const fetchPreviousWeeklyPromoWinners = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}weekly-promo/winners`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            dispatch({ type: FETCH_PREVIOUS_WEEKLY_PROMO_WINNERS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_PREVIOUS_WEEKLY_PROMO_WINNERS, payload: e.response });
        }
    }
}
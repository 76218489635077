import axios from 'axios';

import {
    BEFORE_FETCH_PROMOTIONS,
    FETCH_PROMOTIONS,
    ERROR_FETCHING_PROMOTIONS,

    BEFORE_VERIFY_PROMOTION,
    VERIFY_PROMOTION,
    ERROR_VERIFYING_PROMOTION,

    BEFORE_CLAIM_PROMOTION,
    CLAIM_PROMOTION,
    ERROR_CLAIMING_PROMOTION
} from '../types/promotion.type';

import { getRequest } from 'helpers/httpRequest/request';
import {
    endpoint
} from 'config';

export const beforeFetchPromotion = () => (
    { type:  BEFORE_FETCH_PROMOTIONS }
)

export const fetchPromotion = (page, filters) => {
    return async (dispatch) => {
        try{
            
            dispatch({type: BEFORE_FETCH_PROMOTIONS})

            let filterKeys = Object.keys(filters);
            let pageParams = '';
            if(filterKeys.length > 0){
                filterKeys.map((value)=>{
                    pageParams += `&${value}=${filters[value]}`
                })
            }
            
            const response = await getRequest('v1', `promotions?page=${page}${pageParams}`)

            console.log("RESPONSE: ", response);

            dispatch({ type: FETCH_PROMOTIONS, payload: response });

        }catch(e){
            dispatch({type:  ERROR_FETCHING_PROMOTIONS, payload: e.response });
        }
    }
}



export const beforeVerifyPromotion = (promotion_id) => (
    { type:  BEFORE_VERIFY_PROMOTION, payload: {promotion_id} }
)

export const verifyPromotion = (token, data, type = 'twitter-trend') => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}promotionable/${type}/${data.id}/verify`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });

            dispatch({ type: VERIFY_PROMOTION, params: data, payload: response });

        }catch(e){
            dispatch({type:  ERROR_VERIFYING_PROMOTION, params: data, payload: e.response });
        }
    }
}


export const beforeClaimPromotion = (promotion_id) => (
    { type:  BEFORE_CLAIM_PROMOTION, payload: {promotion_id} }
)

export const claimPromotion = (token, data, type = 'twitter-trend') => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}promotionable/${type}/${data.id}/claim`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });

            dispatch({ type: CLAIM_PROMOTION, params: data, payload: response });

        }catch(e){
            dispatch({type:  ERROR_CLAIMING_PROMOTION, params: data, payload: e.response });
        }
    }
}

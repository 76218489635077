import {
    BEFORE_FETCH_ADVERTS_HISTORY,
    FETCH_ADVERTS_HISTORY,
    NETWORK_ERROR_FETCHING_ADVERTS_HISTORY
} from '../types/ads-history.types';

let initialState = {
    loading: false,
    message: [],
    status_code: null,
    adverts: [],
    pagination: null,
    options: null,
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_ADVERTS_HISTORY: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_ADVERTS_HISTORY: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                adverts: action.payload.data.result.adverts.data,
                pagination: action.payload.data.result.adverts,
                options: action.payload.data.result.options,
            });
        }
        case NETWORK_ERROR_FETCHING_ADVERTS_HISTORY: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }
        default: 
            return state;
    }
}
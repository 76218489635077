import {
    BEFORE_FETCH_BLOG,
    FETCH_BLOG,
    ERROR_FETCHING_BLOG,
    FETCH_BLOG_HEADER,
    ERROR_FETCH_BLOG_HEADER
} from '../types/blog.type';
import { getRequest, postRequest } from 'helpers/httpRequest/request';
// import { networkNotification } from 'components/notifications/Notifications';


export const beforeFetchBlog = () => { return {type:  BEFORE_FETCH_BLOG} }

export const fetchBlog = () => {
    return async (dispatch) => {
        try{
            const response = await getRequest('v1', 'blogs');
            dispatch({ type: FETCH_BLOG, payload: response });

        }catch(e){
            console.log('Error1')
            dispatch({type: ERROR_FETCHING_BLOG, payload: e.response });
        }
    }
}

export const fetchHeaderBlog = () => {
    return async (dispatch) => {
        try{
            const response = await getRequest('v1', 'header-blog');
            dispatch({ type: FETCH_BLOG_HEADER, payload: response });

        }catch(e){
            console.log('Error Header')
            dispatch({type: ERROR_FETCH_BLOG_HEADER, payload: e.response });
        }
    }
}
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { PaystackButton } from 'react-paystack';

import Layout from 'themes/theme-five/layout/Layout';
import * as Actions from './actions/onboarding.actions';
import { getLocalToken } from 'helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from 'components/notifications/Notifications';


class Onboarding extends Component {

    constructor(props){
        super(props);

        this.state = {
            username: ''
        }
    }


    componentDidMount(){
        //console.log("Onboarding Authenticated User: ", this.props.auth_user);
    }


    /**
     * 
     */
    username = async (event) => {
        event.preventDefault();
        const { username } = this.state;
        const { beforeCreateUsername, createUsername } = this.props.actions;
        await beforeCreateUsername();
        await createUsername(getLocalToken(), {username});

        const { status_code, messages } = this.getReducer();

        await networkNotification(messages, status_code);

        if(status_code == 200){
            window.location = '/';
        } 
    }

    resendVerification = async (event) => {
        event.preventDefault();
        const { beforeResendVerification, resendVerification } = this.props.actions;
        await beforeResendVerification();
        await resendVerification(getLocalToken());

        const { verification_status_code, verification_messages } = this.getReducer();

        networkNotification(verification_messages, verification_status_code);
    }


    /**
     * 
     */
    handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardOnboardingReducer;
    }


    dynamicWidth = (idAttribute) => {
        const elem = document.getElementById(idAttribute);
        return elem ? elem.offsetWidth : 600;
    }


    refresh = (event) => {
        event.preventDefault();
        window.location.href = window.location;
    }



    render(){

        const { username } = this.state;

        const { loading, verification_loading } = this.getReducer();

        return(
            this.props.auth_user.username && this.props.auth_user.active && !this.props.auth_user.blacklisted
            ?
                (React.cloneElement(this.props.children, {auth_user: this.props.auth_user}))
            :
                this.props.auth_user.blacklisted
                ?
                    (
                        <Layout hideLeftBar={true}>
                            <div className="app-content content">
                        
                                <div className="content-overlay">

                                </div>

                                <div className="content-wrapper">
                                    <div className="content-header row">
                                        <div className="content-header-left col-12 mb-2 mt-1">
                                            <div className="breadcrumbs-top">
                                                <p>
                                                    <img src="/logo-black.png" height="50" />
                                                </p>
                                                <h5 className="pr-1 mb-0">Your account has been permanently deactivated due to suspicious and prohibited activities.</h5>
                                                
                                                <p>
                                                    <small>If you believe this was done in error please contact support@cluster.live</small>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </Layout>
                    )
                :
                <Layout hideLeftBar={true}>
                    <div className="app-content content">
                        
                        <div className="content-overlay">

                        </div>

                        <div className="content-wrapper">
                            <div className="content-header row">
                                <div className="content-header-left col-12 mb-2 mt-1">
                                    <div className="breadcrumbs-top">
                                        <h5 className="content-header-title float-left pr-1 mb-0">Getting You Started</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="content-body">

                                <section id="basic-vertical-layouts">
                                    <div className="row match-height">


                                        {!this.props.auth_user.active
                                        ?
                                        <div className="col-md-12 col-12">
                                            <div className="card" style={{height: '469.688px'}}>
                                                <div className="card-header">
                                                    <p>
                                                        <img src="/logo-black.png" height="50" />
                                                    </p>
                                                    <p>
                                                        <h4 className="card-title">Email Verification Required</h4>
                                                        <small className="text-muted">You will need to verify your email to proceed.</small>
                                                    </p>
                                                </div>

                                                <div className="card-body">

                                                    <div class="alert bg-rgba-primary alert-dismissible mb-2" role="alert">
                                                        
                                                        <div class="d-flex align-items-center">
                                                        <i class="bx bx-error-circle"></i>
                                                        <span>
                                                            A verification email was sent to <b className="text-dark">{this.props.auth_user.email}</b> when you registered, complete your verification by clicking the link in the email.
                                                        </span>
                                                        </div>
                                                    </div>

                                                    <div className="text-center">
                                                        Didn't receive verification email, <a href="#" onClick={this.resendVerification}><u>click here</u></a>  to resend another.
                                                        <div>
                                                            <small className="">After you have verified, click the continue button below to proceed.</small>
                                                        </div>
                                                    </div>

                                                    <div className="text-center mt-1 mb-1">
                                                        {verification_loading && 
                                                            <i className="font-medium-2 bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                        }
                                                    </div>

                                                    
                                                    <div className="p-1 text-center">
                                                        <button className="btn btn-success btn-lg" onClick={this.refresh}>Continue <i className="bx bx-chevrons-right"></i></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        
                                        :

                                        <div className="col-md-12 col-12">
                                            <div className="card" style={{height: '469.688px'}}>
                                                <div className="card-header">
                                                    <p>
                                                        <img src="/logo-black.png" height="50" />
                                                    </p>
                                                    <p>
                                                        <h4 className="card-title">Create Username</h4>
                                                        <small className="text-muted">Create a friendly username that you can easily share with friends</small>
                                                    </p>
                                                </div>

                                                <div className="card-body">
                                                    <div className="form-body">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label for="first-name-icon">User Name</label>
                                                                    <div className="position-relative has-icon-left">
                                                                    
                                                                    <input type="text" id="first-name-icon" className="form-control" name="username" placeholder="Username" onChange={this.handleChange} />
                                                                    <div className="form-control-position">
                                                                        <i className="bx bx-at"></i>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 d-flex justify-content-end">
                                                                <button className="btn btn-success btn-lg" disabled={loading || username.length < 1} type="button" onClick={this.username}>
                                                                    {loading &&
                                                                        <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i>
                                                                    }
                                                                    Create Username
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        }
                                    </div>
                                </section>

                            </div>

                        </div>
                    </div> 
                </Layout>
        );
    }
}



const mapStateToProps = (state) => ({ dashboardOnboardingReducer: state.dashboardOnboardingReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Onboarding));

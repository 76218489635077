import React from 'react';
import './style/modal.css'
import {
    LoadingSection
} from 'components/loaders/PageSpinners';


const Modal = (props) =>{
    
    
    if(!props.show) {
        return null;
    }

    const showModal = props.show ? 'show' : '';

    const display = props.show ? 'block' : 'none';

    const surveys = props.surveys ? props.surveys : [];

    return(
        <div className='modalBackground'>

            <div className='modalContent' style={{position: 'relative', margin: 'auto', top: 0, left: 0, marginTop: '10%' }}>

            {props.modalLoading ?
                <div className='modalBody marginAuto'>
                    <LoadingSection />
                    Processing your job request...
                </div>
            :
                <React.Fragment>

                    <div style={{display}} className={`modal fade text-left ${showModal}`} id="default" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel1"
                      aria-hidden="true">
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h3 className="modal-title" id="myModalLabel1"> {surveys.length > 0 ? "Complete Survey To Begin Promotion." :  "Accept To Begin Promotion."}</h3>
                            <button onClick={props.closeModal} type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                              <i className="bx bx-x"></i>
                            </button>
                          </div>
                          <form onSubmit={(e) => props.promoteAdvert(e, surveys)}>
                            <div className="modal-body">
                              <p className="mb-0">
                                {surveys.length > 0
                                  ?
                                  surveys.map(
                                    survey => (
                                      <>
                                        <p><b>{survey.value}</b> <br /></p> 
                                        {
                                          survey.options.map(
                                            option => 
                                            <p>
                                              <input className="mr-1" type={`${survey.survey_type_id == 1 ? 'radio' : 'checkbox'}`} id={`survey_${survey.id}`} name={survey.survey_type_id == 1 ? `survey_${survey.id}`: `survey_${survey.id}` } value={`${option.id}`} /> 
                                              {option.value}
                                            </p>
                                          )
                                        }
                                      </>
                                    )
                                  )
                                  :
                                  <>Continue with promoting this advert?</>
                                }
                              </p>

                            

                            </div>

                            <div className="modal-footer">
                              <button onClick={props.closeModal} type="button" className="btn btn-light-secondary" data-dismiss="modal">
                                <i className="bx bx-x d-block d-sm-none"></i>
                                <span className="d-none d-sm-block">Close</span>
                              </button>
                              <button type="submit" className="btn btn-success ml-1" data-dismiss="modal">
                                <i className="bx bx-check d-block d-sm-none"></i>
                                <span className="d-none d-sm-block">Accept Job</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                   
                </React.Fragment>
            }
            </div>
        </div>
       
    )
}

export default Modal
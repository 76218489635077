import {
    BEFORE_FETCH_REFERRALS,
    FETCH_REFERRALS,
    NETWORK_ERROR_FETCHING_REFERRALS
} from '../types/referrals.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,
    referrals: [],
    pagination: null
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_REFERRALS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_REFERRALS: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status,
                referrals: action.payload.data.result.referrals.data,
                pagination: action.payload.data.result.referrals
            });
        }
        case NETWORK_ERROR_FETCHING_REFERRALS: {
            return Object.assign({}, state, {
                loading: false,
                messages: action.payload.data.message,
                status_code: action.payload.status
            });
        }
        default: 
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Fingerprint2 from 'fingerprintjs2';
import FingerprintJS from '@fingerprintjs/fingerprintjs'


import * as Actions from './actions/campaign.actions';
import { getParameter } from '../../../../helpers/url/url-parameters';
import Logo from './assets/logo.png'
import './assets/style.css'
import LinkImage from './assets/linkShare.png'
import { LoadingSection } from 'components/loaders/PageSpinners';
import { endpoint } from "config";
import {
    ssoUrl,
    singleSignOnCallBack
} from '../../../../config';
import { getRequest } from 'helpers/httpRequest/request';

const publicIp = require('public-ip');
const CryptoJS = require('crypto-js');

class Campaign extends Component {

    constructor(props){
        super(props)

        this.state = {
            surveyIndex: 0,
            options: [],
            totalQuestions: 0,
            ipv4: 'fasda',
            ipv6: ''
        }

        this.markSurveyAnswer = this.markSurveyAnswer.bind(this)
        this.nextSurvey = this.nextSurvey.bind(this)
        this.submitSurveyForm = this.submitSurveyForm.bind(this)
    }

    markSurveyAnswer(){
        
        let newOptions = this.state.options;
        const { advert } = this.props.campaignReducer;
        let surveys = advert.surveys

        
        
        surveys.forEach(function(survey){
            if(survey.survey_type_id == 1){
                if(document.querySelector(`input[name="survey_${survey.id}"]:checked`)){
                    var answer = document.querySelector(`input[name="survey_${survey.id}"]:checked`).value;
                    newOptions.push({ survey_id: survey.id, options: [answer] });
                }
            }else{
                if(document.querySelectorAll(`input[type=checkbox][name=survey_${survey.id}]:checked`).length > 0){
                    var answer = Array.from(document.querySelectorAll(`input[type=checkbox][name=survey_${survey.id}]:checked`)).map(e => e.value);
                    newOptions.push({ survey_id: survey.id, options: answer });
                }
            } 
        });

        this.setState({options: newOptions})
        

    }

    nextSurvey(){
        const { advert } = this.props.campaignReducer;
        let surveys = advert.surveys

        let surveyIndex = this.state.surveyIndex + 1
        if(surveyIndex < surveys.length){
            this.setState({surveyIndex})
        }
        this.markSurveyAnswer()
    }

    submitSurveyForm(){
        this.markSurveyAnswer()
        const { submitSurvey } = this.props.actions;
        submitSurvey(this.state.options, {ipv4: this.state.ipv4, ipv6: this.state.ipv6})
    }

    updateComponent(data){
        let salt = "qwrty123";
        let key = CryptoJS.SHA256(salt+salt).toString();
        const CryptoJSAesJson = {
            stringify: function (cipherParams) {
                let j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
                if (cipherParams.iv) j.iv = cipherParams.iv.toString();
                if (cipherParams.salt) j.s = cipherParams.salt.toString();
                return JSON.stringify(j);
            },
            parse: function (jsonStr) {
                let j = JSON.parse(jsonStr);
                let cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
                if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
                if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
                return cipherParams;
            }
        }

        let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {format: CryptoJSAesJson}).toString();
        return encrypted;
    }


    async componentDidMount(){
        let visitorId = null;
        
        try{
            const fp = await FingerprintJS.load()
            const result = await fp.get()
            visitorId = result.visitorId
        }catch(error){}

        const {beforeFetchAdvert, fetchAdvert } = this.props.actions;

        const advertId = this.props.match.params.campaign_id;
        const promoter = getParameter('promoter', null);

        let ip_v4, ip_v6 = '';

        try{
            ip_v4 = await publicIp.v4();
        }catch(error){
            ip_v4 = null
        }

        try{
            ip_v6 = await publicIp.v6(); 
        }catch(error){
            ip_v6 = null
        } 
        this.setState({ipv4: ip_v4, ipv6: ip_v6})

        await beforeFetchAdvert();

        if (window.requestIdleCallback) {
            await requestIdleCallback((function () {
                Fingerprint2.get((async function (components) {
                    
                    await components.push({key: 'csrf', value: visitorId});

                    let updatedComponent = this.updateComponent(components);
                    await fetchAdvert(advertId, { promoter, components: updatedComponent, ip_v4, ip_v6 });

                    let { advert, status_code } = this.props.campaignReducer;

                    if(status_code === 200){
                        if(advert && advert.website_url){
                            window.location = advert.website_url;
                        }
                    }
                }).bind(this))
            }).bind(this))
        } else {
            setTimeout((function () {
                Fingerprint2.get((async function (components) {
                    await components.push({key: 'csrf', value: visitorId});
                    let updatedComponent = this.updateComponent(components);
                    await fetchAdvert(advertId, { promoter, components: updatedComponent, ip_v4, ip_v6 });

                    let { advert, status_code } = this.props.campaignReducer;

                    if(status_code === 200){
                        if(advert && advert.website_url){
                            window.location = advert.website_url;
                        }
                    }
                }).bind(this)) 
            }).bind(this), 500)
        }
    }


    render(){

        const { loading, status_code, advert, surveyAnswerLoading, surveyAnswered } = this.props.campaignReducer;
        
        return (
            <div>

                <center>
                    {loading &&
                        <LoadingSection />
                    }
                </center>

                {status_code !== null && status_code !== 200 &&
                    <center>
                        <div className="p-2 pt-5 pb-5">
                            <p>
                                <a href="/"><img className='logo' src={Logo}  /></a>
                            </p>
                            <p className="text-dark">Page could not be displayed.</p>
                            <p>
                                 This could be because 
                                the ad on this page has <del>expired</del> and is no longer available or the url is invalid.
                            </p>
                        </div>
                    </center>
                }

                    {!loading && advert &&
                        <div className="main pb-0">
                            <Helmet>
                                <title>Campaign | Cluster Advertisement Network</title>
                                <meta property="og:url" content={window.location.href} />
                                <meta property="og:type" content="article" />
                                <meta property="og:title" content={`${advert.caption}`} />
                                <meta property="og:description" content={`${advert.description}`} />
                                <meta property="og:image" content={`${advert && advert.images && advert.images.length > 0 ? advert.images[0].full_uri : ''}`} />
                            </Helmet>

                            <div className="body px-0 py-0 px-sm-0 px-md-4">

                                <div className="row p-1" >
                                    <div className="col-sm-6">
                                        <a href="/"><img className='logo' src={Logo}  /></a>
                                    </div>
                                    <div className="col-sm-6 headerRowText d-none d-md-block">
                                        Promote Ads Like This & Earn
                                        <a href={`${ssoUrl}/register?redirect_url=${singleSignOnCallBack}`}><button className="btn btn-primary primaryBtn">TRY OUT</button></a>
                                    </div>
                                </div>

                                <div className="row adBlock mt-0 mb-0">
                                    <div className="col-12 col-sm-6 p-0 mb-0 image">
                                        
                                        <img src={advert && advert.images && advert.images.length > 0 ? advert.images[0].full_uri : ''} />
                                        <div className="row">
                                            <div className="col-12 pb-0 mb-0">

                                                <div className="d-md-none" style={{position: 'relative', zIndex: 20000}}>
                                                    <a href={`${ssoUrl}/register?redirect_url=${singleSignOnCallBack}`}  className="btn clusterPink btn-lg btn-block rounded-0" style={{position: 'fixed', bottom:'0'}}>
                                                        Become an Ads Earner  <i className="bx bx-chevron-right"></i>
                                                    </a>
                                                </div>    
                                                
                                                {(advert.address || advert.phone_number) && 
                                                    <div className="card pb-0 mb-0" style={{borderRadius: '0px'}}>
                                                        <div class="card-footer d-flex justify-content-between pt-1 rounded-0">
                                                            
                                                            {advert.phone_number &&
                                                                <div class="d-flex align-items-center"> 
                                                                    <i class="cursor-pointer bx bx-phone user-profile-like font-medium-4"></i>
                                                                    <p class="mb-0 ml-25">{advert.phone_number}</p>
                                                                </div>
                                                            }
                                                            
                                                            {advert.address &&
                                                                <div class="d-flex align-items-center">
                                                                    <a href={`https://www.google.ca/maps/place/${encodeURIComponent(advert.address)}`}>
                                                                        <i class="cursor-pointer bx bx-map font-medium-4"></i>
                                                                    </a>
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text" style={{marginBottom:'50px'}}>
                                        <h3>{advert.caption}</h3>
                                        <p style={{lineHeight: '1.8rem', fontSize: '17px'}}>{advert.description}</p>

                                        {advert.website_url && 
                                            <div className="adBlockButton">
                                                <a target="_blank" href={`${endpoint}view/advert/${advert.pid}`}>
                                                    <button className="btn primaryBtn">Visit now</button>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="logoBackground"></div>

                                {advert.surveys.length > 0 && 
                                <div className="row surveyBlock mt-2 d-none d-md-block">

                                    <div className="col-12 col-md-6 headingBlock">

                                        <div className="headHighlight"> Without taking so much of your time</div>

                                        <h3 id="surveySection">Fill out a quick survey to help us serve you better</h3>

                                    </div>

                                    <div className="col-12 col-md-6 surveyQuestionsBlock">

                                        {surveyAnswerLoading ? <LoadingSection /> : 
                                            <>
                                                {surveyAnswered ? <h3> Thank you for answering our survey </h3> : 
                                                <>
                                                    {advert.surveys.length > 0 && 
                                                        <>
                                                            <div style={{display:'inline-block', width:'100%'}}>

                                                                <p style={{display:'block', float: 'right'}}>{this.state.surveyIndex + 1}/{advert.surveys.length }</p> 

                                                                <h3 style={{clear: 'both'}}>{advert.surveys[this.state.surveyIndex].value}  </h3>
                                                                
                                                            </div>
                                                        
                                                            {advert.surveys[this.state.surveyIndex].options.map((value, index)=>(
                                                                <p key={index}>
                                                                    <input
                                                                        type={advert.surveys[this.state.surveyIndex].survey_type_id === 1 ? "radio" : "checkbox"} 
                                                                        name={`survey_${advert.surveys[this.state.surveyIndex].id}`} 
                                                                        value = {value.id}
                                                                    /> 
                                                                    <span style={{marginLeft: '20px'}}>{value.value}</span>
                                                                </p>
                                                                )) 
                                                            }

                                                            <div> 
                                                                <span className=""></span> 
                                                                <button className="btn primaryBtn" 
                                                                    onClick= {advert.surveys.length === this.state.surveyIndex + 1 ? this.submitSurveyForm : this.nextSurvey}>
                                                                    {advert.surveys.length === this.state.surveyIndex + 1 ? `Submit` : `Next`}
                                                                </button>  
                                                            </div>
                                            
                                                        </>
                                                    }
                                                </>
                                                }
                                            </>
                                        }
                                        
                                        
                                    </div>

                                </div>
                                }

                            </div>


                            <div className="row linkBlock d-none">

                                <div className="col-sm-6 linkBlockText">
                                    
                                    <h3>START EARNING & PROMOTING <span className="sm-text">
                                        WITH <b className="primaryColor">CLUSTER</b>
                                        </span>
                                        </h3>

                                    <div className="linkBlockButton">
                                        <a href="/"><button className="btn btn-primary primaryBtn">TRY OUT</button></a>
                                    </div>
                                </div>

                                <div className="col-sm-6 linkBlockImg">
                                    <img src={LinkImage} />
                                </div>

                                
                            </div>

                           
                        </div>
                        
                    }

                
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ campaignReducer: state.campaignReducer});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Campaign));


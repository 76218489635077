import axios from 'axios';

import {
    BEFORE_FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA,
    FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA,
    NETWORK_ERROR_FETCHING_DASHBOARD_EARNINGS_BAR_CHART_DATA
} from '../types/index.types';

import {
    endpoint
} from 'config';


/**
 * Bar Chart Data
 */
export const beforeFetchBarChartData = () => (
    { type:  BEFORE_FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA }
)

export const fetchBarChartData = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}earnings-expenditures/charts/bar`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });
            
            dispatch({ type: FETCH_DASHBOARD_EARNINGS_BAR_CHART_DATA, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_DASHBOARD_EARNINGS_BAR_CHART_DATA, payload: e.response });
        }
    }
}
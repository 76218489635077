import {
    BEFORE_FETCH_LEADER_BOARD,
    FETCH_LEADER_BOARD,
    NETWORK_ERROR_FETCHING_LEADER_BOARD
} from '../types/leader-board.types';

let initialState = {
    loading: false,
    status_code: null,
    messages: [],
    current_leader_board: null,
    previous_leader_board: null,
    leader_board_winners_count: null
}

export default function(state = initialState, action){

    switch(action.type){
        case BEFORE_FETCH_LEADER_BOARD: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_LEADER_BOARD: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                current_leader_board: action.payload.data.result.current_leader_board,
                previous_leader_board: action.payload.data.result.previous_leader_board,
                leader_board_winners_count: action.payload.data.result.leader_board_winners_count,
                messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_FETCHING_LEADER_BOARD: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }

}
import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Helmet } from "react-helmet";

import { BarChart, Bar, Cell,   Brush, Label,
    ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    PieChart, Pie, Sector 
} from 'recharts';

import * as Actions from './actions/index.actions';
import Layout from '../../../layout/Layout';
import MonthFilter from 'components/monthFilter/MonthFilter';
import { getLocalToken } from 'helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import StatisticsTab from './components/statisticTab';

class Index extends Component {

    constructor(props){
        super(props);

        this.state = {
            moment: moment(),
            earningsFill: '#39da8a',
            expendituresFill: '#5c8eee',
            hide_instagram: localStorage.getItem('hide_instagram', null),
            hide_facebook: localStorage.getItem('hide_facebook', null),
            hide_twitter: localStorage.getItem('hide_twitter', null),
        };
    }

    async componentDidMount(){
        //console.log("Auth:: ", this.props.auth_user);
        const {
            beforeFetchBarChartData,
            fetchBarChartData
        } = this.props.actions;

        await beforeFetchBarChartData();
        await fetchBarChartData(getLocalToken(), { date: this.state.moment.format() });
    }

    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardIndexReducer;
    }


    dynamicWidth = (idAttribute) => {
        const elem = document.getElementById(idAttribute);
        return elem ? elem.offsetWidth : 600;
    }


    /**
     * Note that the month is an object of moment
     */
    onFilterEarningByMonth = async (month) => {
        const { beforeFetchBarChartData, fetchBarChartData } = this.props.actions;
        this.setState({ moment: month });
        await beforeFetchBarChartData(); 
        fetchBarChartData(getLocalToken(), { date: month.format() });
    }

    setSocial = async (key) => {
        const val = Date.now().toString();
        await localStorage.setItem(key, val);
        this.setState({[key]: val});
    }

    render(){

        const { bar_chart_data, bar_chart_loading, card_statistics } = this.getReducer();

        const { moment, earningsFill, expendituresFill } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Dashboard | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Dashboard</h5>
                                
                                </div>
                            </div>
                        </div>

                        <div className="content-body">



                            <section id="widgets-Statistics">
                                <div class="row">

                                    {this.state.hide_instagram === null &&
                                        <div class="col-md-4 col-sm-6">
                                            <div class="card text-center">
                                                <div class="card-body p-1">
                                                    <a target="_blank" href="https://www.instagram.com/clusterliveapp">
                                                      <img src="/themefive/app-assets/images/fixed/cluster_fixed_instagram_banner.jpg"  style={{width:'100%'}} />
                                                    </a>
                                                    <div className="p-0 m-0" >
                                                        <span className="badge badge-light-warning badge-pill badge-round float-right ml-auto" style={{ marginTop: 3, cursor: 'pointer' }} onClick={() => this.setSocial('hide_instagram')}>hide</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.hide_facebook === null &&
                                        <div class="col-md-4 col-sm-6">
                                            <div class="card text-center">
                                                <div class="card-body p-1">
                                                    <a target="_blank" href="https://www.facebook.com/Adsclusterlive-104384148694755">
                                                      <img src="/themefive/app-assets/images/fixed/cluster_fixed_facebook_banner.jpg"  style={{width:'100%'}} />
                                                    </a>
                                                    <div className="p-0 m-0" >
                                                        <span className="badge badge-light-warning badge-pill badge-round float-right ml-auto" style={{ marginTop: 3, cursor: 'pointer' }} onClick={() => this.setSocial('hide_facebook')}>hide</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.hide_twitter === null &&
                                        <div class="col-md-4 col-sm-6">
                                            <div class="card text-center">
                                                <div class="card-body p-1">
                                                    <a target="_blank" href="https://twitter.com/clusterliveapp">
                                                      <img src="/themefive/app-assets/images/fixed/cluster_fixed_twitter_banner.jpg"  style={{width:'100%'}} />
                                                    </a>
                                                    <div className="p-0 m-0" >
                                                        <span className="badge badge-light-warning badge-pill badge-round float-right ml-auto" style={{ marginTop: 3, cursor: 'pointer' }} onClick={() => this.setSocial('hide_twitter')}>hide</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </section>

                            <section id="widgets-Statistics">
                                
                                <div class="row">
                                    <StatisticsTab title="Total Earned" loading={bar_chart_loading} value = {card_statistics ? `₦ ${card_statistics.total_earned.toLocaleString()}` : ''} icon='bx-edit-alt' />
                                    <StatisticsTab title="Total Ads Promoted" loading={bar_chart_loading} value={card_statistics ? card_statistics.total_ads_promoted : ''} icon='bx-money' />
                                    <StatisticsTab title="Total Spent" loading={bar_chart_loading} value={card_statistics ? `₦ ${card_statistics.total_spent.toLocaleString()}` : ''} icon='bxs-group' />
                                    <StatisticsTab title="Total Ads Created" loading={bar_chart_loading} value={card_statistics ? card_statistics.total_ads_created : ''} icon='bx-money' />
                                    <StatisticsTab title="Total Clicks" loading={bar_chart_loading} value={card_statistics ? card_statistics.total_clicks : ''} icon='bx-mouse' />
                                    <StatisticsTab title="Total Referrals" loading={bar_chart_loading} value={card_statistics ? card_statistics.total_referrals : ''} icon='bx-link-alt' />
                                </div>
                            </section>

                            <section>
                            
                                <div className="row mb-4 flex">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mt-1">
                                        <div class="card h-100 p-1">

                                            <div class="card-body p-0" id="cluster-chart-container">

                                                
                                                <MonthFilter callback={this.onFilterEarningByMonth} isLoading={bar_chart_loading} />
                                                
                                                {bar_chart_loading
                                                ?
                                                    <LoadingSection />
                                                :
                                                    <BarChart
                                                        width={this.dynamicWidth("cluster-chart-container")}
                                                        height={this.dynamicWidth("cluster-chart-container") * 0.5}
                                                        data={bar_chart_data}
                                                        margin={{
                                                            top: 0,
                                                            right: 0,
                                                            left: 0,
                                                            bottom: 30,
                                                        }}
                                                    >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis label={{ value: `Days (${moment ? moment.format('MMM/YYYY') : moment().format('MMM/YYYY')})`, angle: 0, position: 'bottom' }} dataKey="day">
                                                            
                                                        </XAxis>

                                                        <YAxis yAxisId="left" orientation="left" stroke={earningsFill} label={{ fill: earningsFill, value: 'Earnings (₦)', angle: -90, position: 'insideLeft' }} />
                                                        <YAxis yAxisId="right" orientation="right" stroke={expendituresFill} label={{ fill: expendituresFill, value: 'Expenditures (₦)', angle: -270, position: 'insideRight' }} />
                                                        
                                                        <Tooltip />
                                                        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                         
                                                        
                                                        <Bar dataKey="earnings" yAxisId="left" fill={earningsFill} />
                                                        <Bar dataKey="expenditures" yAxisId="right" fill={expendituresFill} />
                                                        
                                                    </BarChart>
                                                }
                                            </div>

                                        
                                        </div>        
                                    </div>

                                </div>
                            </section>
                        
                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardIndexReducer: state.dashboardIndexReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
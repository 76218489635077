import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Pagination } from 'react-laravel-paginex';
import { Helmet } from "react-helmet";

import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import Blank from 'components/commonCards/Blank';
import Layout from '../../../../layout/Layout';
import * as Actions from './actions/transaction-history.actions';
import { getLocalToken } from 'helpers/authentication/token';
import { getParameter, setParameters } from 'helpers/url/url-parameters';

/**
 * Component to display user transaction history.
 */
class TransactionHistory extends Component {

    constructor(props){
        super(props);
        this.state = {
            provider: getParameter('provider', 0),
            type: getParameter('type', 0),
            status: getParameter('status', 0)
        } 
    }


    /**
     * Here the fetch transactions api will be 
     * called with available filter and pagination 
     * parameters plucked from the current url. 
     */
    async componentDidMount(){
        const { beforeFetchTransactions, fetchTransactions } = this.props.actions;
        await beforeFetchTransactions();
        fetchTransactions(getLocalToken(), {
            provider: getParameter('provider', 0),
            type: getParameter('type', 0),
            status: getParameter('status', 0),
            page: getParameter('page', 1),
        });
    }


    /**
     * This is the callback that is triggered whenever 
     * the user clicks on a pagination option.
     * It receives the page data object of the event.
     */
    paginateTransactions = (data) => {
        setParameters([
            { name: 'page', value: data.page },
        ]);
    }


    /**
     * This method handles updating the local state
     * whenever the fields on the filter form are updated.
     */
    handleChange = (e) => {
        e.preventDefault();
		this.setState({[e.target.name]: e.target.value});
    }


    /**
     * This function is triggered whenever the user
     * clicks the filter button on the filter form.
     * It will sync all the filter values to the current 
     * url, and cause a redirect with the updated parameters.
     * This component will be recreated and componentDidMount
     * will make a fresh fetch api call with these params.
     */
    filterTransactions = (event) => {
        event.preventDefault();
        const { status, type, provider } = this.state;
        setParameters([
            { name: 'provider', value: provider },
            { name: 'type', value: type },
            { name: 'status', value: status },
        ]);
    }


    /**
     * Use this method to dynamically create
     * select form options on a set of objects. E.g
     * [{id: 1, title: 'foo'}, {id: 2, title: 'bar'}]
     */
    createOptions = (dataArray) => {
        return dataArray.map(
            opt => <option key={opt.id} value={opt.id}>{opt.title}</option>
        );
    }


    /** 
     * A very easy and convenient way to set and 
     * retrieve the reducer responsible for rendering 
     * this page under one generic function name.
     */
    getReducer = () => {
        return this.props.dashboardTransactionHistoryReducer;
    }


    render(){
        const { loading, status_code, pagination, transactions, options } = this.getReducer();
        const { status, type, provider } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Transaction History | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    <div className="content-overlay">
                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Transaction History</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item">Transactions
                                    </li>
                                    <li className="breadcrumb-item active">Transaction History
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">


                            {loading
                            ?
                                <LoadingSection />

                            :
                                <section id="bg-variants">
                                    <div className="users-list-filter px-1">
                                        <form onSubmit={this.filterTransactions}>
                                            <div className="row border rounded py-2 mb-2">
                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label htmlFor="users-list-verified">Payment Providers</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" id="users-list-verified" name="provider" value={provider} onChange={(e) => this.handleChange(e)}>
                                                            {this.createOptions([{id: 0, title: 'All Providers'}])}
                                                            {options && options.providers && this.createOptions(options.providers)}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label htmlFor="users-list-role">Transaction Type</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" id="users-list-role" name="type" value={type} onChange={(e) => this.handleChange(e)}>
                                                            {this.createOptions([{id: 0, title: 'All Types'}])}
                                                            {options && options.types && this.createOptions(options.types)}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-3">
                                                    <label htmlFor="users-list-status">Status</label>
                                                    <fieldset className="form-group">
                                                        <select className="form-control" id="users-list-status" name="status" value={status} onChange={(e) => this.handleChange(e)}>
                                                            {this.createOptions([{id: 0, title: 'Any'}])}
                                                            {options && options.status && this.createOptions(options.status)}
                                                        </select>
                                                    </fieldset>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
                                                    <button type="submit" className="btn btn-outline-danger btn-block glow users-list-clear mb-0">Filter</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="">
                                        <div className="row">
                                            
                                            {(!loading && transactions.length == 0 && status_code == 200) &&
                                                <div className="col-md-12 mb-0 mt-0">
                                                    <Blank>
                                                        <i>
                                                            No available data.
                                                        </i>
                                                    </Blank>
                                                </div>
                                            }

                                            
                                            {transactions.map( 
                                                transaction =>  
                                                    <div className="col-md-12 mb-0 mt-0">
                                                        <div className="card p-1 mb-1 mt-0">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-3 col-md-2 mb-1">
                                                                    <img className="mr-1" src={transaction.transactionable.icon} alt="card" />
                                                                    <b>{transaction.transactionable.provider_name}</b>
                                                                </div>
                                                                <div className="col-6 col-sm-9 col-md-3 mb-1">
                                                                    <small className="badge-pill badge-success text-sm">Trans. No.</small><br />
                                                                    {transaction.transactionable.transaction_no}
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-md-3 mb-1">
                                                                    <small>{transaction.transactionable.transaction_customer_code}</small>
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-md-2">
                                                                    {transaction.transactionable.currency} {transaction.transactionable.transaction_amount}
                                                                </div>
                                                                <div className="col-6 col-sm-6 col-md-2 text-right">
                                                                    <span className="text-success">
                                                                        <b>{transaction.transactionable.transaction_status}</b>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>   
                                            )}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">

                                            <nav aria-label="Page navigation" className="pagination pagination-danger pagination-borderless justify-content-center mt-2" >

                                                {(pagination && status_code === 200 && pagination.last_page >= pagination.current_page && pagination.last_page !== 1) &&    
                                                    <Pagination 
                                                        buttonIcons={true} 
                                                        nextButtonIcon="bx bx-chevron-right" 
                                                        prevButtonIcon="bx bx-chevron-left"
                                                        changePage={this.paginateTransactions} 
                                                        data={pagination} 
                                                    />
                                                }

                                            </nav>

                                        </div>
                                    </div>
                                </section>  
                            }

                        </div>
                    
                    </div>
                </div> 
            </Layout>
        );
    }
}

const mapStateToProps = (state) => ({ dashboardTransactionHistoryReducer: state.dashboardTransactionHistoryReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionHistory));
import React, { Component } from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import * as Actions from './actions/layout.actions';
import { getLocalToken } from 'helpers/authentication/token';
import ConnectionError from '../pages/errors/connection-error/ConnectionError';
import { postRequest } from 'helpers/httpRequest/request';
import { clearLocalToken } from 'helpers/authentication/token';
import { networkNotification } from 'components/notifications/Notifications';
import {
  endpoint
} from 'config';


class Layout extends Component {

    constructor(props){
      super(props);

      this.state = {
        networkError: false
      }
    }

    async componentDidMount(){

        
        if(this.props.hideLeftBar){
            let elem = document.getElementsByTagName("body")[0];
            elem.setAttribute("class", "vertical-layout vertical-menu-modern 1-column navbar-sticky footer-static");
            elem.setAttribute("data-menu", "vertical-menu-modern");
            elem.setAttribute("data-col", "1-column");

            document.getElementById("cluster-left-menu-bar").outerHTML = "";
        }else{
          
            //vertical-layout vertical-menu-modern 2-columns navbar-sticky footer-static pace-done menu-hide
            //vertical-layout vertical-menu-modern 2-columns navbar-sticky footer-static pace-done menu-expanded
            //vertical-layout 2-columns navbar-sticky footer-static pace-done menu-hide vertical-overlay-menu
            let elem = document.getElementsByTagName("body")[0];
            // elem.setAttribute("class", "vertical-layout 2-columns navbar-sticky footer-static pace-done menu-open vertical-overlay-menu");
            // elem.setAttribute("data-open", "click");
            // elem.setAttribute("data-menu", "vertical-menu-modern");
            // elem.setAttribute("data-col", "2-columns");
            // elem.style.overflow = 'auto'
            let pageWidth = window.innerWidth
            if(pageWidth < 1200){
                
              elem.classList.remove('vertical-menu-modern')
              elem.classList.add('vertical-overlay-menu')
              elem.classList.add('menu-hide')
              elem.style.overflow = 'auto'
            }
            
        }
        

        const { beforeFetchLayoutData, fetchLayoutData} = this.props.actions;

        await beforeFetchLayoutData();
        await fetchLayoutData(getLocalToken());
    }


    logout = async (e) => {
      e.preventDefault();
      try{
        const response = await axios({
            method: 'post',
            url: `${endpoint}logout`,
            headers: {
                "content-type": "application/json",
                "Accept" : "application/json",
                "Authorization" : `Bearer ${getLocalToken()}`
            }
        });

        clearLocalToken();
        networkNotification(response.data.message, response.status)
        window.location.href = '/';

      }catch(error){
        if(!error.response){
          this.setState({
            networkError: true
          });
        }else{
          networkNotification(error.response.data.message, error.response.status)
        }
      }
    }

    /*
    logout(e){
      e.preventDefault();
      const response =  postRequest('v1', `logout`).then((response) => {
        clearLocalToken()
        networkNotification(response.message, 200)
           
        window.location.href = '/';

      }).catch((error) => {
        if(!error.response){
          this.setState({
            networkError: true
          });
        }else{
          networkNotification(error.message, 500)
        }
      })
    }
    */

    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
      getReducer = () => {
        return this.props.dashboardLayoutDataReducer;
      }


    render(){

        const { loading, status_code, layout } = this.getReducer();

        const { networkError } = this.state;

        return(
            networkError
            ?
              <ConnectionError />
            :
              <>

                
                <div className="header-navbar-shadow"></div>

                
                <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu ">
                  <div className="navbar-wrapper">
                    <div className="navbar-container content">
                      <div className="navbar-collapse" id="navbar-mobile">
                        <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                          <ul className="nav navbar-nav">
                            <li className="nav-item mobile-menu d-xl-none mr-auto"><a className="nav-link nav-menu-main menu-toggle hidden-xs" href="/#"><i className="ficon bx bx-menu"></i></a></li>
                          </ul>
                        </div>

                        <ul className="nav navbar-nav float-right">
                            
                          <li className="nav-item ">
                            <a href="/promoter/promotions" className="dropdown-toggle nav-link dropdown-user-link pl-0 pr-1">
                              <div className="avatar" style={{background:'#00000000'}}>
                                <span className="avatar-content"><i className="ficon bx bx-share"></i></span>
                                {/*<span className="avatar-status-online"></span>*/}
                              </div>
                            </a>
                          </li>


                          <li className="nav-item ">
                            <a href="/promoter/campaigns" className="dropdown-toggle nav-link dropdown-user-link pl-0 pr-1">
                              <div className="avatar" style={{background:'#00000000'}}>
                                <span className="avatar-content"><i className="ficon bx bx-briefcase"></i></span>
                                {layout && layout.available_jobs_count > 0 && <span className="avatar-status-online"></span>}
                              </div>
                            </a>
                          </li>


                          <li className="nav-item ">
                            <a href="/promoter/earnings" className="dropdown-toggle nav-link dropdown-user-link pl-0 pr-1">
                              <div className="avatar" style={{background:'#00000000'}}>
                                <span className="avatar-content"><i className="ficon bx bx-money"></i></span>
                                {/*<span className="avatar-status-online"></span>*/}
                              </div>
                            </a>
                          </li>

                            
                          {layout && !loading &&
                          <li className="dropdown dropdown-notification nav-item"><a className="nav-link nav-link-label" onClick={(event)=>{event.preventDefault()}} href={window.location} data-toggle="dropdown"><i className="ficon bx bx-bell bx-tada bx-flip-horizontal"></i><span className="badge badge-pill badge-danger badge-up">{layout.notifications_count}</span></a>
                            <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                              <li className="dropdown-menu-header">
                                <div className="dropdown-header px-1 py-75 d-flex justify-content-between bg-secondary">
                                  <span className="notification-title">
                                  <span className="badge badge-light-warning badge-pill badge-round mr-50 ml-auto">{layout.notifications_count}</span>
                                  Un-Read Notifications
                                  </span>
                                </div>
                              </li>

                              {layout && layout.notifications_count == 0 &&
                                <li className="scrollable-container media-list">
                                  <div className="media d-flex align-items-center">
                                    <div className="text-center py-75">
                                      You do not have any new notifications.
                                    </div>
                                  </div>
                                </li>
                              }

                              {layout && layout.notifications.map(
                                notification => 
                                <li className="scrollable-container media-list">
                                  <a className="d-flex justify-content-between"  href={`/profile/notifications/${notification.id}`}>
                                    <div className="media d-flex align-items-center">
                                      <div className="media-left pr-0">
                                        <div className="avatar mr-1 m-0">
                                          <div className="avatar bg-rgba-danger m-0 p-25">
                                            <div className="avatar-content">
                                              <span className="font-medium-1 text-danger">{layout && layout.user &&  `${layout.user.first_name && layout.user.first_name.substr(0,1)}${layout.user.last_name && layout.user.last_name.substr(0,1)}`}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="media-body">
                                        <h6 className="media-heading"><span className="text-bold-500">{notification.data.subject}</span></h6><small className="notification-text">{notification.readable_date_time}</small>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              )}
                                    
                              <li className="dropdown-menu-footer"><a className="dropdown-item p-50 text-secondary justify-content-center"  href={`/profile/notifications`}>Read all notifications</a></li>
                            
                            </ul>
                          </li>
                          }
                          
                          <li className="dropdown dropdown-user nav-item">
                            <a className="dropdown-toggle nav-link dropdown-user-link" onClick={(event)=>{event.preventDefault()}} href={window.location} data-toggle="dropdown">
                              <div className="user-nav d-sm-flex d-none">
                                <span className="user-name">{layout && layout.user &&  `${layout.user.first_name} ${layout.user.last_name}`}</span>
                                <span className="user-status text-muted">{layout && layout.user && layout.user.username && `@${layout.user.username}`}</span>
                              </div>
                              <span>
                                
                                <div className="avatar bg-rgba-danger m-0 p-25">
                                  <div className="avatar-content">
                                    <span className="font-medium-1 text-danger">{layout && layout.user &&  `${layout.user.first_name && layout.user.first_name.substr(0,1)}${layout.user.last_name && layout.user.last_name.substr(0,1)}`}</span>
                                  </div>
                                </div>

                              </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right pb-0">
                              <a className="dropdown-item text-warning" href="/leader-board"><i className="bx bx-chalkboard text-warning mr-50"></i> Leaderboard</a>
                              <a className="dropdown-item text-warning" href="/weekly-promo"><i className="bx bx-gift text-warning mr-50"></i> Weekly Promo 🔥</a>
                              <a className="dropdown-item text-warning" href="/profile/achievements"><i className="bx bx-trophy text-warning mr-50"></i> Achievements</a>
                              <div className="dropdown-divider mb-0"></div>
                              <a className="dropdown-item text-warning" href="/tasks"><i className="bx bx-task text-warning mr-50"></i> Tasks</a>
                              <a className="dropdown-item text-warning" href="/profile/referrals"><i className="bx bx-group text-warning mr-50"></i> Referrals</a>
                              <div className="dropdown-divider mb-0"></div>
                              <a className="dropdown-item" href="/promoter/campaigns"><i className="bx bx-briefcase mr-50"></i> Jobs</a>
                              <a className="dropdown-item" href="/promoter/earnings"><i className="bx bx-money mr-50"></i> Earnings</a>
                              <a className="dropdown-item" href="/profile/bank-accounts"><i className="bx bxs-bank mr-50"></i> Bank Accounts</a>
                              <a className="dropdown-item" href="/profile/notifications"><i className="bx bx-bell mr-50"></i> Notifications</a>
                              <div className="dropdown-divider mb-0"></div>
                              <a className="dropdown-item" href="/ads/create"><i className="bx bx-image-add mr-50"></i> Create Ad</a>
                              <a className="dropdown-item" href="/ads/history"><i className="bx bx-images mr-50"></i> Ads History</a>
                              <div className="dropdown-divider mb-0"></div>
                              <a className="dropdown-item" href="/logout" onClick={(e)=>this.logout(e)}><i className="bx bx-power-off mr-50"></i> Logout</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </nav>
                
            
                <div id="cluster-left-menu-bar" className="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true">
                  <div className="navbar-header">
                    <ul className="nav navbar-nav flex-row">
                      <li className="nav-item mr-auto">
                          <a className="navbar-brand" href="/">
                            <div className="brand-logo">
                                <img alt='logo' height="36px"  src="/logo-black.png" />
                            </div>
                            
                          </a>
                      </li>
                      <li className="nav-item nav-toggle">
                        <span className="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i className="bx bx-x d-block d-xl-none font-medium-4 danger"></i><i className="toggle-icon bx bx-disc font-medium-4 d-none d-xl-block danger" data-ticon="bx-disc"></i></span>
                      </li>
                    </ul>
                  </div>
                  <div className="shadow-bottom"></div>
                  <div className="main-menu-content">
                    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" data-icon-style="lines">
                      <li className=" nav-item">
                        <a href="/"><i className="bx bx-bar-chart-alt"></i>
                          <span className="menu-title text-truncate" data-i18n="Dashboard">Dashboard</span>
                        </a>
                      </li>

                      <li className=" nav-item">
                        <a href="/leader-board">
                          <i className="bx bx-chalkboard"></i>
                          <span className="menu-title text-truncate" data-i18n="Dashboard">Leaderboard</span>
                        </a>
                      </li>


                      <li className="nav-item">
                        <a href="/tasks">
                          <i className="bx bx-task"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Tasks</span>
                          {/*<span className="badge badge-light-warning badge-pill badge-round float-right ml-auto">New</span> */}
                        </a>
                      </li>

                      <li className="nav-item">
                        <a href="/weekly-promo">
                          <i className="bx bx-gift"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Weekly Promos 🔥</span>
                        </a>
                      </li>

                      
                    
                      
                      <li className=" navigation-header text-truncate">
                        <span data-i18n="UI Elements">
                          Promoter 
                        </span>
                      </li>
                      
                      <li className=" nav-item">
                        <a href="/promoter/campaigns">
                          <i className="bx bx-briefcase"></i>
                          <span className="menu-title text-truncate" data-i18n="Colors">Jobs</span>
                          <span className="badge badge-light-success badge-pill badge-round float-right ml-auto">EAR₦</span>
                        </a>
                      </li>

                      <li className="nav-item">
                        <a href="/promoter/promotions">
                          <i className="bx bx-certification"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Promotions</span>
                        </a>
                      </li>
                      
                      <li className=" nav-item">
                        <a href="/promoter/earnings">
                          <i className="bx bx-money"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Earnings</span>
                        </a>
                      </li>


                      <li className=" navigation-header text-truncate">
                        <span data-i18n="UI Elements">Profile</span>
                      </li>

                      {/*
                      <li className=" nav-item"><a href="/profile/settings"><i className="menu-livicon" data-icon="thumbnails-small"></i><span className="menu-title text-truncate" data-i18n="Widgets">Settings</span></a>
                      </li>
                      */}

                      <li className="nav-item">
                        <a href="/profile/achievements">
                          <i className="bx bx-trophy"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Achievements</span>
                        </a>
                      </li>

                      <li className=" nav-item">
                        <a href="/profile/bank-accounts">
                          <i className="bx bxs-bank"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Bank Accounts</span>
                        </a>
                      </li>

                      <li className=" nav-item">
                        <a href="/profile/notifications">
                          <i className="bx bx-bell"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Notifications</span>
                          {layout && layout.notifications_count > 0 &&
                          <span className="badge badge-light-danger badge-pill badge-round float-right mr-50 ml-auto">{layout.notifications_count}</span>
                          }
                        </a>
                      </li>

                      <li className="nav-item">
                        <a href="/profile/referrals">
                          <i className="bx bx-group"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Referrals</span>
                          <span className="badge badge-light-warning badge-pill badge-round float-right ml-auto">New</span>
                        </a>
                      </li>


                      <li className="nav-item">
                        <a href="/profile/settings">
                          <i className="bx bx-cog"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Settings</span>
                          <span className="badge badge-light-warning badge-pill badge-round float-right ml-auto">New</span>
                        </a>
                      </li>


                      <li className=" navigation-header text-truncate"><span data-i18n="Apps">Ads Center</span>
                      </li>
                      <li className=" nav-item">
                        <a href="/ads/history">
                          <i className="bx bx-images"></i>
                          <span className="menu-title text-truncate" data-i18n="Email">Adverts History</span>
                        </a>
                      </li>
                      <li className=" nav-item">
                        <a href="/ads/create">
                          <i className="bx bx-image-add"></i>
                          <span className="menu-title text-truncate" data-i18n="Chat">Create Advert</span>
                        </a>
                      </li>


                      <li className=" navigation-header text-truncate">
                        <span data-i18n="UI Elements">Transactions</span>
                      </li>

                      <li className=" nav-item">
                        <a href="/transaction/history">
                          <i className="bx bx-file-find"></i>
                          <span className="menu-title text-truncate" data-i18n="Widgets">Transaction History</span>
                        </a>
                      </li>

                    
                    </ul>
                  </div>
                </div>


                {/*
                <div className="app-content content pt-0 mt-0">

                  <div className="content-wrapper pt-0">
                    <div className="content-body pt-0">
                      <div className="main-menu-content pt-0">
                        <div className="row">
                          <div className="col-12 text-center">
                            <a href="#cluster-instagram-banner" className="btn btn-lg btn-warning glow shadow">
                              <i className="bx bxs-hot"></i>
                              What's New?
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>
                */}
                
      
                {this.props.children}


                <div id="cluster-instagram-banner" className="app-content content pt-0 mt-0 mb-2">

                  <div className="content-wrapper pt-0 mt-0">
                    <div className="content-body pt-0">
                      <div className="main-menu-content pt-0">
                        <div className="row">

                          <div className="col-12 col-md-3 pl-1 offset-md-1 p-0 mb-2">
                            <a target="_blank" href="https://www.instagram.com/clusterliveapp">
                              <img src="/themefive/app-assets/images/fixed/cluster_fixed_instagram_banner.jpg"  style={{width:'100%'}} />
                            </a>
                          </div>
                          
                          <div className="col-12 col-md-3 pl-1 offset-md-1 p-0 mb-2">
                            <a target="_blank" href="https://www.facebook.com/Adsclusterlive-104384148694755">
                              <img src="/themefive/app-assets/images/fixed/cluster_fixed_facebook_banner.jpg"  style={{width:'100%'}} />
                            </a>
                          </div>


                          <div className="col-12 col-md-3 pl-1 offset-md-1 p-0 mb-2">
                            <a target="_blank" href="https://twitter.com/clusterliveapp">
                              <img src="/themefive/app-assets/images/fixed/cluster_fixed_twitter_banner.jpg"  style={{width:'100%'}} />
                            </a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>  

                </div>


                
                  
                <footer className="footer footer-static footer-light" style={{display: 'none'}}>
                    <p className="clearfix mb-0"><span className="float-left d-inline-block">2021 &copy; Cluster Ads</span><span className="float-right d-sm-inline-block d-none">All Rights Reserved.</span>
                        <button className="btn btn-primary btn-icon scroll-top" type="button"><i className="bx bx-up-arrow-alt"></i></button>
                    </p>
                </footer>
                
            
              </>
            
        );
    }
}

const mapStateToProps = (state) => ({ dashboardLayoutDataReducer: state.dashboardLayoutDataReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
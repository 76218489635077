import {
    BEFORE_FETCH_CAMPAIGNS,
    FETCH_CAMPAIGNS,
    ERROR_FETCHING_CAMPAIGNS,
    BEFORE_PROMOTE_ACTION,
    RESPONSE_PROMOTE_ACTION,
    ERROR_PROMOTE_ACTION
} from '../types/campaigns.types';

let initialState = {
    loading: false,
    message: [],
    status_code: null,
    campaigns: [],
    promoteLoading: false,
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_CAMPAIGNS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_CAMPAIGNS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.message,
                campaigns: action.payload.result
            });
        }
        case ERROR_FETCHING_CAMPAIGNS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }

        case BEFORE_PROMOTE_ACTION: {
            return Object.assign({}, state, {
                promoteLoading: true
            });
        }
        case RESPONSE_PROMOTE_ACTION: {
            return Object.assign({}, state, {
                promoteLoading: false
            });
        }
        case ERROR_PROMOTE_ACTION: {
            return Object.assign({}, state, {
                promoteLoading: false
            });
        }
        

        

        default: 
            return state;
    }
}
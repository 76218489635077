//export const endpoint = process.env.REACT_APP_API_ENDPOINT;
//export const baseUrl = process.env.REACT_APP_API_BASE_URL;
//export const singleSignOnCallBack = process.env.REACT_APP_SINGLE_SIGN_ON_CALLBACK;

let env = process.env.REACT_APP_ENV || 'production';

module.exports = {
    'env': env,
    'ssoUrl' : env === 'production' ? 'https://auth.cluster.live' : 'http://localhost:3001',
    'endpoint' : env === 'production' ? 'https://ads.backend.cluster.live/api/v1/' : 'http://localhost:8000/api/v1/',
    'baseUrl' : env === 'production' ? 'https://ads.backend.cluster.live/' : 'http://localhost:8000/',
    'singleSignOnCallBack' : env === 'production' ? 'https://ads.cluster.live/auth' : 'http://localhost:3000/auth',
    'appBaseUrl' : env === 'production' ? 'https://ads.cluster.live' : 'http://localhost:3000',
    'flutterWavePublicKey' : env === 'production' ? 'FLWPUBK-699384897f9931f60abb115e68e67575-X' : 'FLWPUBK_TEST-dd98ee27b0f1344e50261fd72a990ccf-X',
    'paystackPublicKey' : env === 'production' ? 'pk_live_82b6390191a6e9d3a6203de2ca854e6f7afd82b0' : 'pk_test_d11d9b22b20a9d50dc4522f9508edcfd05d3f067'
}

import { combineReducers } from "redux";

import campaignReducer from './themes/theme-five/pages/campaign/reducer/campaign.reducer';
import dashboardCreateAdvertReducer from './themes/theme-five/pages/dashboard/ads/create/reducer/create.reducer';
import dashboardAdvertsHistoryReducer from './themes/theme-five/pages/dashboard/ads/ads-history/reducer/ads-history.reducer';
import dashboardTransactionHistoryReducer from './themes/theme-five/pages/dashboard/transactions/transaction-history/reducer/transaction-history.reducer';
import dashboardCampaignReducer from './themes/theme-five/pages/dashboard/promoter/campaigns/reducers/campaigns.reducers';
import dashboardPromotionReducer from './themes/theme-five/pages/dashboard/promoter/promotions/reducers/promotion.reducer';
import dashboardBanksListReducer from './themes/theme-five/pages/dashboard/profile/bank-accounts/reducer/bank-accounts.reducer';
import dashboardEarningsReducer from './themes/theme-five/pages/dashboard/promoter/earnings/reducers/earnings.reducer';
import dashboardIndexReducer  from './themes/theme-five/pages/dashboard/index/reducers/index.reducer';
import dashboardAdvertDetailsReducer from './themes/theme-five/pages/dashboard/ads/details/reducers/details.reducer';
import dashboardOnboardingReducer from './themes/theme-five/pages/dashboard/onboarding/reducers/onboarding.reducer';
import dashboardNotificationsReducer from './themes/theme-five/pages/dashboard/profile/notifications/reducers/notifications.reducer';
import dashboardNotificationDetailsReducer from './themes/theme-five/pages/dashboard/profile/notification-details/reducers/notification-details.reducer';
import dashboardLayoutDataReducer from './themes/theme-five/layout/reducers/layout.reducer';
import dashboardTaskReducer from './themes/theme-five/pages/dashboard/tasks/reducers/task.reducer';
import dashboardReferralsReducer from 'themes/theme-five/pages/dashboard/profile/referrals/reducers/referrals.reducer';
import dashboardLevelsReducer from 'themes/theme-five/pages/dashboard/profile/user-levels/reducers/user-level.reducer';
import dashboardLeaderboardReducer from 'themes/theme-five/pages/dashboard/leader-board/reducer/leader-board.reducer';
import dashboardWeeklyPromoReducer from 'themes/theme-five/pages/dashboard/weekly-promo/reducer/weekly-promo.reducer';
import dashboardSettingsIndexReducer from 'themes/theme-five/pages/dashboard/profile/settings/settings-index/reducer/settings-index.reducer';
import dashboardSettingsSocialPlatformReducer from 'themes/theme-five/pages/dashboard/profile/settings/social-platforms/reducer/social-platforms.reducer';
import blogReducer from 'themes/theme-five/pages/home/reducers/blog.reducer';
import viewBlogReducer from 'themes/theme-five/pages/home/reducers/viewBlog.reducer';


export default combineReducers({
	campaignReducer,
	dashboardCreateAdvertReducer,
	dashboardAdvertDetailsReducer,
	dashboardAdvertsHistoryReducer,
	dashboardTransactionHistoryReducer,
	dashboardCampaignReducer,
	dashboardPromotionReducer,
	dashboardBanksListReducer,
	dashboardEarningsReducer,
	dashboardIndexReducer,
	dashboardOnboardingReducer,
	dashboardNotificationsReducer,
	dashboardNotificationDetailsReducer,
	dashboardLayoutDataReducer,
	dashboardTaskReducer,
	dashboardReferralsReducer,
	dashboardLevelsReducer,
	dashboardLeaderboardReducer,
	dashboardWeeklyPromoReducer,
	dashboardSettingsIndexReducer,
	dashboardSettingsSocialPlatformReducer,
    blogReducer,
    viewBlogReducer,
});
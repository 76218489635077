import React from 'react';


export const icon = (typeId) => {

    switch(typeId){
        case 2: {
            return {
                icon: "bx bxl-twitter",
                color_class: "text-danger"
            }
            break;
        }
        case 3: {
            return {
                icon: "bx bxl-instagram",
                color_class: "text-danger"
            }
            break;
        }
        default: {
            return {
                icon: "bx bxs-checkbox",
                color_class: "text-secondary"
            }
            break;
        }
    }

    return icon;
}
export const BEFORE_FETCH_BANKS_LIST = 'BEFORE_FETCH_BANKS_LIST';
export const FETCH_BANKS_LIST = 'FETCH_BANKS_LIST';
export const NETWORK_ERROR_FETCHING_BANKS_LIST = 'NETWORK_ERROR_FETCHING_BANKS_LIST';


export const BEFORE_FETCH_USER_BANK_ACCOUNTS = 'BEFORE_FETCH_USER_BANK_ACCOUNTS';
export const FETCH_USER_BANK_ACCOUNTS = 'FETCH_USER_BANK_ACCOUNTS';
export const NETWORK_ERROR_FETCHING_USER_BANK_ACCOUNTS = 'NETWORK_ERROR_FETCHING_USER_BANK_ACCOUNTS';

export const BEFORE_CREATE_BANK_ACCOUNT = 'BEFORE_CREATE_BANK_ACCOUNT';
export const CREATE_BANK_ACCOUNT = 'CREATE_BANK_ACCOUNT';
export const NETWORK_ERROR_CREATING_BANK_ACCOUNT = 'NETWORK_ERROR_CREATING_BANK_ACCOUNT';


export const BEFORE_MAKE_DEFAULT_BANK_ACCOUNT = 'BEFORE_MAKE_DEFAULT_BANK_ACCOUNT';
export const MAKE_DEFAULT_BANK_ACCOUNT = 'MAKE_DEFAULT_BANK_ACCOUNT';
export const NETWORK_ERROR_MAKING_DEFAULT_BANK_ACCOUNT = 'NETWORK_ERROR_MAKING_DEFAULT_BANK_ACCOUNT';


export const BEFORE_DELETE_BANK_ACCOUNT = 'BEFORE_DELETE_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const NETWORK_ERROR_DELETING_BANK_ACCOUNT = 'NETWORK_ERROR_DELETING_BANK_ACCOUNT';
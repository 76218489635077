import {
    BEFORE_FETCH_DASHBOARD_NOTIFICATION_DETAILS,
    FETCH_DASHBOARD_NOTIFICATION_DETAILS,
    NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATION_DETAILS
} from '../types/notification-details.types';

let initialState = {
    loading: false,
    status_code: null,
    notification: null
}

export default function(state = initialState, action){

    switch(action.type){
        case BEFORE_FETCH_DASHBOARD_NOTIFICATION_DETAILS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_DASHBOARD_NOTIFICATION_DETAILS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                notification: action.payload.data.result.notification
            });
        }
        case NETWORK_ERROR_FETCHING_DASHBOARD_NOTIFICATION_DETAILS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }

}
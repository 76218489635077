import {
    BEFORE_FETCH_DASHBOARD_LAYOUT_DATA,
    FETCH_DASHBOARD_LAYOUT_DATA,
    NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA
} from '../types/layout.types';

let initialState = {
    loading: false,
    status_code: null,
    layout: null
}

export default function(state = initialState, action){

    switch(action.type){
        case BEFORE_FETCH_DASHBOARD_LAYOUT_DATA: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_DASHBOARD_LAYOUT_DATA: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                layout: action.payload.data.result.layout
            });
        }
        case NETWORK_ERROR_FETCHING_DASHBOARD_LAYOUT_DATA: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }

}
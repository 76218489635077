import axios from 'axios';
import {
    BEFORE_FETCH_BANKS_LIST,
    FETCH_BANKS_LIST,
    NETWORK_ERROR_FETCHING_BANKS_LIST,

    BEFORE_FETCH_USER_BANK_ACCOUNTS,
    FETCH_USER_BANK_ACCOUNTS,
    NETWORK_ERROR_FETCHING_USER_BANK_ACCOUNTS,

    BEFORE_CREATE_BANK_ACCOUNT,
    CREATE_BANK_ACCOUNT,
    NETWORK_ERROR_CREATING_BANK_ACCOUNT,

    BEFORE_MAKE_DEFAULT_BANK_ACCOUNT,
    MAKE_DEFAULT_BANK_ACCOUNT,
    NETWORK_ERROR_MAKING_DEFAULT_BANK_ACCOUNT,

    BEFORE_DELETE_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    NETWORK_ERROR_DELETING_BANK_ACCOUNT,
} from '../types/bank-accounts.types';

import {
    endpoint
} from 'config';


/**
 * Network Calls for fetching list
 * of banks used on the ADD form
 */
export const beforeFetchBanksList = () => (
    { type:  BEFORE_FETCH_BANKS_LIST }
)

export const fetchBanksList = (token, data = {}) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}banks`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            dispatch({ type: FETCH_BANKS_LIST, payload: response });

        }catch(e){
            dispatch({type:  NETWORK_ERROR_FETCHING_BANKS_LIST, payload: e.response });
        }
    }
}


/**
 * Network Calls for creating a bank account 
 * for a user
 */
export const beforeCreateBankAccount = () => (
    { type:  BEFORE_CREATE_BANK_ACCOUNT }
)

export const createBankAccount = (token, data = {}) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}bank-account`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                data
            });

            dispatch({ type: CREATE_BANK_ACCOUNT, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_CREATING_BANK_ACCOUNT, payload: e.response });
        }
    }
}


/**
 * Network Calls for fetching user bank accounts
 */
export const beforeFetchBankAccounts = () => (
    { type:  BEFORE_FETCH_USER_BANK_ACCOUNTS }
)

export const fetchBankAccounts = (token, data = {}) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}bank-accounts`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            dispatch({ type: FETCH_USER_BANK_ACCOUNTS, payload: response });

        }catch(e){
            dispatch({type:  NETWORK_ERROR_FETCHING_USER_BANK_ACCOUNTS, payload: e.response });
        }
    }
}





/**
 * Network Call for a making a bank account default
 */
export const beforeMakeDefaultBankAccount = (account_id) => (
    { type:  BEFORE_MAKE_DEFAULT_BANK_ACCOUNT, payload: {account_id} }
)

export const makeDefaultBankAccount = (token, data = {}) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}bank-account/${data.account_id}/default`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });

            dispatch({ type: MAKE_DEFAULT_BANK_ACCOUNT, payload: response });

        }catch(e){
            dispatch({type:  NETWORK_ERROR_MAKING_DEFAULT_BANK_ACCOUNT, payload: e.response });
        }
    }
}


/**
 * Network Call for a making a bank account default
 */
export const beforeDeleteBankAccount = (account_id) => (
    { type: BEFORE_DELETE_BANK_ACCOUNT, payload: {account_id} }
)

export const deleteBankAccount = (token, data = {}) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'delete',
                url: `${endpoint}bank-account/${data.account_id}/delete`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });

            dispatch({ type: DELETE_BANK_ACCOUNT, params: data, payload: response });

        }catch(e){
            dispatch({type:  NETWORK_ERROR_DELETING_BANK_ACCOUNT, params: data, payload: e.response });
        }
    }
}
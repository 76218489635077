import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';


import * as Actions from './actions/settings-index.actions';
import Layout from 'themes/theme-five/layout/Layout';
import { getLocalToken } from 'helpers/authentication/token';
import {
	customNotification,
	networkNotification
} from 'components/notifications/Notifications';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';


class SettingsIndex extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){

    }


    getReducer = () => {
        return this.props.dashboardSettingsIndexReducer;
    }

    render(){

        return(
            <Layout>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Settings</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="index.html"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item"><span>Profile</span>
                                    </li>
                                    <li className="breadcrumb-item active">Settings
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">


                            <section className="faq">
                                <div className="row">
                                    <div className="col-12">
                                    
                                    <div className="card bg-transparent shadow-none">
                                        <div className="card-body">

                                    
                                        
                                        <div className="main-wrapper-content">
                                            <div className="wrapper-content" data-faq="getting-text">
                                            <div className="text-center p-md-4 p-sm-1 py-1 p-0">
                                                <h1 className="faq-title">General Settings</h1>
                                                <p>Make changes to your general settings here.</p>
                                            </div>
                                            
                                            <div id="accordion-icon-wrapper1" className="collapse-icon accordion-icon-rotate">
                                                <div className="accordion" id="accordionWrapar2" data-toggle-hover="false">
                                                
                                                <a href="/profile/settings/social-platforms">
                                                    <div className="card collapse-header">
                                                        <div id="heading6" className="card-header" data-toggle="collapse" role="button" data-target="#accordion66" aria-expanded="false" aria-controls="accordion66">
                                                        <span className=" collapse-title d-flex align-items-center"><i className="bx bxs-circle font-small-1"></i>
                                                            Social Platforms Settings
                                                        </span>
                                                        </div>
                                                    </div>
                                                </a>

                                               

                                                </div>
                                            </div>
                                            
                                            </div>
                                        
                                        </div>
                                        </div>
                                    </div>
                                    
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }
}


const mapStateToProps = (state) => ({ dashboardSettingsIndexReducer: state.dashboardSettingsIndexReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsIndex));
import {
    BEFORE_FETCH_BLOG,
    FETCH_BLOG,
    ERROR_FETCHING_BLOG,
    FETCH_BLOG_HEADER,
    ERROR_FETCH_BLOG_HEADER
} from '../types/blog.type';

let initialState = {
    loading: false,
    blogHeaderLoading: false,
    message: [],
    status_code: null,
    status: '',
    blogs: [],
    blogHeader: {},
    blogHeaderError: '',
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_BLOG: {
            return {...state, loading: true};
        }
        case ERROR_FETCHING_BLOG: {
            return {...state, loading: false, message: ['Error fetching blog']};
        }
        case ERROR_FETCH_BLOG_HEADER: {
            return {...state, blogHeaderLoading: false};
        }
        case FETCH_BLOG: {
            return Object.assign({}, state, {
                loading: false,
                status: action.payload.status,
                status_code: action.payload.status_code,
                messages: action.payload.message,
                blogs: action.payload.result.data
            });
        }
        case FETCH_BLOG_HEADER: {
            return Object.assign({}, state, {
                // loading: false,
                // status: action.payload.status,
                // status_code: action.payload.status_code,
                // messages: action.payload.message,
                blogHeader: action.payload.result || {},
                blogHeaderLoading: false
            });
        }
        
        default: 
            return state;
    }
}
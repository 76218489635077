import axios from 'axios';

import {
    BEFORE_FETCH_REFERRALS,
    FETCH_REFERRALS,
    NETWORK_ERROR_FETCHING_REFERRALS
} from '../types/referrals.types';

import {
    endpoint
} from 'config';


/**
 * Bar Chart Data
 */
export const beforeFetchReferrals = () => (
    { type:  BEFORE_FETCH_REFERRALS }
)

export const fetchReferrals = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}referrals`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });
            
            dispatch({ type: FETCH_REFERRALS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_REFERRALS, payload: e.response });
        }
    }
}
import React, { Component } from 'react';
import axios from 'axios';

// import { getParameter } from '../../helpers/url/url-parameters';
import { 
    clearLocalToken, 
    getLocalToken
} from '../../helpers/authentication/token';

import Home from 'themes/theme-five/pages/home/Home';
import ConnectionError from 'themes/theme-five/pages/errors/connection-error/ConnectionError';

import {
    endpoint
} from 'config';



/**
 * Middleware component for all protected routes.
 * Component passes the auth_user prop to all children components.
 */
class Authenticated  extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            isLoggedIn: false,
            user: null,
            connectionError: false,
        }; 
    }


    /**
     * At component initialization, we will determine if a token exists.
     * If it doesnt we will redirect to the default public page.
     * If it does we will check the validation of that token, and
     * save to browser once we get that validation. Else we will
     * erase the token and redirect user to default public page.
     */
    async componentDidMount(){
        const token = getLocalToken();
        if(!token){
            this.redirect();
            this.setState({isLoggedIn: false, loading: false});
        }else{
            this.setState({isLoggedIn: true});
            const response = await this.confirmAuthentication(token);
            this.handleResponse(response);
        }
    }


    /**
     * If a token exists on the browser, we will
     * use this asynchronous function to try and
     * retrieve the authenticated user's payload.
     * The response object from this function will
     * be further analyzed to decide what further
     * actions will be taken. 3 Major outcomes of
     * the response will be valid, invalid or undefined.
     * @param {String} token 
     * @returns {Object} response i.e {} or undefined
     */
    confirmAuthentication = async (token) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}user`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
            });
            return response;
        }catch(e){
            return e.response;
        }
    }
 

    /**
     * Here we expect 3 possible results for the response i.e
     * valid | invalid | undefined. Undefined typically indicates an 
     * inactive server or intenet connection, valid indicates 200 response
     * and user should be authenticated AND invalid indicates server 
     * returned a non 200 status code response i.e token is not valid.
     * @param {Object} response
     * @returns {void}
     */
    handleResponse = (response) => {
        if(!response){
            this.setState({
                isLoggedIn: false, 
                loading: false, 
                connectionError: true
            });
        }else{
            if(response && response.status === 200){
                this.setState({
                    isLoggedIn: true, 
                    loading: false, 
                    user: response.data.result.user
                });
            }else{
                this.setState({
                    isLoggedIn: false, 
                    loading: false
                });
                clearLocalToken();
                this.redirect();
            }
        }
    }


    /**
     * This function returns the public page route
     * to return to if user is not authenticated.
     * Note that redirects wouldn't happen on the '/' route.
     */
    redirect = () => {
        /** Here we prevent an infinite redirect on index */
        if(window.location.pathname !== '/'){
            window.location = '/';
        }
    }


    render(){
        const { loading, isLoggedIn, user, connectionError } = this.state;

        return(
            connectionError 
            ?
                <ConnectionError />
            :
                (!loading 
                ?
                    <React.Fragment>
                        {isLoggedIn && user
                        ?
                            <React.Fragment>
                                {React.cloneElement(this.props.children, {auth_user: user})}
                            </React.Fragment>
                        :
                            <Home />
                        }
                   </React.Fragment>
                :
                    (null)  
                ) 
        );
    }
}

export default Authenticated;
export const BEFORE_FETCH_PROMOTER_EARNINGS = 'BEFORE_FETCH_PROMOTER_EARNINGS';
export const FETCH_PROMOTER_EARNINGS = 'FETCH_PROMOTER_EARNINGS';
export const NETWORK_ERROR_FETCHING_PROMOTER_EARNINGS = 'NETWORK_ERROR_FETCHING_PROMOTER_EARNINGS';

export const BEFORE_WITHDRAW_AVAILABLE_BALANCE = 'BEFORE_WITHDRAW_AVAILABLE_BALANCE';
export const WITHDRAW_AVAILABLE_BALANCE = 'WITHDRAW_AVAILABLE_BALANCE';
export const NETWORK_ERROR_WITHDRAWING_AVAILABLE_BALANCE = 'NETWORK_ERROR_WITHDRAWING_AVAILABLE_BALANCE';


export const BEFORE_FETCH_EARNINGS_BAR_CHART_DATA = 'BEFORE_FETCH_EARNINGS_BAR_CHART_DATA';
export const FETCH_EARNINGS_BAR_CHART_DATA = 'FETCH_EARNINGS_BAR_CHART_DATA';
export const NETWORK_ERROR_FETCHING_EARNINGS_BAR_CHART_DATA = 'NETWORK_ERROR_FETCHING_EARNINGS_BAR_CHART_DATA';

export const BEFORE_FETCH_EARNINGS_PIE_CHART_DATA = 'BEFORE_FETCH_EARNINGS_PIE_CHART_DATA';
export const FETCH_EARNINGS_PIE_CHART_DATA = 'FETCH_EARNINGS_PIE_CHART_DATA';
export const NETWORK_ERROR_FETCHING_EARNINGS_PIE_CHART_DATA = 'NETWORK_ERROR_FETCHING_EARNINGS_PIE_CHART_DATA';
import axios from 'axios';

import {
    BEFORE_FETCH_ADVERT,
    FETCH_ADVERT,
    NETWORK_ERROR_FETCHING_ADVERT,
    BEFORE_SUMBITING_SURVEY,
    SUBMIT_SURVEY_ACTION,
    ERROR_SUBMITTING_SURVEY
} from '../types/campaign.types';

import {
    endpoint
} from '../../../../../config';
import { getRequest, postRequest } from 'helpers/httpRequest/request';
import { networkNotification } from 'components/notifications/Notifications';


export const beforeFetchAdvert = () => (
    { type:  BEFORE_FETCH_ADVERT }
)

export const fetchAdvert = (pid, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'post',
                url: `${endpoint}public/advert/${pid}`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json"
                },
                data
            });

            dispatch({ type: FETCH_ADVERT, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_ADVERT, payload: e.response });
        }
    }
}

export const submitSurvey = (data, ipData) =>{
    return async (dispatch) =>{
        try{
            
            dispatch({type: BEFORE_SUMBITING_SURVEY})

            const response = await postRequest('v1', `public/survey/answer`, {survey_answers: data, ...ipData})

            networkNotification(response.message, response.status_code)
            dispatch({ type: SUBMIT_SURVEY_ACTION, payload: '' });


        }catch(e){
            dispatch({ type: ERROR_SUBMITTING_SURVEY, payload: e.message });

            networkNotification([e.message], 400)
        }
    }
}
import axios from 'axios';

import {
    BEFORE_FETCH_ADVERTS_HISTORY,
    FETCH_ADVERTS_HISTORY,
    NETWORK_ERROR_FETCHING_ADVERTS_HISTORY
} from '../types/ads-history.types';

import {
    endpoint
} from '../../../../../../../config';



export const beforeFetchAdvertsHistory = () => (
    { type:  BEFORE_FETCH_ADVERTS_HISTORY }
)

export const fetchAdvertsHistory = (token, data = {}) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}adverts`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: data
            });

            dispatch({ type: FETCH_ADVERTS_HISTORY, payload: response });

        }catch(e){
            dispatch({type:  NETWORK_ERROR_FETCHING_ADVERTS_HISTORY, payload: e.response });
        }
    }
}
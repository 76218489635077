import React from "react";

function StatisticsTab(props){
    return(
        <div class="col-md-4 col-sm-6">
            <div class="card text-center">
                <div class="card-body">
                    <div class="badge-circle badge-circle-lg badge-circle-light-success mx-auto my-1">
                        <i class={`bx ${props.icon} font-medium-5`}></i>
                    </div>
                    <p class="text-muted mb-0 line-ellipsis">{props.title}</p>
                    <h2 class="mb-0">
                        {props.loading 
                        ?
                            <i className="bx bx-loader-circle bx-spin bx-flip-horizontal"></i>
                        :
                            (props.value !== '' ? `${props.value}`: `--`) 
                        }
                    </h2>

                </div>
            </div>
        </div>
    )
}

export default StatisticsTab;
import {
    BEFORE_FETCH_DASHBOARD_TRANSACTIONS,
    FETCH_DASHBOARD_TRANSACTIONS,
    NETWORK_ERROR_FETCHING_DASHBOARD_TRANSACTIONS
} from '../types/transaction-history.types';

let initialState = {
    loading: false,
    status_code: null,
    transactions: [],
    options: null,
    messages: [],
    pagination: null
}

export default function(state = initialState, action){

    switch(action.type){
        case BEFORE_FETCH_DASHBOARD_TRANSACTIONS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_DASHBOARD_TRANSACTIONS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                transactions: action.payload.data.result.transactions.data,
                options: action.payload.data.result.options,
                pagination: action.payload.data.result.transactions,
                messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_FETCHING_DASHBOARD_TRANSACTIONS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }

}
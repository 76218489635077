import {
    BEFORE_FETCH_PROMOTER_EARNINGS,
    FETCH_PROMOTER_EARNINGS,
    NETWORK_ERROR_FETCHING_PROMOTER_EARNINGS,

    BEFORE_WITHDRAW_AVAILABLE_BALANCE,
    WITHDRAW_AVAILABLE_BALANCE,
    NETWORK_ERROR_WITHDRAWING_AVAILABLE_BALANCE,

    BEFORE_FETCH_EARNINGS_BAR_CHART_DATA,
    FETCH_EARNINGS_BAR_CHART_DATA,
    NETWORK_ERROR_FETCHING_EARNINGS_BAR_CHART_DATA,

    BEFORE_FETCH_EARNINGS_PIE_CHART_DATA,
    FETCH_EARNINGS_PIE_CHART_DATA,
    NETWORK_ERROR_FETCHING_EARNINGS_PIE_CHART_DATA
} from '../types/earnings.types';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,
    earnings: null,
    bank_account: null,

    withdraw_loading: false,
    withdraw_messages: [],
    withdraw_status_code: null,

    bar_chart_loading: false,
    bar_chart_messages: [],
    bar_chart_status_code: null,
    bar_chart_data: [],

    pie_chart_loading: false,
    pie_chart_messages: [],
    pie_chart_status_code: null,
    pie_chart_data: []
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_PROMOTER_EARNINGS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_PROMOTER_EARNINGS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
                earnings: action.payload.data.result.earnings,
                bank_account: action.payload.data.result.bank_account
            });
        }
        case NETWORK_ERROR_FETCHING_PROMOTER_EARNINGS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message,
            });
        }

        case BEFORE_WITHDRAW_AVAILABLE_BALANCE: {
            return Object.assign({}, state, {
                withdraw_loading: true
            });
        }
        case WITHDRAW_AVAILABLE_BALANCE: {
            return Object.assign({}, state, {
                withdraw_loading: false,
                withdraw_status_code: action.payload.status,
                withdraw_messages: action.payload.data.message
            });
        }
        case NETWORK_ERROR_WITHDRAWING_AVAILABLE_BALANCE: {
            return Object.assign({}, state, {
                withdraw_loading: false,
                withdraw_status_code: action.payload.status,
                withdraw_messages: action.payload.data.message
            });
        }

        
        case BEFORE_FETCH_EARNINGS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: true
            });
        }
        case FETCH_EARNINGS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: false,
                bar_chart_status_code: action.payload.status,
                bar_chart_messages: action.payload.data.message,
                bar_chart_data: action.payload.data.result.rechart_earnings_barchart
            });
        }
        case NETWORK_ERROR_FETCHING_EARNINGS_BAR_CHART_DATA: {
            return Object.assign({}, state, {
                bar_chart_loading: false,
                bar_chart_status_code: action.payload.status,
                bar_chart_messages: action.payload.data.message
            });
        }


        case BEFORE_FETCH_EARNINGS_PIE_CHART_DATA: {
            return Object.assign({}, state, {
                pie_chart_loading: true
            });
        }
        case FETCH_EARNINGS_PIE_CHART_DATA: {
            return Object.assign({}, state, {
                pie_chart_loading: false,
                pie_chart_status_code: action.payload.status,
                pie_chart_messages: action.payload.data.message,
                pie_chart_data: action.payload.data.result.rechart_earnings_piechart
            });
        }
        case NETWORK_ERROR_FETCHING_EARNINGS_PIE_CHART_DATA: {
            return Object.assign({}, state, {
                pie_chart_loading: false,
                pie_chart_status_code: action.payload.status,
                pie_chart_messages: action.payload.data.message
            });
        }
        default: 
            return state;
    }
}
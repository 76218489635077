import React, { Component } from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Layout from '../../../layout/Layout';
import Blank from 'components/commonCards/Blank';
import * as Actions from './actions/weekly-promo.actions';
import { getLocalToken } from 'helpers/authentication/token';

import {
    LoadingSection
} from 'components/loaders/PageSpinners';

import {
    networkNotification
} from 'components/notifications/Notifications';

var toonavatar = require('cartoon-avatar');

class WeeklyPromo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: ''
        };
    }

    async componentDidMount(){
        const dynamicStyles = [
            "/themefive/app-assets/css/leader-board/leader-board.css"
        ];

        dynamicStyles.forEach(function(styleHref){
            const style = document.createElement("link");
            style.href = styleHref;
            style.rel = "stylesheet";
            style.type = "text/css";
            document.head.appendChild(style);
        });

         const { beforeFetchPreviousWeeklyPromoWinners, fetchPreviousWeeklyPromoWinners} = this.props.actions;
         await beforeFetchPreviousWeeklyPromoWinners();
         fetchPreviousWeeklyPromoWinners(getLocalToken(), {});
    }


    handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }


    claimWeeklyPromo = async (e) => {
        e.preventDefault();
        const { beforeClaimWeeklyPromo, claimWeeklyPromo} = this.props.actions;
        const { code } = this.state;
        await beforeClaimWeeklyPromo();
        await claimWeeklyPromo(getLocalToken(), { code });

        const { status_code_claim, messages_claim } = this.getReducer();
        await networkNotification(messages_claim, status_code_claim);
    }


    /**
     * Returns the reducer for this page. Enabling
     * us to manage the reducer in one uniform place.
     * In an event where we will need to change the
     * name of the reducer, we only need to change it here.
     */
    getReducer = () => {
        return this.props.dashboardWeeklyPromoReducer;
    }



    render(){

        const { loading, status_code, previous_winners, messages, loading_claim, status_code_claim, messages_claim } = this.getReducer();
        const { code } = this.state;

        return(
            <Layout>

                <Helmet>
                    <title>Weekly Promo | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">


                        <div className="content-header row">
                            <div className="content-header-left col-md-9 col-sm-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                    <h5 className="content-header-title float-left pr-1 mb-0">Weekly Promo</h5>
                                    <div className="breadcrumb-wrapper d-none d-sm-block">
                                        <ol className="breadcrumb p-0 mb-0 pl-1">
                                        <li className="breadcrumb-item">
                                            <a href="/"><i className="bx bx-home-alt"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active">Weekly Promo</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="content-body">

                            {loading
                            ?
                                <LoadingSection />
                            :
                                <section className="">
                                    <div className="">

                                        <div className="row mb-2 pb-2" style={{borderBottom: '2px dashed #eee'}}>
                                            <div className="col-12 col-md-12 mb-1">
                                                
                                                <fieldset className="p-2" style={{border: '2px dashed #666'}}>
                                                    <b className="text-success">Rules</b>
                                                    <p>One promo code will be posted on any of our social media pages every week - Facebook, Instagram or Twitter.</p>
                                                    <ul>
                                                        <li>First user to find and claim code wins prize.</li>
                                                        <li>Code will be posted any time within the week.</li>
                                                        <li>Only one weekly winner.</li>
                                                        <li>You have only 3 trials to input the correct code.</li>
                                                        <li>Winner instantly gets cash prize added to their Cluster earnings.</li>
                                                    </ul>
                                                    <div className="input-group ">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Promo code"
                                                            name="code"
                                                            onChange={this.handleChange}  
                                                            value={code}
                                                            disabled={false}
                                                        />

                                                        <div className="input-group-append">
                                                            <button disabled={loading_claim} className={`btn btn-success`} type="button" onClick={(e) => this.claimWeeklyPromo(e)}>
                                                                {loading_claim ? <i className="bx bx-loader-circle bx-spin bx-flip-horizontal mr-1"></i> : <i className="bx bxs-label"></i>} Claim
                                                           </button>
                                                        </div>
                                                    </div>
                                                </fieldset>

                                            </div>
                                        </div>




                                        <div className="row mb-2 pb-2">

                                            {previous_winners.length > 0 &&
                                                <div className="col-12 col-md-12">
                                                    <p className="text-white bg-success p-2"><b>Previous Promo Winners</b></p>
                                                </div>
                                            }

                                            <div className="col-12 col-md-12 mb-1">

                                                {
                                                    previous_winners.map( 
                                                        item => 

                                                            <div className="col-md-12 mb-0 mt-0 text-dark mt-1" style={{borderBottom: '2px dashed #eee'}}>
                                                                
                                                                    <div className="row">
                                                                        <div className="col-8 col-sm-8 col-md-3 mb-1">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="user-info__basic">
                                                                                    <small className="mb-0">👑  {item.user.first_name} @{item.user.username}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        <div className="col-4 col-sm-4 col-md-3">
                                                                            <div className="text-right">
                                                                                <small className="mr-1 mb-0">{item.start_of_week}</small>
                                                                                <small className="text-success">- to -</small>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6 col-sm-6 col-md-3">
                                                                            <div className="text-left">
                                                                                <small className="mr-1 mb-0">{item.end_of_week}</small>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6 col-sm-6 col-md-3 text-right">

                                                                            {item && <b>&#8358; {item.amount && item.amount.toLocaleString()}</b>}
                                                                            
                                                                        </div>
                                                                    </div>
                                                               
                                                            </div>

                                                    )
                                                }

                                            </div>
                                        </div>

                                        
                                    </div>
                                </section> 
                            }

                        </div>
                    
                    </div>
                </div> 

            </Layout>
        );
    }

}

const mapStateToProps = (state) => ({ dashboardWeeklyPromoReducer: state.dashboardWeeklyPromoReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WeeklyPromo));

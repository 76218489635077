import axios from 'axios';

import {
    BEFORE_FETCH_ADVERT_DETAILS,
    FETCH_ADVERT_DETAILS,
    NETWORK_ERROR_FETCHING_ADVERT_DETAILS,

    BEFORE_FETCH_ADVERT_VIEWS_BAR_CHART_DATA,
    FETCH_ADVERT_VIEWS_BAR_CHART_DATA,
    NETWORK_ERROR_FETCHING_ADVERT_VIEWS_BAR_CHART_DATA
} from '../types/details.types';

import {
    endpoint
} from 'config';

export const beforeFetchAdvertDetails = () => (
    { type:  BEFORE_FETCH_ADVERT_DETAILS }
)

export const fetchAdvertDetails = (token, pid) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}advert/${pid}`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                }
            });
            //console.log("Earning Stats: ", response);
            dispatch({ type: FETCH_ADVERT_DETAILS, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_ADVERT_DETAILS, payload: e.response });
        }
    }
}




/**
 * Bar Chart Data
 */
export const beforeFetchBarChartViewsData = () => (
    { type:  BEFORE_FETCH_ADVERT_VIEWS_BAR_CHART_DATA }
)

export const fetchBarChartViewsData = (token, data) => {
    return async (dispatch) => {
        try{
            const response = await axios({
                method: 'get',
                url: `${endpoint}advert/views/charts/bar/${data.pid}`,
                headers: {
                    "Content-Type": 'application/json',
                    "Accept" : "application/json",
                    "Authorization" : `Bearer ${token}`
                },
                params: {date: data.date}
            });
            //console.log("Recharts Data: ", response);
            dispatch({ type: FETCH_ADVERT_VIEWS_BAR_CHART_DATA, payload: response });

        }catch(e){
            dispatch({type: NETWORK_ERROR_FETCHING_ADVERT_VIEWS_BAR_CHART_DATA, payload: e.response });
        }
    }
}


import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from "react-helmet";

import * as Actions from './actions/referrals.actions';
import {
    LoadingSection
} from 'components/loaders/PageSpinners';
import Layout from '../../../../layout/Layout';
import Blank from 'components/commonCards/Blank';
import {
    ssoUrl,
    singleSignOnCallBack,
    appBaseUrl
} from 'config';
import { getLocalToken } from 'helpers/authentication/token';
import { Pagination } from 'react-laravel-paginex';
import { getParameter, setParameters } from 'helpers/url/url-parameters';
import {
	networkNotification,
    customNotification
} from 'components/notifications/Notifications';

class Referrals extends Component{

    constructor(props){
        super(props);

        this.state = {
            value: '',
            copied: false
        };
    }

    async componentDidMount(){
        const { beforeFetchReferrals, fetchReferrals } = this.props.actions;
        await beforeFetchReferrals();
        fetchReferrals(getLocalToken(), { page: getParameter('page', 1) });
    }

    getReducer = () => {
        return this.props.dashboardReferralsReducer;
    }

    /**
     * This is the callback that is triggered whenever 
     * the user clicks on a pagination option.
     * It receives the page data object of the event.
     */
    paginateReferrals = (data) => {
        setParameters([
            { name: 'page', value: data.page },
        ]);
    }

    onCopyToClipboard = () => {
        this.setState({copied: true});
        customNotification(["Referral link copied."], "Copied", "success");
        //alert("Copied to clipboard");
    }

    render(){

        const { loading, status_code, referrals, pagination } = this.getReducer();

        return(
            <Layout>

                <Helmet>
                    <title>Referrals | Cluster</title>
                </Helmet>

                <div className="app-content content">
                    
                    <div className="content-overlay">

                    </div>

                    <div className="content-wrapper">

                        <div className="content-header row">
                            <div className="content-header-left col-12 mb-2 mt-1">
                                <div className="breadcrumbs-top">
                                <h5 className="content-header-title float-left pr-1 mb-0">Referrals</h5>
                                <div className="breadcrumb-wrapper d-none d-sm-block">
                                    <ol className="breadcrumb p-0 mb-0 pl-1">
                                    <li className="breadcrumb-item"><a href="/"><i className="bx bx-home-alt"></i></a>
                                    </li>
                                    <li className="breadcrumb-item"><span>Profile</span>
                                    </li>
                                    <li className="breadcrumb-item active">Referrals
                                    </li>
                                    </ol>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-body">
                            <section>

                                <div className="row">

                                    <div className="col-12">
                                        <div className="card">
                                            
                                            <div className="card-header">
                                                <h4 className="card-title">Referral Link</h4>
                                            </div>

                                            <div className="card-body">
                                                
                                                <div className="media align-items-center">
                                                   
                                                    <div className="media-body d-sm-flex justify-content-between align-items-center">
                                                        <div className="media-text mb-1 mb-sm-0">
                                                            <a href={`/referrer/${this.props.auth_user.username}`} className="success darken-4" target="_blank">{`${appBaseUrl}/referrer/${this.props.auth_user.username}`}
                                                            </a>
                                                        </div>
                                                        <div className="media-link">
                                                            <CopyToClipboard text={`${appBaseUrl}/referrer/${this.props.auth_user.username}`}
                                                                onCopy={this.onCopyToClipboard}>
                                                                <button className="btn btn-success round"><i className="bx bx-copy"></i> Copy Referral Link</button>
                                                            </CopyToClipboard>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p>
                                                    <small>
                                                        Share referral link with your friends to earn points and level up, when you level up you earn more per click when you promote ads.
                                                    </small>
                                                </p>
                                            
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Referral History</h5>
                                    </div>

                                    {pagination &&
                                    <div className="col-md-6 text-right">
                                        <div className="dataTables_info" id="users-list-datatable_info" role="status" aria-live="polite">{`Showing ${pagination.from ? pagination.from: 0} to ${pagination.to ? pagination.to : 0} of ${pagination.total ? pagination.total : 0}`} referrals</div>
                                    </div>
                                    }
                                </div>

                                {loading 
                                ?
                                    <LoadingSection />
                                :
                                    <div className="row">

                                        {referrals.length > 0
                                        ?
                                            (referrals.map(
                                                referral =>
                                                <div className="col-md-12">
                                                    <div className="card p-2 mb-1">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="avatar bg-rgba-primary m-0 mr-50">
                                                                    <div className="avatar-content">
                                                                    <span className="font-size-base text-success">RA</span>
                                                                    </div>
                                                                </div>

                                                                {referral.referred.first_name} {referral.referred.last_name}
                                                                &nbsp; <small>{referral.referred.username && `@${referral.referred.username}`}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        :
                                            <div className="col-sm-12">
                                                <div className="card p-2">
                                                    <Blank>
                                                        <span className="text-dark">
                                                            You have not made any referrals yet.
                                                        </span>
                                                    </Blank>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                }


                                    <div className="row">
                                        <div className="col-sm-12">

                                            <nav aria-label="Page navigation" className="pagination pagination-danger pagination-borderless justify-content-center mt-2" >

                                                {(pagination && status_code === 200 && pagination.last_page >= pagination.current_page && pagination.last_page !== 1) &&    
                                                    <Pagination 
                                                        buttonIcons={true} 
                                                        nextButtonIcon="bx bx-chevron-right" 
                                                        prevButtonIcon="bx bx-chevron-left"
                                                        changePage={this.paginateReferrals} 
                                                        data={pagination} 
                                                    />
                                                }

                                            </nav>

                                        </div>
                                    </div>
                                
                            
                            </section>
                        </div>

                    </div>
                </div> 

            </Layout>
        );
    }

}

const mapStateToProps = (state) => ({ dashboardReferralsReducer : state.dashboardReferralsReducer });

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Referrals));
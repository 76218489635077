import React from "react";
import {
    ssoUrl,
    singleSignOnCallBack
} from '../../../../../config';


const nav = () => {
    return (<>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container px-5">
                <a className="navbar-brand" href="/">
                <img  height="50px"  src="/logo-white.png" alt='logo' />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item"><a className="nav-link" href="#how-it-works">How It Works</a></li>
                        <li className="nav-item mr-1"><a className="nav-link" href="#articles">Articles</a></li>
                        {/* <li className="nav-item mr-1"><a className="nav-link" href="/blog">Blog</a></li> */}
                        
                        <li className="nav-item mr-1 mb-2">
                            <a className="btn btn-outline-light" href={`${ssoUrl}?redirect_url=${singleSignOnCallBack}`}>Login</a>
                        </li>

                        <li className="nav-item">
                            <a className="btn btn-danger" href={`${ssoUrl}/register?redirect_url=${singleSignOnCallBack}`}>Register</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </>)

}

export default nav;
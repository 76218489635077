import { getRequest, postRequest } from 'helpers/httpRequest/request';
import { customNotification } from 'components/notifications/Notifications';

import {
    FETCH_TASK_LOADING,
    FETCH_TASK,
    ERROR_FETCHING_TASK,
    ACCEPT_TASK_LOADING,
    ACCEPT_TASK,
    ERROR_ACCEPTING_TASK,
    CLAIM_TASK_LOADING,
    CLAIM_TASK,
    ERROR_CLAIMING_TASK,
} from '../types/task.type';

export const fetchTask = () => {
    return async (dispatch) => {
        try{
            dispatch({ type:  FETCH_TASK_LOADING });
            const response = await getRequest('v1', 'tasks');
            dispatch({ type: FETCH_TASK, payload: response.result.tasks });

        }catch(e){
            let message = ["Error fetching task"]
            if(e?.response?.data?.message){
                message = e.response.data.message
            }
            customNotification(message)
            dispatch({type: ERROR_FETCHING_TASK, payload: e.response });
        }
    }
}

export const acceptTask = (taskId) => {
    return async (dispatch) => {
        try{
            dispatch({ type:  ACCEPT_TASK_LOADING });
            const response = await postRequest('v1', `tasks/${taskId}/assign`);
            customNotification(response.message, "Task Accepted", "success");
            dispatch({ type: ACCEPT_TASK, payload: response });
            return response;

        }catch(e){
            dispatch({type: ERROR_ACCEPTING_TASK, payload: e.response });
        }
    }
}

export const claimTask = (taskId) => {
    return async (dispatch) => {
        try{
            dispatch({ type:  CLAIM_TASK_LOADING });
            const response = await postRequest('v1', `tasks/${taskId}/claim`);
            customNotification(response.message, "Task Claimed", "success")
            dispatch({ type: CLAIM_TASK, payload: response });
            return response;

        }catch(e){
            dispatch({type: ERROR_CLAIMING_TASK, payload: e.response });
        }
    }
}



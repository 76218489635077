import {
    BEFORE_FETCH_PROMOTIONS,
    FETCH_PROMOTIONS,
    ERROR_FETCHING_PROMOTIONS,

    BEFORE_VERIFY_PROMOTION,
    VERIFY_PROMOTION,
    ERROR_VERIFYING_PROMOTION,

    BEFORE_CLAIM_PROMOTION,
    CLAIM_PROMOTION,
    ERROR_CLAIMING_PROMOTION
} from '../types/promotion.type';

let initialState = {
    loading: false,
    messages: [],
    status_code: null,
    promotions: {},
    promotion_collection: [],
    cost_per_trend: null,

    verification_loading: false,
    verification_messages: [],
    verification_status_code: null,

    claim_loading: false,
    claim_messages: [],
    claim_status_code: null
}

export default function (state = initialState, action){
    switch(action.type){
        case BEFORE_FETCH_PROMOTIONS: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case FETCH_PROMOTIONS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.message,
                promotions: action.payload.result.paginated_promotions,
                promotion_collection: action.payload.result.paginated_promotions.data,
                cost_per_trend: action.payload.result.cost_per_trend
            });
        }
        case ERROR_FETCHING_PROMOTIONS: {
            return Object.assign({}, state, {
                loading: false,
                status_code: action.payload.status,
                messages: action.payload.data.message
            });
        }
        case BEFORE_VERIFY_PROMOTION: {
            const index = state.promotion_collection.findIndex(
                promotion => promotion.id === action.payload.promotion_id);
            return Object.assign({}, state, {
                verification_loading: true,
                promotion_collection: [
                    ...state.promotion_collection.slice(0, index),
                    {
                        ...state.promotion_collection[index],
                        verification_loading: true        
                    },
                    ...state.promotion_collection.slice(index + 1)
                ]
            });
        }
        case VERIFY_PROMOTION: {
            const index = state.promotion_collection.findIndex(
                promotion => promotion.id === action.params.id);
            return Object.assign({}, state, {
                verification_loading: false,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message,
                promotion_collection: [
                    ...state.promotion_collection.slice(0, index),
                    {
                        ...state.promotion_collection[index],
                        verification_loading: false       
                    },
                    ...state.promotion_collection.slice(index + 1)
                ]
            });
        }
        case ERROR_VERIFYING_PROMOTION: {
            const index = state.promotion_collection.findIndex(
                promotion => promotion.id === action.params.id);
            return Object.assign({}, state, {
                verification_loading: false,
                verification_status_code: action.payload.status,
                verification_messages: action.payload.data.message,
                promotion_collection: [
                    ...state.promotion_collection.slice(0, index),
                    {
                        ...state.promotion_collection[index],
                        verification_loading: false       
                    },
                    ...state.promotion_collection.slice(index + 1)
                ]
            });
        }
        case BEFORE_CLAIM_PROMOTION: {
            const index = state.promotion_collection.findIndex(
                promotion => promotion.id === action.payload.promotion_id);
            return Object.assign({}, state, {
                claim_loading: true,
                promotion_collection: [
                    ...state.promotion_collection.slice(0, index),
                    {
                        ...state.promotion_collection[index],
                        claim_loading: true        
                    },
                    ...state.promotion_collection.slice(index + 1)
                ]
            });
        }
        case CLAIM_PROMOTION: {
            const index = state.promotion_collection.findIndex(
                promotion => promotion.id === action.params.id);
            return Object.assign({}, state, {
                claim_loading: false,
                claim_status_code: action.payload.status,
                claim_messages: action.payload.data.message,
                promotion_collection: [
                    ...state.promotion_collection.slice(0, index),
                    {
                        ...state.promotion_collection[index],
                        claim_loading: false        
                    },
                    ...state.promotion_collection.slice(index + 1)
                ]
            });
        }
        case ERROR_CLAIMING_PROMOTION: {
            const index = state.promotion_collection.findIndex(
                promotion => promotion.id === action.params.id);
            return Object.assign({}, state, {
                claim_loading: false,
                claim_status_code: action.payload.status,
                claim_messages: action.payload.data.message,
                promotion_collection: [
                    ...state.promotion_collection.slice(0, index),
                    {
                        ...state.promotion_collection[index],
                        claim_loading: false        
                    },
                    ...state.promotion_collection.slice(index + 1)
                ]
            });
        }
        default: 
            return state;
    }
}
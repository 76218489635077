import React from "react";
import {
    ssoUrl,
    singleSignOnCallBack
} from '../../../../../config';

const footer = () => <>

    <footer className="footer-10">
        <div className="container">
            <div className="row mb-5 pb-3 no-gutters">
                <div className="col-md-4 mb-md-0 mb-4 d-flex">
                    <div className="con con-1 w-100 py-5">
                        <div className="con-info w-100 text-center">
                            <div className="icon d-flex align-items-center bg-danger justify-content-center">
                                <span className="bx bxl-whatsapp"></span>
                            </div>
                            <div className="text">
                                <span>(+234) 813 045 5623</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-md-0 mb-4 d-flex">
                    <div className="con con-2 w-100 py-5">
                        <div className="con-info w-100 text-center">
                            <div className="icon d-flex align-items-center bg-danger justify-content-center">
                                <span className="bx bx-envelope"></span>
                            </div>
                            <div className="text">
                                <span>support@cluster.live</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-md-0 mb-4 d-flex">
                    <div className="con con-3 w-100 py-5">
                        <div className="con-info w-100 text-center">
                            <div className="icon d-flex align-items-center bg-danger justify-content-center">
                                <span className="bx bx-globe"></span>
                            </div>
                            <div className="text">
                                <span>ads.cluster.live</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-3 mb-md-0 mb-4">
                    <h2 className="footer-heading">Mission</h2>
                    <ul className="list-unstyled">
                        <li><a href="#" className="d-block">Our mission is to maximise the potential of commerce by giving everyone the opportunity to participate and benefit from advertising.</a></li>
                    </ul>
                </div>

                <div className="col-md-3 mb-md-0 mb-4">
                    <h2 className="footer-heading">Vision</h2>
                    <ul className="list-unstyled">
                        <li><a href="#" className="d-block">Our vision is to become the platform of choice where people can share their dreams and co-prosper.</a></li>
                    </ul>
                </div>

                <div className="col-md-3 mb-md-0 mb-4">
                    <h2 className="footer-heading">Social Media</h2>
                    <ul className="list-unstyled">
                        <li><a href="https://www.facebook.com/Adsclusterlive-104384148694755" className="d-block">Facebook</a></li>
                        <li><a href="https://www.instagram.com/clusterliveapp/" className="d-block">Instagram</a></li>
                        <li><a href="https://twitter.com/clusterliveapp" className="d-block">Twitter</a></li>
                    </ul>
                </div>

                <div className="col-md-3 mb-md-0 mb-4">
                    <h2 className="footer-heading">Quick Links</h2>
                    <ul className="list-unstyled">
                        <li><a href={`${ssoUrl}/register?redirect_url=${singleSignOnCallBack}`} className="d-block">Register</a></li>
                        <li><a href={`${ssoUrl}?redirect_url=${singleSignOnCallBack}`} className="d-block">Login</a></li>
                        <li><a href={`${ssoUrl}/privacy-policy`} target="_blank" className="d-block">Privacy Policy</a></li>
                    </ul>
                </div>

            </div>
            <div className="row mt-5 pt-4 border-top">
                <div className="col-md-6 col-lg-8 mb-md-0 mb-4">
                    <p className="copyright mb-0">
                        Copyright &copy; {new Date().getFullYear()} Cluster Software Company Limited. All rights reserved.
                    </p>
                </div>
                <div className="col-md-6 col-lg-4 text-md-right">
                    <ul className="ftco-footer-social p-0">
                        <li className="ftco-animate"><a href="https://www.facebook.com/Adsclusterlive-104384148694755" target="_blank" data-toggle="tooltip" data-placement="top" title="Facebook"><span className="bx bxl-facebook"></span></a></li>
                        <li className="ftco-animate"><a href="https://www.instagram.com/clusterliveapp/" target="_blank" data-toggle="tooltip" data-placement="top" title="Instagram"><span className="bx bxl-instagram"></span></a></li>
                        <li className="ftco-animate"><a href="https://twitter.com/clusterliveapp" target="_blank" data-toggle="tooltip" data-placement="top" title="Twitter"><span className="bx bxl-twitter"></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</>

export default footer;
 
import {
    FETCH_POINTS_LOADING,
    FETCH_POINTS,
    ERROR_FETCHING_POINTS,
    FETCH_LEVEL_LOADING,
    FETCH_LEVEL,
    ERROR_FETCHTING_LEVEL,
} from '../types/user-level.type';

let initialState = {
    fetch_point_loading: false,
    points: [],
    fetch_points_messages: [],
    fetch_points_status_code: null,

    fetch_level_loading: false,
    fetch_level_messages: [],
    fetch_level_status_code: null, 
    levels: [],
    current_level: null,
    points: 0,
    todays_points: 0,
    months_data: null
}

export default function (state = initialState, action){
    switch(action.type){
        case FETCH_POINTS_LOADING: {
            return Object.assign({}, state, {
                fetch_point_loading: true
            });
        }
        case FETCH_POINTS: {
            return Object.assign({}, state, {
                fetch_point_loading: false,
                points: action.payload.data,
            });
        }
        case ERROR_FETCHING_POINTS: {
            return Object.assign({}, state, {
                fetch_point_loading: false,
                fetch_points_status_code: action.payload.status,
                fetch_points_messages: action.payload.data.message,
            });
        }

        case FETCH_LEVEL_LOADING: {
            return Object.assign({}, state, {
                fetch_level_loading: true
            });
        }
        case FETCH_LEVEL: {
            return Object.assign({}, state, {
                fetch_level_loading: false,
                fetch_level_status_code: action.payload.status,
                fetch_level_messages: action.payload.data.message,
                levels: action.payload.data.result.levels,
                current_level: action.payload.data.result.current_level,
                points: action.payload.data.result.points,
                todays_points: action.payload.data.result.todays_points,
                months_data: action.payload.data.result.months_data
            });
        }
        case ERROR_FETCHTING_LEVEL: {
            return Object.assign({}, state, {
                fetch_level_loading: false,
                fetch_level_status_code: action.payload.status,
                fetch_level_messages: action.payload.data.message,
            });
        }
        
        default: 
            return state;
        }
}
import React from 'react';

const marker = (typeId) => {
    switch(typeId){
        case 4: {
            return <span className="badge badge-info">#Twitter Trend</span>;
        }
        default: {
            return <span className="badge badge-success">Regular Share</span>;
        }
    }
}

const CampaignCard = (props)=>{
    return (
        <div className="col-xl-4 col-md-6 col-sm-12">
            
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title line-ellipsis">{props.caption}</h4>
                </div>
                
                <div className="card-body">
                    <p className="card-text line-ellipsis">
                        {props.description}
                    </p>
                </div>
                <img className="img-fluid" src={props.mediable ? props.mediable.full_uri: ''} alt="Ad Banner" />
                
                <div className="p-2">
                    <div className="progress progress-bar-success">
                            <div className="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="20" aria-valuemax="100" style={{width:`${props.progress}%`}}>
                            </div>
                    </div>
                </div>

                <div className="p-0 pl-2 pr-2 m-0">
                    {marker(props.social_platform_type_id)}
                </div>
            
                <div className="card-footer d-flex justify-content-between">
                    <span><small>{props.date_created}</small></span>
                    <button className="btn btn-light-success" onClick={()=>props.openModal(props.pid, props.surveys, props.advert)}>Apply</button>
                </div>
            </div>
    
        </div>
    )
}

export default CampaignCard;